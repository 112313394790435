import axiosAuth from 'utils/axios/axiosAuth';

const shipmentCustomerApi = {
  getAwaitingCustomers: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`shipment_customer/`, {
      params: queryParams,
    });
  },
  getShipmentCustomer: ({ shipmentCustomerId }) => {
    return axiosAuth.get(`shipment_customer/${shipmentCustomerId}`);
  },

  createShipmentCustomer: ({ params }) => {
    return axiosAuth.post(`getcare_delivery_order`, params);
  }
};

export default shipmentCustomerApi;
