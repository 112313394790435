import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDisplayFields } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderProducts, makeSelectSalesOrderDiscounts } from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import {
  soProductsParamsMap,
  soProductsDisplayFields,
} from 'utils/constanst/omsSalesOrderConstants';
import { saveSalesOrderProductItem } from 'redux/actions/oms/salesOrderActions';

import SalesOrderProduct from './SalesOrderProduct/SalesOrderProduct';
import SalesOrderDiscount from './SalesOrderDiscount/SalesOrderDiscount';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './SalesOrderProducts.module.scss';
import gridClasses from './SalesOrderProductsGrid.module.scss';

class SalesOrderProducts extends PureComponent {
  _availableProducts = () => {
    return this.props.salesOrderProducts.filter(item => !item.willRemove);
  }

  render() {
    const { salesOrderStatusId, salesOrderDiscounts } = this.props;
    const displayFields = getDisplayFields(soProductsParamsMap, soProductsDisplayFields);
    const availableProducts = this._availableProducts();

    return (
      <div className={`${classes.Wrap}`}>
        <h4 className={classes.SectionTitle}>Danh sách hàng đặt</h4>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <ListHeaderCol className={`${gridClasses.Col} TextCenter`} label="#"/>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                {...item}
                className={`${gridClasses.Col} ${item.className}`}
              />
            ))}
            <ListHeaderCol label=""/>
          </div>
          <div className={`${classes.Body}`}>
            {availableProducts.length ? (<>
              { availableProducts.map((item, index) => (
                <SalesOrderProduct
                  key={`pr-product-${item.id}`}
                  {...item}
                  index={index}
                  disabledRemoving={availableProducts.length === 1}
                  salesOrderStatusId={salesOrderStatusId}
                  onRemoveProduct={this.props.saveSalesOrderProductItem}
                />
              )) }
              { salesOrderDiscounts.map((item, index) => (
                <SalesOrderDiscount
                  key={`pr-discount-${item.id}`}
                  {...item}
                />
              )) }
            </>) : (
              <p className="NoData">Không có sản phẩm nào</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SalesOrderProducts.propTypes = {
  salesOrderProducts: PropTypes.array,
  salesOrderDiscounts: PropTypes.array,
  salesOrderStatusId: PropTypes.number,
  onCancelSalesOrder: PropTypes.func,
};

SalesOrderProducts.defaultProps = {
  salesOrderProducts: [],
  salesOrderDiscounts: [],
};

const mapStateToProps = createStructuredSelector({
  salesOrderProducts: makeSelectSalesOrderProducts(),
  salesOrderDiscounts: makeSelectSalesOrderDiscounts(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesOrderProductItem: (payload) => dispatch(saveSalesOrderProductItem(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesOrderProducts);
