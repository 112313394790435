import {
  GET_SALES_ORDER_STATUSES,
  GET_SALES_ORDER_LIST,
  GET_SALES_ORDER,
  SAVE_LOADING,
  SAVE_SALES_ORDER_LIST,
  SAVE_SALES_ORDER_LIST_TOTAL,
  SAVE_SALES_ORDER_STATUSES,
  SAVE_SALES_TRANSACTION_LIST,
  SAVE_SALES_TRANSACTION_LIST_TOTAL,

  SAVE_SALES_ORDER,
  SAVE_SALES_ORDER_PRODUCTS,
  SAVE_SALES_ORDER_PRODUCT_ITEM,
  SAVE_SALES_ORDER_BONUSES,
  SAVE_SALES_ORDER_DISCOUNTS,

  SAVE_SALES_ORDER_NOTES,
  SAVE_SALES_ORDER_NOTE_ITEM,
  REMOVE_SALES_ORDER_NOTE_ITEM,

  SAVE_SALES_ORDER_LOGISTIC,
  SAVE_SALES_ORDER_SALES_USER,
  SAVE_SALES_ORDER_DELIVERY,

  SAVE_SALES_ORDER_ALL_INFO,

  UPDATE_LOGISTIC_PROVIDER,
  UPDATE_DELIVERY_INFO,
  CONFIRM_PURCHASING,
  CONFIRM_SUCCESS_DELIVERY,
  CONFIRM_SUCCESS,
  CONFIRM_SHIP_FROM_VENDOR_SUCCESS,

  GET_SALES_ORDER_HISTORY,
  SAVE_SALES_ORDER_HISTORY,
  GET_SALES_TRANSITION_LIST,
  PRINT_DELIVERY_PAPER,
  RETURN_DELIVERY,
} from 'redux/constants/oms/salesOrderConstants';

export function getSalesOrderStatuses(payload) {
  return {
    type: GET_SALES_ORDER_STATUSES,
    payload,
  };
}

export function getSalesOrderList(payload) {
  return {
    type: GET_SALES_ORDER_LIST,
    payload,
  };
}

export function getSalesTransitionList(payload) {
  return {
    type: GET_SALES_TRANSITION_LIST,
    payload,
  };
}

export function getSalesOrder(id) {
  return {
    type: GET_SALES_ORDER,
    id,
  };
}

export function updateLogisticProvider(payload) {
  return {
    type: UPDATE_LOGISTIC_PROVIDER,
    payload,
  };
}
export function updateDeliveryInfo(payload) {
  return {
    type: UPDATE_DELIVERY_INFO,
    payload,
  };
}
export function confirmPurchasing(payload) {
  return {
    type: CONFIRM_PURCHASING,
    payload,
  };
}
export function confirmSuccessDelivery(payload) {
  return {
    type: CONFIRM_SUCCESS_DELIVERY,
    payload,
  };
}
export function confirmSuccess(payload) {
  return {
    type: CONFIRM_SUCCESS,
    payload,
  };
}
export function printDeliveryPaper(payload) {
  return {
    type: PRINT_DELIVERY_PAPER,
    payload,
  };
}
export function returnDelivery(payload) {
  return {
    type: RETURN_DELIVERY,
    payload,
  };
}
export function confirmShipFromVendorSuccess(payload) {
  return {
    type: CONFIRM_SHIP_FROM_VENDOR_SUCCESS,
    payload,
  };
}

export function getSalesOrderHistory(payload) {
  return {
    type: GET_SALES_ORDER_HISTORY,
    payload,
  };
}

// mutation
export function saveSalesOrderStatuses(salesOrderStatuses) {
  return {
    type: SAVE_SALES_ORDER_STATUSES,
    salesOrderStatuses,
  };
}

export function saveSalesOrderList(salesOrderList) {
  return {
    type: SAVE_SALES_ORDER_LIST,
    salesOrderList,
  };
}

export function saveSalesOrderTransaction(salesOrderTransaction) {
  return {
    type: SAVE_SALES_TRANSACTION_LIST,
    salesOrderTransaction,
  };
}

export function saveSalesOrderTransactionTotal(salesOrderListTransactionTotal) {
  return {
    type: SAVE_SALES_TRANSACTION_LIST_TOTAL,
    salesOrderListTransactionTotal,
  };
}


export function saveSalesOrderListTotal(salesOrderListTotal) {
  return {
    type: SAVE_SALES_ORDER_LIST_TOTAL,
    salesOrderListTotal,
  };
}

export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}

export function saveSalesOrder(salesOrder) {
  return {
    type: SAVE_SALES_ORDER,
    salesOrder,
  };
}
export function saveSalesOrderProducts(salesOrderProducts) {
  return {
    type: SAVE_SALES_ORDER_PRODUCTS,
    salesOrderProducts,
  };
}
export function saveSalesOrderProductItem(salesOrderProductItem) {
  return {
    type: SAVE_SALES_ORDER_PRODUCT_ITEM,
    salesOrderProductItem,
  };
}
export function saveSalesOrderBonuses(salesOrderBonuses) {
  return {
    type: SAVE_SALES_ORDER_BONUSES,
    salesOrderBonuses,
  };
}
export function saveSalesOrderDiscounts(salesOrderDiscounts) {
  return {
    type: SAVE_SALES_ORDER_DISCOUNTS,
    salesOrderDiscounts,
  };
}

export function saveSalesOrderNotes(salesOrderNotes) {
  return {
    type: SAVE_SALES_ORDER_NOTES,
    salesOrderNotes,
  };
}
export function saveSalesOrderNoteItem(salesOrderNoteItem) {
  return {
    type: SAVE_SALES_ORDER_NOTE_ITEM,
    salesOrderNoteItem,
  };
}
export function removeSalesOrderNoteItem(salesOrderNoteItem) {
  return {
    type: REMOVE_SALES_ORDER_NOTE_ITEM,
    salesOrderNoteItem,
  };
}

export function saveSalesOrderLogistic(salesOrderLogistic) {
  return {
    type: SAVE_SALES_ORDER_LOGISTIC,
    salesOrderLogistic,
  };
}
export function saveSalesOrderSalesUser(salesOrderSalesUser) {
  return {
    type: SAVE_SALES_ORDER_SALES_USER,
    salesOrderSalesUser,
  };
}
export function saveSalesOrderDelivery(salesOrderDelivery) {
  return {
    type: SAVE_SALES_ORDER_DELIVERY,
    salesOrderDelivery,
  };
}
export function saveSalesOrderAllInfo(salesOrderData) {
  return {
    type: SAVE_SALES_ORDER_ALL_INFO,
    salesOrderData,
  };
}

export function saveSalesOrderHistory(salesOrderHistory) {
  return {
    type: SAVE_SALES_ORDER_HISTORY,
    salesOrderHistory,
  };
}
