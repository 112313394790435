import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderHistory } from 'redux/selectors';
import { getSalesOrderHistory } from 'redux/actions/oms/salesOrderActions';
import { getStatusClass } from 'utils/constanst/omsSalesOrderConstants';

import Panel from 'components/Panel/Panel';

import classes from './SalesOrderHistory.module.scss';

class SalesOrderHistory extends PureComponent {
  componentDidUpdate(prevProps) {
    const { salesOrderId } = this.props;
    if (salesOrderId && salesOrderId !== prevProps.salesOrderId) {
      this._loadData();
    }
  }
  _loadData = () => {
    this.props.getSalesOrderHistory({
      params: { getcare_order_id: this.props.salesOrderId },
    });
  };

  render() {
    const { salesOrderHistory } = this.props;

    return (
      <Panel
        title="Lịch sử đơn hàng"
        panelClassName={classes.Panel}
        content={<div className={`${classes.Wrap}`}>
          <div className={`${classes.Inner}`}>
            <ul className={classes.EventList}>
              {salesOrderHistory.map((event) => (
                <li key={`event-${event.id}`} className={classes.EventItem}>
                  <span className={classes.Bullet}></span>
                  <div className={classes.EventDetails}>
                    <p className={classes.EventTime}>
                      {validDate(event.created_at)
                        ? format(validDate(event.created_at), dateTimeFormat)
                        : ''}
                      { event.getcare_order_status?.id
                        ? <span className={`${getStatusClass(event.getcare_order_status.id)} ${classes.EventStatus}`}>{event.getcare_order_status.name}</span>
                        : ''
                      }
                    </p>
                    <p className={classes.EventTitle}>{`${event.user_name}: ${event.action}`}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>}
        isBorder
      />
    );
  }
}

SalesOrderHistory.propTypes = {
  salesOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SalesOrderHistory.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  salesOrderHistory: makeSelectSalesOrderHistory(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrderHistory: (payload) => dispatch(getSalesOrderHistory(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesOrderHistory);
