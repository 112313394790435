import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'routes/routes';
import RouteWithSubRoutes from 'components/RouteWithSubRoutes';
import { createMuiTheme, ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { defaultTheme } from 'assets/js/themes';

import './App.scss';

const theme = createMuiTheme(defaultTheme);

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <div className="App">
            <Switch>
              <Route exact path="/">
                <Redirect to="/so" />
              </Route>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </div>
        </StylesProvider>
      </ThemeProvider>
    );
  }
}

export default App;
