import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEqual, sortBy } from 'lodash';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import classes from './FiltersExportSelection.module.scss';

class FiltersExportSelection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIds: [],
    }
  }
  componentDidMount() {
    this.setState({
      selectedIds: this.getAllIds(),
    });
  }
  componentDidUpdate(prevProps) {
    const { list } = this.props;
    if (prevProps.list.length === 0 && list.length > 0) {
      this.setState({
        selectedIds: this.getAllIds(),
      });
    }
  }

  isItemSelected = (channel) => {
    return this.state.selectedIds.some(id => id === channel.id);
  }
  isAllSelected = () => {
    const listIds = this.getAllIds();
    return this.state.selectedIds.length > 0 && isEqual(sortBy(listIds), sortBy(this.state.selectedIds));
  }
  getAllIds = () => {
    return this.props.list.map(item => item.id);
  }

  handleSelectedChange = (e, channel) => {
    const newIds = e.target.checked ? [...this.state.selectedIds, channel.id] : this.state.selectedIds.filter(id => id !== channel.id);
    this.setState({
      selectedIds: [...newIds],
    });
    this.props.onSelectedIdsChange(newIds);
  }
  handleToggleSelectAll = (e) => {
    const newIds = e.target.checked ? this.getAllIds() : [];
    this.setState({
      selectedIds: [...newIds],
    });
    this.props.onSelectedIdsChange(newIds);
  }

  render() {
    const { list } = this.props;

    return (<div className={`${classes.List}`}>
      <div className={`${classes.Body}`}>
        <div className={`${classes.Row} ${classes.Header}`}>
          <div className={`${classes.Col} ${classes.CheckboxWrap}`}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  className={classes.Checkbox}
                  checked={this.isAllSelected()}
                  onChange={this.handleToggleSelectAll}
                />
              }
              label="Đội ngũ bán hàng"
            />
          </div>
        </div>

        { list && list.length
          ? list.map(item => (
              <div
                key={`channel-${item.id}`}
                className={`${classes.Row} ${classes.Item}`}
              >
                <div className={`${classes.Col} ${classes.CheckboxWrap}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        className={classes.Checkbox}
                        checked={this.isItemSelected(item)}
                        onChange={(e) => this.handleSelectedChange(e, item)}
                      />
                    }
                    label={`${item.code} - ${item.name}`}
                  />
                </div>
              </div>
            ))
          : (<p className="NoData">Không có đội ngũ bán hàng nào</p>)
        }
      </div>
    </div>);
  }
}

FiltersExportSelection.propTypes = {
  list: PropTypes.array,
  onSelectedIdsChange: PropTypes.func,
};

FiltersExportSelection.defaultProps = {
  list: [],
};

export default FiltersExportSelection;
