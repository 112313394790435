import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import AwaitingDeliveryOrderListItem from './AwaitingDeliveryOrderListItem/AwaitingDeliveryOrderListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './AwaitingDeliveryOrderListGrid.module.scss';
import classes from './AwaitingDeliveryOrderList.module.scss';

class AwaitingDeliveryOrderList extends PureComponent {
  _isItemSelected = (order) => {
    return this.props.selectedPackedOrders.some(item => item.id === order.id);
  }

  render() {
    const { salesOrderList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div className={`$${classes.Header}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          <div className={`${gridClasses.Col} TextCenter`}>STT</div>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={`${gridClasses.Col} ${item.className}`}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
            </div>

      <div className={`${classes.Body}`}>
      
        { !isLoading && salesOrderList.length
          ? salesOrderList.map((item, index) => (
            <AwaitingDeliveryOrderListItem
              key={`awaiting-so-${item.id}`}
              {...item}
              index={index}
              isSelected={this._isItemSelected(item)}
              onItemSelectedToggle={this.props.onPackedItemSelectedToggle}
              onSubmitDeliverySuccess={this.props.onSubmitDeliverySuccess}
            />))
          : (<p className="NoData">Không có đơn hàng nào</p>)
        }
      </div>
    </div>;
  }
}

AwaitingDeliveryOrderList.propTypes = {
  displayFields: PropTypes.array,
  salesOrderList: PropTypes.array,
  isLoading: PropTypes.bool,
  selectedPackedOrders: PropTypes.array,
  onSortChange: PropTypes.func,
  onPackedItemSelectedToggle: PropTypes.func,
  onSubmitDeliverySuccess: PropTypes.func,
};

AwaitingDeliveryOrderList.defaultProps = {
  displayFields: [],
  salesOrderList: [],
  isLoading: false,
  selectedPackedOrders: [],
};

export default AwaitingDeliveryOrderList;
