import purchaseRequestApi from 'utils/api/oms/purchaseRequestApi';
import { put } from 'redux-saga/effects';
import { purchaseRequestActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadPurchaseRequestStatuses(payload) {
  try {
    yield put(purchaseRequestActions.savePurchaseRequestStatuses([]));

    const { data: response } = yield purchaseRequestApi.getAllStatuses();

    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(
      purchaseRequestActions.savePurchaseRequestStatuses(response.data ? response.data : [])
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadPurchaseRequestList(payload) {
  const { params } = payload.payload;
  try {
    yield put(purchaseRequestActions.saveLoading(true));

    const { data: response } = yield purchaseRequestApi.getAll({ params });

    yield put(purchaseRequestActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      purchaseRequestActions.savePurchaseRequestList(response.data ? response.data : [])
    );
    yield put(purchaseRequestActions.savePurchaseRequestListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadPurchaseRequest(payload) {
  try {
    yield put(purchaseRequestActions.saveLoading(true));
    yield put(purchaseRequestActions.savePurchaseRequest(null));
    yield put(purchaseRequestActions.savePurchaseRequestProducts([]));
    yield put(purchaseRequestActions.savePurchaseRequestBonuses([]));
    yield put(purchaseRequestActions.savePurchaseRequestDiscounts([]));
    yield put(purchaseRequestActions.savePurchaseRequestNotes([]));
    yield put(purchaseRequestActions.savePurchaseRequestLogistic(null));
    yield put(purchaseRequestActions.savePurchaseRequestSalesUser(null));
    yield put(purchaseRequestActions.savePurchaseRequestDelivery(null));

    const { data: response } = yield purchaseRequestApi.get(payload.id);

    yield put(purchaseRequestActions.saveLoading(false));

    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }

    yield put(purchaseRequestActions.savePurchaseRequest({
      ...response.data,
      getcare_purchase_request_items: undefined,
      getcare_purchase_request_bonuss: undefined,
      getcare_purchase_request_discounts: undefined,
      getcare_purchase_request_notes: undefined,
    }));
    yield put(purchaseRequestActions.savePurchaseRequestProducts(response.data.getcare_purchase_request_items || []));
    yield put(purchaseRequestActions.savePurchaseRequestBonuses(response.data.getcare_purchase_request_bonuss || []));
    yield put(purchaseRequestActions.savePurchaseRequestDiscounts(response.data.getcare_purchase_request_discounts || []));
    yield put(purchaseRequestActions.savePurchaseRequestNotes(response.data.getcare_purchase_request_notes || []));
    yield put(purchaseRequestActions.savePurchaseRequestLogistic(response.data.logistic_provider || null));
    yield put(purchaseRequestActions.savePurchaseRequestSalesUser({
      name: response.data.sales_name,
    }));
    yield put(purchaseRequestActions.savePurchaseRequestDelivery(response.data.customer_delivery || null));

  } catch (err) {
    console.log(err);
  }
}
