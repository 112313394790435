export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã đơn hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'created_at',
    label: 'Thời gian tạo',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'customer_name',
    label: 'Khách hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'order_most_items',
    label: 'Sản phẩm',
  },
  {
    name: 'sales_name',
    label: 'Người phụ trách',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'amount_total',
    label: 'Giá trị đơn hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'logistic_provider_name',
    label: 'Vận chuyển',
  },
  {
    name: 'getcare_order_status_name',
    label: 'Trạng thái',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'delivery_by_getcare',
    label: 'Phahub giao hàng',
    sortable: true,
    sortDir: '',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};
export const filterFields = [
  {
    name: 'code',
    label: 'Mã đơn hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'customer_name',
    label: 'Khách hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'created_at',
    label: 'Thời gian tạo',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập hoặc chọn...',
  },
  {
    name: 'getcare_order_item_product_name',
    label: 'Sản phẩm',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'sales_name',
    label: 'Người phụ trách',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_order_status_id',
    label: 'Trạng thái',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'logistic_provider_name',
    label: 'Đối tác vận chuyển',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_sales_channel_id',
    label: 'Khu vực bán hàng',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'delivery_by_getcare',
    label: 'Giao hàng',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
];

// details
export const soProductsDisplayFields = [
  {
    name: 'product_name',
    label: 'Tên thuốc/Sản phẩm',
  },
  {
    name: 'vendor_name',
    label: 'Nhà cung cấp',
  },
  {
    name: 'uom_base_name',
    label: 'Đơn vị',
  },
  {
    name: 'type_label',
    label: 'Nhãn',
  },
  {
    name: 'price_sales_total',
    label: 'Đơn giá',
    className: 'TextRight',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng đặt',
    className: 'TextCenter',
  },
  {
    name: 'available_number',
    label: 'Số lượng thực tế',
  },
  {
    name: 'amount_total',
    label: 'Thành tiền',
    className: 'TextRight',
  },
];
export const soProductsParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};

export const soBonusesDisplayFields = [
  {
    name: 'promotion_name',
    label: 'Chương trình khuyến mãi',
  },
  {
    name: 'product_bonus_name',
    label: 'Sản phẩm khuyến mãi',
  },
  {
    name: 'uom_base_name',
    label: 'Đơn vị',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng',
  },
];
export const soBonusesParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};

export const PAYMENT_FEE_BY_SENDER_ID = 1;
export const PAYMENT_FEE_BY_RECEIVER_ID = 2;
export const PAYMENT_FEE_MAP = {
  [PAYMENT_FEE_BY_SENDER_ID]: 'Người gửi',
  [PAYMENT_FEE_BY_RECEIVER_ID]: 'Người nhận',
}
export const PAYMENT_FEE_ARR = [
  {
    id: PAYMENT_FEE_BY_SENDER_ID,
    name: PAYMENT_FEE_MAP[PAYMENT_FEE_BY_SENDER_ID],
  },
  {
    id: PAYMENT_FEE_BY_RECEIVER_ID,
    name: PAYMENT_FEE_MAP[PAYMENT_FEE_BY_RECEIVER_ID],
  },
]

export const REQUIRED_NOTE_CHOTHUHANG = 'CHOTHUHANG';
export const REQUIRED_NOTE_CHOXEMHANGKHONGTHU = 'CHOXEMHANGKHONGTHU';
export const REQUIRED_NOTE_KHONGCHOXEMHANG = 'KHONGCHOXEMHANG';
export const REQUIRED_NOTE_MAP = {
  [REQUIRED_NOTE_CHOTHUHANG]: 'Cho thử hàng',
  [REQUIRED_NOTE_CHOXEMHANGKHONGTHU]: 'Cho xem hàng không thử',
  [REQUIRED_NOTE_KHONGCHOXEMHANG]: 'Không cho xem hàng',
}
export const REQUIRED_NOTE_ARR = [
  {
    id: REQUIRED_NOTE_CHOTHUHANG,
    name: REQUIRED_NOTE_MAP[REQUIRED_NOTE_CHOTHUHANG],
  },
  {
    id: REQUIRED_NOTE_CHOXEMHANGKHONGTHU,
    name: REQUIRED_NOTE_MAP[REQUIRED_NOTE_CHOXEMHANGKHONGTHU],
  },
  {
    id: REQUIRED_NOTE_KHONGCHOXEMHANG,
    name: REQUIRED_NOTE_MAP[REQUIRED_NOTE_KHONGCHOXEMHANG],
  },
]

export const IN_PROGRESS_PURCHASE = 1; // đang mua hàng
export const LACKING_STOCK = 2; // thiếu hàng
export const CONFIRMED_PURCHASE = 3; // đã xác nhận
export const AWAITING_DELIVERY = 4; // chờ giao hàng
export const DELIVERY_SERVICE_SENT = 5; // gửi giao hàng
export const PICKED_UP = 6; // đã lấy hàng
export const IN_PROGRESS_DELIVERY = 7; // đang giao hàng
export const DELIVERY_SUCCESS = 8; // giao hàng thành công
export const ORDER_SUCCESS = 9; // thành công
export const IS_PROGRESS_RETURN = 10; // đang trả hàng
export const RETURNED = 11; // đã trả hàng
export const CANCELLED = 12; // huỷ đơn hàng

export function getStatusClass(soStatus) {
  if ([IS_PROGRESS_RETURN, RETURNED ].includes(soStatus)) {
    return 'StatusLabel Warning';
  }
  if ([PICKED_UP, IN_PROGRESS_DELIVERY ].includes(soStatus)) {
    return 'StatusLabel Info';
  }
  if ([DELIVERY_SUCCESS, ORDER_SUCCESS ].includes(soStatus)) {
    return 'StatusLabel Success';
  }
  if ([LACKING_STOCK, CANCELLED ].includes(soStatus)) {
    return 'StatusLabel Danger';
  }
  if (soStatus === true ){
    return 'StatusLabel Success';
  }
  return 'StatusLabel Default';
}

export function isInProgressPurchase(soStatus) {
  return soStatus === IN_PROGRESS_PURCHASE;
}
export function isLackingStock(soStatus) {
  return soStatus === LACKING_STOCK;
}
export function isAwaitingDelivery(soStatus) {
  return soStatus === AWAITING_DELIVERY;
}
export function isBeforeDeliveryServiceSent(soStatus) {
  return [IN_PROGRESS_PURCHASE, LACKING_STOCK, CONFIRMED_PURCHASE].includes(soStatus);
}
export function isDeliveryServiceSent(soStatus) {
  return soStatus === DELIVERY_SERVICE_SENT;
}
export function isInProgressDelivery(soStatus) {
  return soStatus === IN_PROGRESS_DELIVERY;
}

// actions
export const UPDATE_CUSTOMER_DELIVERY = "update_customer_delivery";
export const UPDATE_LOGISTIC_PROVIDER = "update_logistic_provider"
export const SEND_DELIVERY = "send_delivery";
export const PRINT_PURCHASE_INVOICE = "print_purchase_invoice";
export const PRINT_PURCHASE_DELIVERY = "print_purchase_delivery";
export const SUCCESS_DELIVERY = "success_delivery";
export const DONE = "done";
export const CANCEL = "cancel";
export const CONFIRM = "confirm";
export const UPDATE_SALESMAN = "update_salesman";
export const APPROVE_PURCHASING = "approve_purchasing";
export const RETURN_DELIVERY = "return_delivery";
export const SHIP_FROM_VENDOR = "ship_from_vendor";

export function getActionItem(actionCode, orderDetailsActions) {
  if (!orderDetailsActions) return;
  return orderDetailsActions.find(action => action.code === actionCode);
}

// awaiting delivery orders
export const awaitingDeliveryOrdersDisplayFields = [
  {
    name: 'pr_code',
    label: 'Mã đơn đặt',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'code',
    label: 'Mã đơn hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'customer_name',
    label: 'Khách hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'customer_delivery_address',
    label: 'Địa chỉ nhận hàng',
  },
  {
    name: 'logistic_provider_name',
    label: 'Vận chuyển',
  },
  {
    name: 'created_at',
    label: 'Thời gian tạo',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'sales_name',
    label: 'Người phụ trách',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'amount_total',
    label: 'Giá trị đơn hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'packing_status_name',
    label: '3PL',
    sortable: true,
    sortDir: '',
    className: 'TextCenter',
  },
  {
    name: 'note_latest',
    label: 'Ghi chú giao hàng',
  },
];
export const awaitingDeliveryOrderListParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  getcare_order_status_id: [AWAITING_DELIVERY],
  mode: 'AWAITING_DELIVERY',
};
export const awaitingDeliveryOrdersFilterFields = [
  {
    name: 'pr_code',
    label: 'Mã đơn đặt',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'code',
    label: 'Mã đơn hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'customer_name',
    label: 'Khách hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'logistic_provider_id',
    label: 'Loại hình vận chuyển',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'sales_name',
    label: 'Người phụ trách',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'created_at',
    label: 'Thời gian tạo',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập hoặc chọn...',
  },
  {
    name: 'amount_total',
    label: 'Giá trị đơn hàng',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập...',
  },
];
