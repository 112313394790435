import shipmentCustomerApi from 'utils/api/oms/shipmentCustomerApi';
import { put } from 'redux-saga/effects';
import { customerActions, shipmentActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadCustomerList(payload) {
  const { params } = payload.payload;
  try {
    yield put(customerActions.saveLoading(true));

    const { data: response } = yield shipmentCustomerApi.getAwaitingCustomers({ params });

    yield put(customerActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      customerActions.saveCustomerList(response.data ? response.data : [])
    );
    yield put(customerActions.saveCustomerListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadShipmentCustomer(payload) {
  const { shipmentCustomerId } = payload.payload;
  try {
    yield put(customerActions.saveLoading(true));
    yield put(customerActions.saveShipmentCustomerInfo(null));
    yield put(customerActions.saveShipmentCustomerOrders([]));

    const { data: response } = yield shipmentCustomerApi.getShipmentCustomer({ shipmentCustomerId });

    yield put(customerActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      customerActions.saveShipmentCustomerInfo(response.data ? {
        ...response.data,
        orders: undefined,
      } : null)
    );
    yield put(
      customerActions.saveShipmentCustomerOrders(response.data ? [...response.data.orders] : [])
    );
  } catch (err) {
    console.log(err);
  }
}
export function* createCustomerShipment(payload) {
  const { params } = payload.payload;
  try {
    yield put(customerActions.saveLoading(true));

    const { data: response } = yield shipmentCustomerApi.createShipmentCustomer({ params });

    yield put(customerActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      shipmentActions.saveShipment(response.data ? response.data : null)
    );
  } catch (err) {
    console.log(err);
  }
}
