import {
  CHECK_AUTH,
  SIGN_OUT,
  SAVE_AUTH,
  REMOVE_AUTH,
  SAVE_AUTH_LOADING,
  SAVE_AUTH_ERROR,
  GET_USER_TEAM_LIST,
  SAVE_USER_TEAM_LIST,
} from 'redux/constants/authConstants';

export function checkAuth(payload) {
  return {
    type: CHECK_AUTH,
    payload
  };
}
export function signOut(payload) {
  return {
    type: SIGN_OUT,
    payload
  };
}
export function getUserTeamList(payload) {
  return {
    type: GET_USER_TEAM_LIST,
    payload
  };
}

// mutation
export function saveAuthLoading(loading) {
  return {
    type: SAVE_AUTH_LOADING,
    loading
  };
}
export function saveAuthError(error) {
  return {
    type: SAVE_AUTH_ERROR,
    error
  };
}
export function saveAuth(user) {
  return {
    type: SAVE_AUTH,
    user
  };
}
export function removeAuth(payload) {
  return {
    type: REMOVE_AUTH,
    payload
  };
}
export function saveUserTeamList(userTeamList) {
  return {
    type: SAVE_USER_TEAM_LIST,
    userTeamList
  };
}
