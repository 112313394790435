import React, { PureComponent } from 'react';
import { debounce, isEqual } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getLinearProgressValue, numberFormat } from 'utils/helper';

import supportProjectApi from 'utils/api/ecom/serviceRequest/connectSupport/support/projectApi';

import {
  Grid,
  Typography,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import StyledWidget from 'components/Styled/Widget/Widget';
import StyledLinearProgress from 'components/Styled/LinearProgress/LinearProgress';
import SupportProductList from './SupportProductList/SupportProductList'

import styles from './ProjectDetails.module.scss';

class ProjectDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      project: {},
      loadingProject: false,
      loadMoreDescription: false,
      contentMaxLength: 255,
      iframeDialogOpen: false,
    };
    this.source = null;
  }
  componentDidMount() {
    this.fetchProjectDetails();
  }
  componentDidUpdate(prevProps,prevState) {
    if ( !isEqual(prevProps.selectedProjectId, this.props.selectedProjectId) ) {
      this.setState({ loadingProject: true });
      this.debounceFetchProjectDetails();
    }
  }

  fetchProjectDetails = async () => {
    this.setState({ loadingProject: true });
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    const { data: response } = await this.loadProjectDetails({ cancelToken: this.source.token });
    this.setState({ 
      project: response.data || null, 
      loadingProject: false,
    });
  }
  debounceFetchProjectDetails = debounce(this.fetchProjectDetails,250)
  loadProjectDetails = async ({ cancelToken }) => {
    return await supportProjectApi.get({
      params: {
        project_id: this.props.selectedProjectId,
      },
      cancelToken,
    });
  }
  handleOpenUrl = (e) => {
    e.preventDefault();
    this.setState({ iframeDialogOpen: true });
  }

  render() {
    const { className } = this.props;
    const urlEcomProjectDetails = `${process.env.REACT_APP_PATH_ECOM}community/connect-support/project/detail/${this.state.project.id}`;
    
    return (
      <div className={className}>
        <div className="margin__bottom--2">
          <Typography className={`margin__bottom--2 ${ this.state.loadingProject && 'display--none'}`} variant="h6">Dự án <b>{this.state.project.name}</b></Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <StyledWidget 
                loading={this.state.loadingProject}
              >
                {
                  !this.state.loadingProject && <>
                    <Typography className="margin__bottom--2" variant="h6">Thông tin dự án</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm="auto">
                        <Box display="flex" alignItems="center" className="gap--2">
                          <Avatar
                            alt={this.state.project.customer_name} 
                            src={this.state.project.customer_avatar}
                          >
                            {this.state.project.customer_name ? this.state.project.customer_name.charAt(0) : 'P'}
                          </Avatar>
                          <div>
                            <p className="fontWeight--semiBold">{this.state.project.customer_name}</p>
                            <p><small>Đã đăng {moment(this.state.project.created_at).format('DD/MM/YYYY')}</small></p>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm>
                        <div className="dataItem margin__bottom--2">
                          <label>Cần trước ngày</label>
                          <p>{moment(this.state.project.deadline).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className="dataItem margin__bottom--2">
                          <label>Địa chỉ</label>
                          <p>{this.state.project.address}</p>
                        </div>
                        <div className="dataItem">
                          <label>Số lượng đóng góp</label>
                          <Box className="gap--2" display="flex" flexGrow={1} alignItems="center">
                            <StyledLinearProgress style={{ flexGrow: 1 }} variant="determinate" value={getLinearProgressValue({ value: this.state.project.quantity_number_donate, max: this.state.project.quantity_number})}/>
                            <p>{numberFormat(this.state.project.quantity_number_donate)}/{numberFormat(this.state.project.quantity_number)}</p>
                          </Box>
                        </div>
                      </Grid>
                    </Grid>
                    <hr/>
                    <Typography className="margin__bottom--2" variant="h6">Giới thiệu</Typography>
                    <div className="dataItem dataItem__menu margin__bottom--2">
                      <label>Chi tiết dự án</label>
                      <p>
                        {this.state.loadMoreDescription ? `${this.state.project.description} ` : `${String(this.state.project.description).substring(0,this.state.contentMaxLength)}... `}
                        {
                          (this.state.project.description?.length || 0) > this.state.contentMaxLength && 
                          <>
                            {
                              this.state.loadMoreDescription ? 
                              <span onClick={() => this.setState({ loadMoreDescription: false })} className="cursor--pointer text--info">Ẩn bớt</span> :
                              <span onClick={() => this.setState({ loadMoreDescription: true })} className="cursor--pointer text--info">Xem thêm</span>
                            }
                          </>
                        }
                      </p>
                    </div>
                    <div className="dataItem dataItem__menu">
                      <label>Thông tin liên hệ</label>
                      <div>
                        <div className="dataItem margin__bottom--2">
                          <label>Người tạo</label>
                          <p>{this.state.project.customer_name}</p>
                        </div>
                        <div className="dataItem margin__bottom--2">
                          <label>Số điện thoại</label>
                          <p>{this.state.project.customer_phone}</p>
                        </div>
                        <div className="dataItem margin__bottom--2">
                          <label>Địa chỉ</label>
                          <p>{this.state.project.address}</p>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </StyledWidget>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledWidget 
                loading={this.state.loadingProject}
              >
                {
                  !this.state.loadingProject && <>
                    <Typography className="margin__bottom--2" variant="h6">Xem chi tiết dự án</Typography>
                    <a onClick={this.handleOpenUrl} href={urlEcomProjectDetails} target="_blank">{urlEcomProjectDetails}</a>
                  </>
                }
              </StyledWidget>
            </Grid>
          </Grid>
        </div>
        <SupportProductList
          loading={this.state.loadingProject}
          selectedProjectId={this.props.selectedProjectId}
          selectedProjectServiceRequestItemId={this.props.selectedProjectServiceRequestItemId}
          supportProductList={this.state.project.service_request_items}
          onSelectServiceRequestItemId={this.props.onSelectServiceRequestItemId}
        />
        <Dialog fullScreen open={this.state.iframeDialogOpen} onClose={() => this.setState({ iframeDialogOpen: false })}>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">{this.state.project.name}</Typography>
              <IconButton onClick={() => this.setState({ iframeDialogOpen: false })}>
                <CloseIcon/>
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className="padding--0 position__relative" dividers>
            <iframe
              className={styles.iframe}
              src={urlEcomProjectDetails}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

ProjectDetails.propTypes = {
  selectedProjectId: PropTypes.number,
  selectedProjectServiceRequestItemId: PropTypes.number,
  onSelectServiceRequestItemId: PropTypes.func,
  className: PropTypes.string,
};

ProjectDetails.defaultProps = {
  selectedProjectId: null,
  selectedProjectServiceRequestItemId: null,
  className: ''
};

export default ProjectDetails;
