import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import * as QueryString from 'query-string';

import CreateShipment from 'components/shipment/CreateShipment/CreateShipment';
import ShipmentDetails from 'components/shipment/ShipmentDetails/ShipmentDetails';
import ShipmentCustomers from 'views/oms/ShipmentCustomers/ShipmentCustomers';

class Shipment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shipmentCustomerId: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const params = QueryString.parse(location.search);

    if (!params.shipment_customer) {
      return;
    }

    if (!!params.shipment_customer) {
      this.setState({
        shipmentCustomerId: params.shipment_customer,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const params = QueryString.parse(location.search);
    if (params.shipment_customer !== QueryString.parse(prevProps.location.search).shipment_customer) {
      if (!!params.shipment_customer) {
        this.setState({
          shipmentCustomerId: params.shipment_customer,
        });
      }
      if (!params.shipment_customer) {
        return;
      }
    }
  }

  _isCreating = () => {
    const { id } = this.props.match.params;
    return id && id === 'add';
  }
  _isShipmentCustomers = () => {
    const { id } = this.props.match.params;
    return id && id === 'customer';
  }

  render() {
    return (<>
      { this._isShipmentCustomers()
        ? <ShipmentCustomers/>
        : ( this._isCreating()
            ? <CreateShipment shipmentCustomerId={this.state.shipmentCustomerId}/>
            : <ShipmentDetails/>
          )
      }
    </>);
  }
}

export default compose(withRouter)(Shipment);
