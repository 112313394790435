const styles = (theme) => {
  return {
    tableToolbar: {
      padding: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    tableCellRadio: {
      padding: '0 0 0 6px',
    }
  }
};

export default styles;