import React, { PureComponent } from 'react';
import salesTeamApi from 'utils/api/mdm/salesTeamApi';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { debounce } from 'lodash';
import { suggestionPageSize } from 'utils/constanst/common';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import classes from './SalesOrderSalesFormDialog.module.scss';

class SalesOrderSalesFormDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      queryInput: '',
      salesUserOptions: [],
    }
  }
  componentDidMount() {
    const { queryInput } = this.state;
    this.loadSalesUsers({ name: queryInput });
  }
  componentDidUpdate(prevProps, prevState) {
    const { queryInput } = this.state;
    if (queryInput !== prevState.queryInput) {
      this.loadSalesUsers({ name: queryInput });
    }
  }
  loadSalesUsers = debounce(async (params) => {
    const { data: response } = await salesTeamApi.getSalesUsers({
      params: { page: 1, page_size: suggestionPageSize, ...params },
    });
    if (!response?.result) return;
    this.setState({
      salesUserOptions: response.data ? [...response.data] : [],
    });
  }, 500);
  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await salesOrderApi.updateSalesUser({
      params: {
        id: this.props.salesOrderId,
        getcare_sales_id: values.salesUserId,
        sales_note: this.props.hasSalesUser ? values.salesNote : null,
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      this.props.onSaveSuccess(response.data);
      this.props.onClose();
    });
  };
  handleInputChange = (e, newValue) => {
    this.setState({
      queryInput: newValue,
    });
  };
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
    this.setState({
      queryInput: '',
      salesUserOptions: [],
    });
  };

  render() {
    const { isOpen, salesId, salesName } = this.props;
    const { salesUserOptions, isLoading } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>{ salesId ? 'Thay đổi Trình Dược Viên' : 'Chỉ định Trình Dược Viên'}</DialogTitle>
          <Formik
            initialValues={{
              salesUserId: salesId,
              salesUser: {
                id: salesId,
                name: salesName,
              },
              salesNote: '',
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validationSchema={Yup.object().shape({
              salesUserId: Yup.string().required("Vui lòng chọn Trình Dược Viên"),
              salesNote: salesId && Yup.string().required("Vui lòng nhập lý do thay đổi").test('len', 'Tối đa 255 ký tự', val => val?.length <= 255),
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <form noValidate autoComplete="off">
                  <DialogContent className={classes.DialogContent}>
                    <div className={classes.FieldControl}>
                      <label>
                        Chọn Trình Dược Viên <span className={classes.RequiredMark}>*</span>
                      </label>
                      <Autocomplete
                        selectOnFocus
                        name="salesUser"
                        handleHomeEndKeys={false}
                        value={values.salesUser || null}
                        onInputChange={this.handleInputChange}
                        options={salesUserOptions || []}
                        getOptionLabel={(option) => option?.name || ''}
                        filterOptions={(x) => x}
                        renderOption={(option) => option?.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            autoFocus
                            placeholder="Chọn..."
                            {...params}
                            error={!!errors.salesUserId}
                            helperText={errors.salesUserId}
                          />
                        )}
                        onChange={(e, newValue) => {
                          setFieldValue('salesUserId', newValue?.id);
                          setFieldValue('salesUser', newValue);
                        }}
                      />
                    </div>
                    { salesId &&
                      <div className={classes.FieldControl}>
                        <label>Lý do thay đổi <span className={classes.RequiredMark}>*</span></label>
                        <TextField
                          autoComplete="off"
                          rowsMax={3}
                          multiline
                          value={values.salesNote}
                          placeholder="Nhập..."
                          name="salesNote"
                          error={!!errors.salesNote}
                          helperText={errors.salesNote}
                          onChange={handleChange}
                        />
                      </div>
                    }
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      autoFocus
                    >
                      Xác nhận
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

export default SalesOrderSalesFormDialog;
