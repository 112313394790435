import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';

class RouteWithSubRoutes extends PureComponent {
  render() {
    const route = { ...this.props };

    return (<Route
      path={route.path}
      render={props => (
        <route.component {...this.props} routes={route.routes} />
      )}
    />);
  }
}

export default RouteWithSubRoutes;
