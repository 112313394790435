export const GET_CUSTOMER_LIST = 'getcare/oms/customer/GET_CUSTOMER_LIST';
export const GET_SHIPMENT_CUSTOMER = 'getcare/oms/customer/GET_SHIPMENT_CUSTOMER';
export const CREATE_CUSTOMER_SHIPMENT = 'getcare/oms/customer/CREATE_CUSTOMER_SHIPMENT';

// mutation
export const SAVE_CUSTOMER_LIST = 'getcare/oms/customer/SAVE_CUSTOMER_LIST';
export const SAVE_LOADING = 'getcare/oms/customer/SAVE_LOADING';
export const SAVE_CUSTOMER_LIST_TOTAL = 'getcare/oms/customer/SAVE_CUSTOMER_LIST_TOTAL';

export const SAVE_SHIPMENT_CUSTOMER_INFO = 'getcare/oms/customer/SAVE_SHIPMENT_CUSTOMER_INFO';
export const SAVE_SHIPMENT_CUSTOMER_ORDERS = 'getcare/oms/customer/SAVE_SHIPMENT_CUSTOMER_ORDERS';
export const SAVE_SHIPMENT_CUSTOMER_ORDER_ITEM = 'getcare/oms/customer/SAVE_SHIPMENT_CUSTOMER_ORDER_ITEM';
