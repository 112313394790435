import {
  SAVE_DELIVERY_USER_LOADING,

  SAVE_DELIVERY_USER_LIST,
  SAVE_DELIVERY_USER_LIST_TOTAL,
} from 'redux/constants/oms/deliveryConstans';

const initialState = {
  deliveryUserLoading: false,

  deliveryUserList: [],
  deliveryUserListTotal: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DELIVERY_USER_LOADING: {
      return {
        ...state,
        deliveryUserLoading: action.deliveryUserLoading,
      };
    }

    case SAVE_DELIVERY_USER_LIST: {
      const list = action.deliveryUserList;
      return {
        ...state,
        deliveryUserList: list ? [...list] : [],
      };
    }
    case SAVE_DELIVERY_USER_LIST_TOTAL: {
      return {
        ...state,
        deliveryUserListTotal: action.deliveryUserListTotal,
      };
    }

    default:
      return state;
  }
};

export default reducer;
