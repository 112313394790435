import { suggestionPageSize } from 'utils/constanst/common';

export const VN_ID = 220;
export const listDisplayFields = [
  {
    name: 'getcare_province_name',
    label: 'Tỉnh/TP',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'getcare_district_name',
    label: 'Quận/Huyện',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'getcare_ward_name',
    label: 'Phường/Xã',
    sortable: false,
    sortDir: '',
  },
]
export const listParamsMap = {
  page_size: suggestionPageSize,
  page: 1,
  order: '',
}
