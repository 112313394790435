import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDisplayFields } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPurchaseRequestProducts, makeSelectPurchaseRequestDiscounts } from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { prProductsParamsMap, prProductsDisplayFields } from 'utils/constanst/omsPurchaseRequestConstants';

import PurchaseRequestProduct from './PurchaseRequestProduct/PurchaseRequestProduct';
import PurchaseRequestDiscount from './PurchaseRequestDiscount/PurchaseRequestDiscount';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './PurchaseRequestProducts.module.scss';
import gridClasses from './PurchaseRequestProductsGrid.module.scss';

class PurchaseRequestProducts extends PureComponent {
  render() {
    const { purchaseRequestProducts, purchaseRequestDiscounts } = this.props;
    const displayFields = getDisplayFields(prProductsParamsMap, prProductsDisplayFields);

    return (
      <div className={`${classes.Wrap}`}>
        <h4 className={classes.SectionTitle}>Danh sách hàng đặt</h4>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
          <ListHeaderCol className={`${gridClasses.Col} TextCenter`} label="#"/>
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                {...item}
                className={`${gridClasses.Col} ${item.className}`}
              />
            ))}
          </div>
          <div className={`${classes.Body}`}>
            {purchaseRequestProducts.length ? (<>
              { purchaseRequestProducts.map((item, index) => (
                <PurchaseRequestProduct
                  key={`pr-product-${item.id}`}
                  {...item}
                  index={index}
                />
              )) }
              { purchaseRequestDiscounts.map((item, index) => (
                <PurchaseRequestDiscount
                  key={`pr-discount-${item.id}`}
                  {...item}
                />
              )) }
            </>) : (
              <p className="NoData">Không có sản phẩm nào</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PurchaseRequestProducts.propTypes = {
  purchaseRequestProducts: PropTypes.array,
  purchaseRequestDiscounts: PropTypes.array,
};

PurchaseRequestProducts.defaultProps = {
  purchaseRequestProducts: [],
  purchaseRequestDiscounts: [],
};

const mapStateToProps = createStructuredSelector({
  purchaseRequestProducts: makeSelectPurchaseRequestProducts(),
  purchaseRequestDiscounts: makeSelectPurchaseRequestDiscounts(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(PurchaseRequestProducts);
