import deliveryApi from 'utils/api/oms/deliveryApi';
import { put } from 'redux-saga/effects';
import { deliveryActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadDeliveryUserList(payload) {
  const { params } = payload.payload;
  try {
    yield put(deliveryActions.saveDeliveryUserLoading(true));

    const { data: response } = yield deliveryApi.getUserList({ params });

    yield put(deliveryActions.saveDeliveryUserLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      deliveryActions.saveDeliveryUserList(response.data ? response.data : [])
    );
    yield put(deliveryActions.saveDeliveryUserListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
