import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrder } from 'redux/selectors';
import { currencyFormatVN, validNumber } from 'utils/helper';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from './SalesOrderApproveDialog.module.scss';

class SalesOrderApproveDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }

  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await salesOrderApi.approvePurchasing({
      params: {
        id: this.props.salesOrder?.id,
        amount_paid: validNumber(values.amount_paid),
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      this.props.onApproveSuccess(response.data);
      this.props.onClose();
    });
  };
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, salesOrder } = this.props;
    const { isLoading } = this.state;
    const total = salesOrder?.amount_total;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>Xác nhận duyệt đơn hàng</DialogTitle>
          <Formik
            initialValues={{
              amount_paid: '',
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validationSchema={Yup.object().shape({
              amount_paid: Yup.number().required('Vui lòng nhập').max(total, `Tối đa ${currencyFormatVN(total)}`),
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleSubmit,
              } = props;

              const amountPaied = values.amount_paid || 0;
              const amountRemained = Math.max(total - amountPaied, 0);

              return (
                <form noValidate autoComplete="off">
                  <DialogContent className={classes.DialogContent}>
                    <h4 className={classes.DialogInfo}>Đơn hàng <strong>{ salesOrder?.code }</strong> có giá trị là <strong>{ currencyFormatVN(total) }.</strong></h4>
                    <div className={classes.FieldControl}>
                      <label>
                        Khách đã trả <span className="TextMuted">(VNĐ)</span> *
                      </label>
                      <TextField
                        size="small"
                        autoComplete="off"
                        value={values.amount_paid}
                        name="amount_paid"
                        error={!!errors.amount_paid}
                        helperText={errors.amount_paid}
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>
                        Cần thu thêm <span className="TextMuted">(VNĐ)</span>
                      </label>
                      <TextField
                        disabled
                        size="small"
                        autoComplete="off"
                        value={amountRemained}
                        name="amountRemained"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </div>
                    <p className="TextDanger">Bạn có chắc muốn Duyệt đơn không?</p>
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      autoFocus
                    >
                      Xác nhận
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SalesOrderApproveDialog);
