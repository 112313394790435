export const GET_SHIPMENT_STATUSES = 'getcare/oms/shipment/GET_SHIPMENT_STATUSES';

export const GET_SHIPMENT_LIST = 'getcare/oms/shipment/GET_SHIPMENT_LIST';
export const GET_SHIPMENT = 'getcare/oms/shipment/GET_SHIPMENT';

export const PRINT_SHIPMENT = 'getcare/oms/shipment/PRINT_SHIPMENT';
export const CONFIRM_SHIPMENT_SUCCESS_DELIVERY = 'getcare/oms/shipment/CONFIRM_SHIPMENT_SUCCESS_DELIVERY';
export const RETURN_SHIPMENT_DELIVERY = 'getcare/oms/shipment/RETURN_SHIPMENT_DELIVERY';

// mutation
export const SAVE_SHIPMENT_STATUSES = 'getcare/oms/shipment/SAVE_SHIPMENT_STATUSES';

export const SAVE_LOADING = 'getcare/oms/shipment/SAVE_LOADING';

export const SAVE_SHIPMENT_LIST = 'getcare/oms/shipment/SAVE_SHIPMENT_LIST';
export const SAVE_SHIPMENT_LIST_TOTAL = 'getcare/oms/shipment/SAVE_SHIPMENT_LIST_TOTAL';

export const SAVE_SHIPMENT = 'getcare/oms/shipment/SAVE_SHIPMENT';
export const SAVE_SHIPMENT_PRODUCTS = 'getcare/oms/shipment/SAVE_SHIPMENT_PRODUCTS';
export const SAVE_SHIPMENT_LOGISTIC = 'getcare/oms/shipment/SAVE_SHIPMENT_LOGISTIC';
export const SAVE_SHIPMENT_DELIVERY = 'getcare/oms/shipment/SAVE_SHIPMENT_DELIVERY';

export const SAVE_SHIPMENT_ALL_INFO = 'getcare/oms/shipment/SAVE_SHIPMENT_ALL_INFO';
