import {
  SAVE_DELIVERY_LOGISTIC_LIST,
  SAVE_LOGISTIC_LIST,
} from 'redux/constants/logisticConstants';

const initialState = {
  deliveryLogisticList: [],
  logisticList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DELIVERY_LOGISTIC_LIST: {
      return {
        ...state,
        deliveryLogisticList: action.deliveryLogisticList,
      };
    }
    case SAVE_LOGISTIC_LIST: {
      return {
        ...state,
        logisticList: action.logisticList,
      };
    }
    default:
      return state;
  }
}

export default reducer;
