export const MAX_FILE_SIZE = 5*1024*1024;

export const listPageSizes = [50, 100, 150];
export const suggestionPageSize = 200;
export const allInPageSize = 1000;

export const DEFAULT_PAGE = `/so`;

export const ACTIVE = 1;
export const DEACTIVE = -1;
export const activeStatusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [DEACTIVE]: 'Ngừng hoạt động',
};
export const deliverMap = {
  [true]: 'Phahub giao hàng',
  [false]: 'Nhà cung cấp giao hàng',
};

export const LOGISTIC_GETCARE_ID = 1;
export const LOGISTIC_CHANH_XE_ID = 2;
export const LOGISTIC_OTHER_ID = 999;
export const logisticOtherMap = {
  id: LOGISTIC_OTHER_ID,
  name: 'Đơn vị vận chuyển khác',
}

export const LOGISTIC_GETCARE_TYPE = 1;
export const LOGISTIC_CHANHXE_TYPE = 2;
export const LOGISTIC_OTHER_TYPE = 3;
export const LOGISTIC_CLIENT_TYPE = 4;
export const LOGISTIC_VENDOR_TYPE = 5;

export function isThirdPartyLogisticProvider(providerIdOrType) {
  // return ![LOGISTIC_GETCARE_ID, LOGISTIC_CHANH_XE_ID].includes(providerId);
  return [LOGISTIC_OTHER_TYPE, LOGISTIC_OTHER_TYPE + '', LOGISTIC_OTHER_ID, LOGISTIC_OTHER_ID + ''].includes(providerIdOrType);
}
export function isChanhXeLogisticProvider(providerId) {
  return LOGISTIC_CHANH_XE_ID === providerId;
}
export function isGetcareLogisticProvider(providerId) {
  return LOGISTIC_GETCARE_ID === providerId;
}
export function getAvailableLogisticProviders(deliveryLogisticList, orderAmount) {
  if (!orderAmount || !deliveryLogisticList) return [];

  return deliveryLogisticList.filter(logistic => {
    const hasMin = !['', undefined, null].includes(logistic.min_cod);
    const hasMax = !['', undefined, null].includes(logistic.max_cod);
    if (!hasMin && !hasMax) return true;
    if (!hasMin) return orderAmount < logistic.max_cod;
    if (!hasMax) return orderAmount >= logistic.min_cod;
    return orderAmount < logistic.max_cod && orderAmount >= logistic.min_cod;
  });
}
