import {
  SAVE_PURCHASE_REQUEST_STATUSES,

  SAVE_LOADING,

  SAVE_PURCHASE_REQUEST_LIST,
  SAVE_PURCHASE_REQUEST_LIST_TOTAL,

  SAVE_PURCHASE_REQUEST,
  SAVE_PURCHASE_REQUEST_PRODUCTS,
  SAVE_PURCHASE_REQUEST_BONUSES,
  SAVE_PURCHASE_REQUEST_DISCOUNTS,
  SAVE_PURCHASE_REQUEST_NOTES,
  SAVE_PURCHASE_REQUEST_LOGISTIC,
  SAVE_PURCHASE_REQUEST_SALES_USER,
  SAVE_PURCHASE_REQUEST_DELIVERY,
} from 'redux/constants/oms/purchaseRequestConstants';

const initialState = {
  purchaseRequestStatuses: [],

  loading: false,

  purchaseRequestList: [],
  purchaseRequestListTotal: 0,

  purchaseRequest: null,
  purchaseRequestProducts: [],
  purchaseRequestBonuses: [],
  purchaseRequestDiscounts: [],
  purchaseRequestNotes: [],
  purchaseRequestLogistic: null,
  purchaseRequestSalesUser: null,
  purchaseRequestDelivery: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PURCHASE_REQUEST_STATUSES: {
      return {
        ...state,
        purchaseRequestStatuses: action.purchaseRequestStatuses ? action.purchaseRequestStatuses : [],
      };
    }

    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_PURCHASE_REQUEST_LIST: {
      const list = action.purchaseRequestList;
      return {
        ...state,
        purchaseRequestList: list ? [...list] : [],
      };
    }
    case SAVE_PURCHASE_REQUEST_LIST_TOTAL: {
      return {
        ...state,
        purchaseRequestListTotal: action.purchaseRequestListTotal,
      };
    }

    case SAVE_PURCHASE_REQUEST: {
      return {
        ...state,
        purchaseRequest: action.purchaseRequest,
      };
    }
    case SAVE_PURCHASE_REQUEST_PRODUCTS: {
      return {
        ...state,
        purchaseRequestProducts: action.purchaseRequestProducts,
      };
    }
    case SAVE_PURCHASE_REQUEST_BONUSES: {
      return {
        ...state,
        purchaseRequestBonuses: action.purchaseRequestBonuses,
      };
    }
    case SAVE_PURCHASE_REQUEST_DISCOUNTS: {
      return {
        ...state,
        purchaseRequestDiscounts: action.purchaseRequestDiscounts,
      };
    }
    case SAVE_PURCHASE_REQUEST_NOTES: {
      return {
        ...state,
        purchaseRequestNotes: action.purchaseRequestNotes,
      };
    }
    case SAVE_PURCHASE_REQUEST_LOGISTIC: {
      return {
        ...state,
        purchaseRequestLogistic: action.purchaseRequestLogistic,
      };
    }
    case SAVE_PURCHASE_REQUEST_SALES_USER: {
      return {
        ...state,
        purchaseRequestSalesUser: action.purchaseRequestSalesUser,
      };
    }
    case SAVE_PURCHASE_REQUEST_DELIVERY: {
      return {
        ...state,
        purchaseRequestDelivery: action.purchaseRequestDelivery,
      };
    }

    default:
      return state;
  }
};

export default reducer;
