import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import { isThirdPartyLogisticProvider } from 'utils/constanst/common';
import { REQUIRED_NOTE_ARR } from 'utils/constanst/omsSalesOrderConstants';
import { currencyFormatVN } from 'utils/helper';

import TextField from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Checkbox from '@material-ui/core/Checkbox';

import classes from '../DeliverySentForm.module.scss';

class OtherLogisticDelivery extends PureComponent {
  _getThirdPartyLogisticProviders = () => {
    return this.props.deliveryLogisticList.filter(item => isThirdPartyLogisticProvider(item.type));
  }
  _getSelectedProvider = (providerId) => {
    return this._getThirdPartyLogisticProviders()?.find(item => item.id === providerId);
  }
  _getServiceOptions = () => {
    const selectedProvider = this._getThirdPartyLogisticProviders().find(item => item.id === this.props.values.providerId);
    return selectedProvider?.services || [];
  }
  _getSelectedService = (service_id) => {
    const services = this._getServiceOptions();
    if (!services) return [];
    return services.find(item => item.service_id === service_id);
  }
  _getSelectedRequiredNote = (requiredNoteId) => {
    return REQUIRED_NOTE_ARR.find(item => item.id === requiredNoteId);
  }
  _getSeviceLabel = (option) => {
    return option ? `${option.service_name} (${currencyFormatVN(option.fee?.total)})` : '';
  }

  render() {
    const { 
      values, 
      errors, 
      handleChange, 
      setFieldValue, 
      amountGoods, 
      isShipmentCombined, 
      isLoadingField, 
    } = this.props;
    const selectedProvider = this._getSelectedProvider(values.providerId);

    return (<>
      <div className={`${classes.FieldGroup} ${classes.ProviderGroup}`}>
        <div className={classes.FieldControl}>
          <label>
            Đơn vị vận chuyển <span className={classes.RequiredMark}>*</span>
          </label>
          <Autocomplete
            disableClearable
            selectOnFocus
            name="providerId"
            handleHomeEndKeys={false}
            value={selectedProvider || null}
            options={this._getThirdPartyLogisticProviders()}
            getOptionLabel={(option) => option?.name || ''}
            filterOptions={(x) => x}
            renderOption={(option) => option?.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                autoFocus
                placeholder="Chọn..."
                {...params}
                error={!!errors.providerId}
                helperText={errors.providerId}
              />
            )}
            onChange={(e, newValue) => {
              setFieldValue('providerId', newValue?.id || '');
            }}
          />
        </div>
      </div>

      <div className={classes.DeliveryDetailsForm}>
        <div className={`${classes.FieldGroup}`}>
          <div className={classes.FieldControl}>
            <label>
              Tiền đơn hàng <span className={classes.TextNote}>(VNĐ)</span> <span className={classes.RequiredMark}>*</span>
            </label>
            <TextField
              size="small"
              autoComplete="off"
              value={values.amount_goods}
              name="amount_goods"
              error={!!errors.amount_goods}
              helperText={errors.amount_goods}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  allowNegative: true,
                },
              }}
              onChange={(e) => {
                this.props.onAmountChange({
                  ...values,
                  [e.target.name]: e.target.value,
                }, setFieldValue);
                handleChange(e);
              }}
            />
          </div>
          <div className={classes.FieldControl}>
            <label>
              Trọng lượng <span className={classes.TextNote}>(kg)</span> <span className={classes.RequiredMark}>*</span>
            </label>
            <TextField
              autoComplete="off"
              value={values.weight}
              name="weight"
              error={!!errors.weight}
              helperText={errors.weight}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className={`${classes.FieldGroup}`}>
          <div className={classes.FieldControl}>
            <label>
              Phí thu khách <span className={classes.TextNote}>(VNĐ)</span> <span className={classes.RequiredMark}>*</span>
            </label>
            <TextField
              size="small"
              autoComplete="off"
              value={values.total_fee}
              name="total_fee"
              error={!!errors.total_fee}
              helperText={errors.total_fee}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              onChange={(e) => {
                this.props.onAmountChange({
                  ...values,
                  [e.target.name]: e.target.value,
                }, setFieldValue);
                handleChange(e);
              }}
            />
          </div>
          <div className={classes.FieldControl}>
            <label>
              Kích thước <span className={classes.TextNote}>(cm)</span> <span className={classes.RequiredMark}>*</span>
            </label>
            <div>
              <div className={classes.FieldGroupSize}>
                <TextField
                  autoComplete="off"
                  value={values.length}
                  name="length"
                  error={!!errors.length}
                  placeholder="Dài"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onChange={handleChange}
                />
                <TextField
                  autoComplete="off"
                  value={values.width}
                  name="width"
                  error={!!errors.width}
                  placeholder="Rộng"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onChange={handleChange}
                />
                <TextField
                  autoComplete="off"
                  value={values.height}
                  name="height"
                  error={!!errors.height}
                  placeholder="Cao"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  onChange={handleChange}
                />
              </div>
              { (!!errors.length || !!errors.width || !!errors.height) && <span className={`${classes.HelperText} TextDanger`}>Vui lòng nhập kích thước, tối đa 99.</span>}
            </div>
          </div>
        </div>
        <div className={`${classes.FieldGroup}`}>
          <div className={classes.FieldControl}>
            <label>
              COD <span className={classes.TextNote}>(VNĐ)</span> <span className={classes.RequiredMark}>*</span>
            </label>
            <TextField
              disabled
              size="small"
              autoComplete="off"
              value={values.cod}
              name="cod"
              error={!!errors.cod}
              helperText={errors.cod}
              InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: {
                  allowNegative: true,
                },
              }}
              onChange={handleChange}
            />
          </div>
        </div>
        { isShipmentCombined &&
          <div className={`${classes.FieldGroup}`}>
            <div className={classes.FieldControl}>
              <label>
                Phí thu khách dự kiến
              </label>
              <TextField
                disabled
                size="small"
                autoComplete="off"
                value={values.total_fee_2}
                name="total_fee_2"
                error={!!errors.total_fee_2}
                helperText={errors.total_fee_2}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={handleChange}
              />
            </div>
          </div>
        }
        <div className={`${classes.FieldGroup}`}>
          <div className={classes.FieldControl}>
            <label>
              Mua bảo hiểm <span className={classes.TextNote}>(VNĐ)</span>
            </label>
            <div>
              <Checkbox
                size="small"
                name="insurance_value"
                checked={!!values.insurance_value}
                style={{ padding: 0 }}
                onChange={(e) => {
                  const newValue = e.target.checked ? amountGoods : 0;
                  setFieldValue('insurance_value', newValue);
                }}
              />
            </div>
          </div>
          <div className={classes.FieldControl}>
            <label>
              Yêu cầu khi giao <span className={classes.RequiredMark}>*</span>
            </label>
            <Autocomplete
              disableClearable
              name="required_note"
              handleHomeEndKeys={false}
              value={this._getSelectedRequiredNote(values.required_note) || null}
              options={REQUIRED_NOTE_ARR}
              getOptionLabel={(option) => option?.name || ''}
              renderOption={(option) => option?.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  placeholder="Chọn..."
                  {...params}
                  error={!!errors.required_note}
                  helperText={errors.required_note}
                />
              )}
              onChange={(e, newValue) => {
                setFieldValue('required_note', newValue?.id || '');
              }}
            />
          </div>
        </div>
        <div className={`${classes.FieldGroup}`}>
          <div className={classes.FieldControl}>
            <label className={classes.FieldLabel}>
              Gói dịch vụ <span className={classes.RequiredMark}>*</span> 
              <Tooltip title="Lưu ý giá trị gói dịch vụ sẽ thay đổi tùy theo trọng lượng/kích thước/Đổi địa chỉ lấy hàng nên vui lòng kiểm tra kỹ trước khi xác nhận gửi đơn" placement="top">
                <InfoIcon className={classes.LabelIcon}/>
              </Tooltip>
            </label>
            <Autocomplete
              disableClearable
              name="service_id"
              handleHomeEndKeys={false}
              value={this._getSelectedService(values.service_id) || null}
              options={isLoadingField?.service_id ? [] : this._getServiceOptions()}
              getOptionLabel={(option) => this._getSeviceLabel(option)}
              renderOption={(option) => this._getSeviceLabel(option)}
              getOptionSelected={(option, value) => option.service_id === value.service_id}
              loading={isLoadingField?.service_id}
              loadingText="Đang tải..."
              renderInput={(params) => (
                <TextField
                  autoFocus
                  placeholder="Chọn..."
                  {...params}
                  error={!!errors.service_id}
                  helperText={errors.service_id}
                />
              )}
              onChange={(e, newValue) => {
                setFieldValue('service_id', newValue?.service_id || '');
              }}
            />
          </div>
          <div className={classes.FieldControl}>
            <label>
              Ngày lấy hàng <span className={classes.RequiredMark}>*</span>
            </label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                autoOk
                variant="inline"
                format={dateFormat}
                name="pickup_date"
                value={validDate(values.pickup_date) ? validDate(values.pickup_date) : null}
                onChange={(date) => {
                  const value = validDate(date) ? date.toISOString() : '';
                  setFieldValue('pickup_date', value);
                }}
                error={!!errors.pickup_date}
                helperText={errors.pickup_date}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className={classes.FieldControl}>
          <label>
            Ghi chú giao hàng <span className={classes.RequiredMark}>*</span>
          </label>
          <TextField
            autoComplete="off"
            rowsMax={3}
            multiline
            value={values.note}
            placeholder="Nhập ghi chú giao hàng"
            name="note"
            error={!!errors.note}
            helperText={errors.note}
            onChange={handleChange}
          />
        </div>
      </div>
    </>)
  }
}

OtherLogisticDelivery.propTypes = {
  deliveryLogisticList: PropTypes.array,
  onAmountChange: PropTypes.func,
  isShipmentCombined: PropTypes.bool,
};

OtherLogisticDelivery.defaultProps = {
  isShipmentCombined: false,
};

export default OtherLogisticDelivery;
