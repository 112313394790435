import React, { PureComponent } from 'react';
import { currencyFormatVN, roundCurrency } from 'utils/helper';

import Tooltip from '@material-ui/core/Tooltip';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import classes from './PurchaseRequestProduct.module.scss';
import gridClasses from '../PurchaseRequestProductsGrid.module.scss';

class PurchaseRequestProduct extends PureComponent {
  render() {
    const {
      index,
      product_name,
      vendor_name,
      uom_base_name,
      price_sales_total,
      quantity_number,
      available_number,
      vat,
      getcare_purchase_request_item_discounts,
      type_label,
    } = this.props;
    const realQty = !['', null, undefined].includes(available_number) ? available_number : quantity_number;

    return (<>
      <div className={`${gridClasses.Row} ${classes.Item}`}>
        <div className={`${gridClasses.Col} TextCenter`}>{index + 1}</div>
        <div className={`${gridClasses.Col}`}>
          {product_name}
          { ['', null, undefined].includes(vat) && (
            <Tooltip title={`Sản phẩm không xuất hoá đơn`} arrow placement="top">
              <ReportProblemOutlinedIcon className={classes.NoInvoiceIcon} fontSize="small"/>
            </Tooltip>
          ) }
        </div>
        <div className={`${gridClasses.Col}`}>{vendor_name}</div>
        <div className={`${gridClasses.Col}`}>{uom_base_name}</div>
        <div className={`${gridClasses.Col}`}>{type_label || `-`}</div>
        <div className={`${gridClasses.Col} TextRight`}>{![null, undefined, ''].includes(price_sales_total) ? currencyFormatVN(price_sales_total) : ``}</div>
        <div className={`${gridClasses.Col} TextCenter`}>{quantity_number}</div>
        <div className={`${gridClasses.Col} TextRight`}>
          { currencyFormatVN(roundCurrency(price_sales_total * Number(realQty))) }
        </div>
      </div>
      { getcare_purchase_request_item_discounts && getcare_purchase_request_item_discounts.length > 0 &&
        getcare_purchase_request_item_discounts.map((item, index) => (
          <div key={`pr-discount-${index}`} className={`${gridClasses.Row} ${gridClasses.LineDiscountRow} ${classes.Item} ${classes.DiscountItem}`}>
            <div className={`${gridClasses.Col}`}></div>
            <div className={`${gridClasses.Col}`}></div>
            <div className={gridClasses.GroupCol}>
              <div className={`${gridClasses.Col}`}>{`${item.promotion_bonus_name ? item.promotion_bonus_name + ` ` : ``}${item.promotion_code}`}</div>
              <div className={`${gridClasses.Col} TextRight`}>{ ['', null, undefined].includes(item.amount) ? '' : `(${currencyFormatVN(item.amount)})` }</div>
            </div>
          </div>
        ))
      }
    </>);
  }
}

export default PurchaseRequestProduct;
