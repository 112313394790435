import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import { validDate } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderNotes, makeSelectLoginUser } from 'redux/selectors';
import { saveSalesOrderNoteItem, removeSalesOrderNoteItem } from 'redux/actions/oms/salesOrderActions';
import { orderBy } from 'lodash';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Panel from 'components/Panel/Panel';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import SalesOrderNoteFormDialog from 'components/so/SalesOrderNoteFormDialog/SalesOrderNoteFormDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import AddIcon from '@material-ui/icons/Add';

import classes from './SalesOrderNotes.module.scss';

class SalesOrderNotes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openNoteFormDialog: false,
      selectedNoteItem: null,

      isConfirmDialogOpen: false,
      isDialogLoading: false,
    }
  }
  _getOrderedSalesOrderNotes = () => {
    return orderBy(this.props.salesOrderNotes, 'created_at', 'desc');
  }
  _hasUpdateNotePermission = (userId) => {
    return this.props.user?.id === userId;
  }

  handleEditNoteItem = (noteItem) => {
    this.setState({
      selectedNoteItem: { ...noteItem },
    }, () => {
      this.handleOpenNoteFormDialog();
    });
  }
  handleOpenNoteFormDialog = () => {
    this.setState({
      openNoteFormDialog: true,
    });
  }
  handleCloseNoteFormDialog = () => {
    this.setState({
      openNoteFormDialog: false,
      selectedNoteItem: null,
    });
  }
  handleSubmitNoteSuccess = (newNoteItem) => {
    this.props.saveSalesOrderNoteItem(newNoteItem);
  }

  handleRemoveNoteItem = (noteItem) => {
    this.setState({
      selectedNoteItem: { ...noteItem },
    }, () => {
      this.handleConfirmDialogOpen();
    });
  }
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
      selectedNoteItem: null,
    });
  }
  handleSubmitRemoveNoteItem = async () => {
    const { selectedNoteItem } = this.state;
    this.setState({
      isDialogLoading: true,
    });
    const { data: response } = await salesOrderApi.deleteSalesOrderNote({
      id: selectedNoteItem?.id,
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    this.setState({
      isDialogLoading: false,
    }, () => {
      this.handleRemoveNoteSuccess(selectedNoteItem);
      this.handleConfirmDialogClose();
    });
  }
  handleRemoveNoteSuccess = (noteItem) => {
    this.props.removeSalesOrderNoteItem(noteItem);
  }

  render() {
    const orderedNotes = this._getOrderedSalesOrderNotes();

    return (<>
      <Panel
        title="Ghi chú"
        titleAction={
          <Button
            size="small"
            color="primary"
            startIcon={<AddIcon fontSize="small" />}
            onClick={this.handleOpenNoteFormDialog}
          >Thêm ghi chú</Button>
        }
        panelClassName={classes.Panel}
        content={<div className={classes.Wrap}>
          { orderedNotes && orderedNotes.length > 0 ? (<>
              { orderedNotes.map(note => (
                <div key={`note-${note.id}`} className={classes.NoteItem}>
                  <div className={classes.NoteInfo}>
                    <div>
                      <span className={`${classes.NoteOwner} TextSecondary`}>{note.user_name}</span>
                      <span className={classes.NoteDate}>{validDate(note.created_at) ? format(validDate(note.created_at), dateTimeFormat) : ''}</span>
                    </div>
                    { this._hasUpdateNotePermission(note.getcare_user_id) &&
                      <div className={classes.NoteActions}>
                        <IconButton
                          onClick={() => this.handleRemoveNoteItem(note)}
                          style={{ padding: '0' }}
                          size="small"
                        >
                          <DeleteOutline fontSize="small" />
                        </IconButton>
                        <IconButton
                          onClick={() => this.handleEditNoteItem(note)}
                          style={{ padding: '0' }}
                          size="small"
                        >
                          <Edit fontSize="small" color="primary" />
                        </IconButton>
                      </div>
                    }
                  </div>
                  <p className={`${classes.NoteContent} PreWrap`}>{note.note}</p>
                </div>
              )) }
            </>) : <p className="NoData">Không có ghi chú nào</p>
          }
        </div>}
        isBorder
      />
      {
        this.state.openNoteFormDialog &&
        <SalesOrderNoteFormDialog
          isOpen={this.state.openNoteFormDialog}
          noteItem={this.state.selectedNoteItem}
          salesOrderId={this.props.salesOrderId}
          onClose={this.handleCloseNoteFormDialog}
          onSubmitSuccess={this.handleSubmitNoteSuccess}
        />
      }
      { this.state.isConfirmDialogOpen
        && <ConfirmationDialog
          isOpen={this.state.isConfirmDialogOpen}
          title="Xác nhận xoá"
          isLoading={this.state.isDialogLoading}
          message="Bạn có chắc xoá ghi chú này?"
          onClose={this.handleConfirmDialogClose}
          onSubmit={this.handleSubmitRemoveNoteItem}
        />
      }
    </>)
  }
}

SalesOrderNotes.propTypes = {
  salesOrderNotes: PropTypes.array,
};

SalesOrderNotes.defaultProps = {
  salesOrderNotes: [],
};

const mapStateToProps = createStructuredSelector({
  salesOrderNotes: makeSelectSalesOrderNotes(),
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesOrderNoteItem: (payload) => dispatch(saveSalesOrderNoteItem(payload)),
    removeSalesOrderNoteItem: (payload) => dispatch(removeSalesOrderNoteItem(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesOrderNotes);
