import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrder } from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { isEqual, debounce } from 'lodash';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { Link } from 'react-router-dom';
import { getStatusClass } from 'utils/constanst/omsSalesOrderConstants';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import classes from './SalesOrderInfo.module.scss';

class SalesOrderInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      codeSearchInput: '',
      codeOptions: [],
      selectedCode: null,
    }
  }
  componentDidMount() {
    if (this.props.salesOrder) {
      this.setState({
        ...this._initState(this.props.salesOrder),
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { salesOrder } = this.props;
    if (salesOrder && !isEqual(salesOrder, prevProps.salesOrder)) {
      this.setState({
        ...this._initState(salesOrder),
      });
    }
    const { codeSearchInput } = this.state;
    if (codeSearchInput && codeSearchInput !== prevState.codeSearchInput) {
      this._loadSuggestedCodes({
        params: {
          code: codeSearchInput,
        }
      });
    }
  }

  _initState = (params) => {
    return {
      codeSearchInput: params.code,
      selectedCode: {
        id: params.id,
        code: params.code,
      },
      codeOptions: [{id: params.id, code: params.code}],
    }
  }
  _loadSuggestedCodes = debounce(async ({ params }) => {
    const { data: response } = await salesOrderApi.getAll({ params });
    if (!response?.result) return;
    this.setState({
      codeOptions: response.data ? response.data : [],
    });
  }, 500)

  handleChangeSelectedCode = (e, newValue) => {
    if (!newValue) return;
    this.props.history.push(`/so/${newValue.code}`);
  }

  render() {
    const { salesOrder } = this.props;
    const { selectedCode, codeOptions, codeSearchInput } = this.state;

    return (
      <div className={classes.Wrap}>
        <div className={classes.ColInner}>
          <div className={`${classes.Info} ${classes.CodeInfo}`}>
            <label>Mã đơn hàng</label>
            <Autocomplete
              disableClearable
              handleHomeEndKeys={false}
              value={selectedCode || null}
              onChange={this.handleChangeSelectedCode}
              inputValue={codeSearchInput}
              onInputChange={(e, newValue) => {
                this.setState({
                  codeSearchInput: newValue,
                });
              }}
              id="combo-box-codes"
              options={codeOptions}
              filterOptions={(x) => x}
              renderOption={(option) => option.code}
              getOptionLabel={(option) => option?.code || ''}
              getOptionSelected={(option, value) =>
                value && option && value.id && option.id ? option.id === value.id : null
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div className={`${classes.Info} ${classes.Center}`}>
            <label>Trạng thái</label>
            <div>
              <span className={getStatusClass(salesOrder?.getcare_order_status?.id)}>
                {salesOrder?.getcare_order_status?.name}
              </span>
            </div>
          </div>
        </div>
        <div className={classes.ColInner}>
          <div className={classes.Info}>
            <label>Mã đơn đặt hàng</label>
            {salesOrder?.pr_code ? <Link className="TextSecondary" to={`/pr/${salesOrder?.pr_code}`}>{ salesOrder?.pr_code }</Link> : `-`}
          </div>
        </div>
        <div className={`${classes.ColInner} ${classes.CustomerCol}`}>
          <div className={`${classes.Info} ${classes.SecondaryInfo}`}>
            <label>Khách hàng</label>
            <span>{salesOrder?.customer_name}<span className={classes.iconNew}> {salesOrder?.is_new_customer === 1 ? 'new' : ''}</span></span>
          </div>
          <div className={`${classes.Info} ${classes.SecondaryInfo}`}>
            <label>Mã khách hàng</label>
            <span>{salesOrder?.customer_code}</span>
          </div>
          <div className={`${classes.Info} ${classes.SecondaryInfo}`}>
            <label>Địa chỉ</label>
            {salesOrder?.customer_address}
          </div>
          <div className={`${classes.Info} ${classes.SecondaryInfo}`}>
            <label>Mã số thuế</label>
            {salesOrder?.customer_taxcode || `-`}
          </div>
        </div>
      </div>
    );
  }
}

SalesOrderInfo.propTypes = {
  salesOrder: PropTypes.object,
};

SalesOrderInfo.defaultProps = {
  salesOrder: null,
};

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(SalesOrderInfo);
