export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã đặt hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_order_type_name',
    label: 'Loại đơn',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_purchase_request_status_name',
    label: 'Trạng thái',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'customer_name',
    label: 'Khách hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'created_at',
    label: 'Thời gian tạo',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'sales_name',
    label: 'Phụ trách bởi',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'amount_total',
    label: 'Giá trị đơn hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'order_most_items',
    label: 'Sản phẩm',
  },
  {
    name: 'note_latest',
    label: 'Ghi chú gần nhất',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};
export const filterFields = [
  {
    name: 'code',
    label: 'Mã đặt hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_order_type_name',
    label: 'Loại đơn',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_purchase_request_status_id',
    label: 'Trạng thái',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'customer_name',
    label: 'Khách hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'customer_address',
    label: 'Địa chỉ',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'created_at',
    label: 'Thời gian tạo',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập hoặc chọn...',
  },
  {
    name: 'user_name_created',
    label: 'Người tạo',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'amount_total',
    label: 'Giá trị đơn hàng',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_purchase_request_item_product_name',
    label: 'Sản phẩm',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
];

// purchase request details
export const prProductsDisplayFields = [
  {
    name: 'product_name',
    label: 'Tên thuốc/Sản phẩm',
  },
  {
    name: 'vendor_name',
    label: 'Nhà cung cấp',
  },
  {
    name: 'uom_base_name',
    label: 'Đơn vị',
  },
  {
    name: 'type_label',
    label: 'Nhãn',
  },
  {
    name: 'price_sales_total',
    label: 'Đơn giá',
    className: 'TextRight',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng đặt',
    className: 'TextCenter',
  },
  {
    name: 'amount_total',
    label: 'Thành tiền',
    className: 'TextRight',
  },
];
export const prProductsParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};

export const prBonusesDisplayFields = [
  {
    name: 'promotion_name',
    label: 'Chương trình khuyến mãi',
  },
  {
    name: 'product_bonus_name',
    label: 'Sản phẩm khuyến mãi',
  },
  {
    name: 'uom_base_name',
    label: 'Đơn vị',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng',
  },
];
export const prBonusesParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};

export const AWAITING_PURCHASE = 1; // đợi mua hàng
export const LACKING_STOCK = 2; // thiếu hàng
export const CONFIRMED_PURCHASE = 3; // đã xác nhận
export const AWAITING_DELIVERY = 4; // chờ giao hàng
export const IN_PROGRESS_DELIVERY = 5; // đang giao hàng
export const DELIVERY_SUCCESS = 6; // giao hàng thành công
export const ORDER_SUCCESS = 7; // thành công
export const IS_PROGRESS_RETURN = 8; // đang trả hàng
export const RETURNED = 9; // đã trả hàng
export const CANCELLED = 10; // huỷ đơn hàng

export function getStatusClass(soStatus) {
  if ([IS_PROGRESS_RETURN, RETURNED ].includes(soStatus)) {
    return 'StatusLabel Warning';
  }
  if ([IN_PROGRESS_DELIVERY ].includes(soStatus)) {
    return 'StatusLabel Info';
  }
  if ([DELIVERY_SUCCESS, ORDER_SUCCESS ].includes(soStatus)) {
    return 'StatusLabel Success';
  }
  if ([LACKING_STOCK, CANCELLED ].includes(soStatus)) {
    return 'StatusLabel Danger';
  }
  return 'StatusLabel Default';
}
