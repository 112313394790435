import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPurchaseRequestLogistic } from 'redux/selectors';
import { isThirdPartyLogisticProvider } from 'utils/constanst/common';

import Panel from 'components/Panel/Panel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import classes from './PurchaseRequestLogisticProvider.module.scss';

class PurchaseRequestLogisticProvider extends PureComponent {
  render() {
    const selectedLogistic = this.props.purchaseRequestLogistic;
    const _isThirdPartyLogisticProvider = isThirdPartyLogisticProvider(selectedLogistic?.id);

    return (<Panel
      title="Đơn vị vận chuyển"
      panelClassName={classes.Panel}
      content={<div className={classes.Wrap}>
        { selectedLogistic ? (
            <>
              <h4>
                <strong>{selectedLogistic?.name}</strong>
                { !_isThirdPartyLogisticProvider && ` (${selectedLogistic.total_fee ? 'Phí dự kiến ' + currencyFormatVN(selectedLogistic.total_fee) : 'Phí vận chuyển sẽ do Phahub chỉ định sau'})` }
              </h4>
              { _isThirdPartyLogisticProvider &&
                <div className={classes.Options}>
                  { selectedLogistic?.service_id &&
                    <p key={`service-${selectedLogistic.service_id}`} className={classes.Service}>
                      <RadioButtonCheckedIcon fontSize="small" color="secondary"/>
                      <span className={classes.ServiceName}>
                        { `${selectedLogistic.service_name} ${![null, undefined, ''].includes(selectedLogistic.total_fee) ? '(' + currencyFormatVN(selectedLogistic.total_fee) + ')' : ``}` }
                      </span>
                    </p>
                  }
                </div>
              }
            </>
          ) : <p className="NoData">Không có dịch vụ vận chuyển</p>
        }
      </div>}
      isBorder
    />)
  }
}

PurchaseRequestLogisticProvider.propTypes = {
  purchaseRequestLogistic: PropTypes.object,
};

PurchaseRequestLogisticProvider.defaultProps = {
  purchaseRequestLogistic: null,
};

const mapStateToProps = createStructuredSelector({
  purchaseRequestLogistic: makeSelectPurchaseRequestLogistic(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PurchaseRequestLogisticProvider);
