import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDisplayFields } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPurchaseRequestBonuses } from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { prBonusesParamsMap, prBonusesDisplayFields } from 'utils/constanst/omsPurchaseRequestConstants';

import PurchaseRequestBonus from './PurchaseRequestBonus/PurchaseRequestBonus';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './PurchaseRequestBonuses.module.scss';
import gridClasses from './PurchaseRequestBonusesGrid.module.scss';

class PurchaseRequestBonuses extends PureComponent {
  render() {
    const { purchaseRequestBonuses } = this.props;
    const displayFields = getDisplayFields(prBonusesParamsMap, prBonusesDisplayFields);

    return (<>
      { purchaseRequestBonuses && purchaseRequestBonuses.length > 0 &&
        <div className={`${classes.Wrap}`}>
          <h4 className={classes.SectionTitle}><strong>Danh sách hàng khuyến mãi</strong></h4>
          <div className={`${classes.List}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              <ListHeaderCol label="#"/>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={gridClasses.Col}
                  {...item}
                />
              ))}
            </div>
            <div className={`${classes.Body}`}>
              {purchaseRequestBonuses.map((item, index) => (
                <PurchaseRequestBonus
                  key={`pr-product-${item.id}`}
                  {...item}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      }
    </>);
  }
}

PurchaseRequestBonuses.propTypes = {
  purchaseRequestBonuses: PropTypes.array,
};

PurchaseRequestBonuses.defaultProps = {
  purchaseRequestBonuses: [],
};

const mapStateToProps = createStructuredSelector({
  purchaseRequestBonuses: makeSelectPurchaseRequestBonuses(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(PurchaseRequestBonuses);
