import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CustomerListItem from './CustomerListItem/CustomerListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './CustomerListGrid.module.scss';
import classes from './CustomerList.module.scss';

class CustomerList extends PureComponent {
  render() {
    const { customerList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
         <div className={`$${classes.Header}`}>
         <div className={`${gridClasses.Row} ${classes.Header}`}>
          <div className={`${gridClasses.Col} TextCenter`}>STT</div>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={`${gridClasses.Col} ${item.className}`}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
          <div className={`${gridClasses.Col} ${gridClasses.ActionsCol}`}></div>
        </div>
            </div>

      <div className={`${classes.Body}`}>
      
        { !isLoading && customerList.length
          ? customerList.map((item, index) => (
            <CustomerListItem
              key={`awaiting-customer-${item.id}-${index}`}
              {...item}
              index={index}
              onCreateShipment={this.props.onCreateShipment}
            />))
          : (<p className="NoData">Không có khách hàng nào</p>)
        }
      </div>
    </div>;
  }
}

CustomerList.propTypes = {
  displayFields: PropTypes.array,
  customerList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
  onCreateShipment: PropTypes.func,
};

CustomerList.defaultProps = {
  displayFields: [],
  customerList: [],
  isLoading: false,
};

export default CustomerList;
