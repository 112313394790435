import {
  GET_SALES_CHANNEL_LIST,
  SAVE_SALES_CHANNEL_LIST,
  SAVE_SALES_CHANNEL_LIST_TOTAL,
} from 'redux/constants/mdm/salesChannelConstants';

export function getSalesChannelList(payload) {
  return {
    type: GET_SALES_CHANNEL_LIST,
    payload
  };
}

// mutation
export function saveSalesChannelList(salesChannelList) {
  return {
    type: SAVE_SALES_CHANNEL_LIST,
    salesChannelList
  };
}
export function saveSalesChannelListTotal(salesChannelListTotal) {
  return {
    type: SAVE_SALES_CHANNEL_LIST_TOTAL,
    salesChannelListTotal
  };
}
