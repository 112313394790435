import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPurchaseRequest } from 'redux/selectors';
import { currencyFormatVN, validNumber } from 'utils/helper';

import Panel from 'components/Panel/Panel';

import classes from './PurchaseRequestAmountInfo.module.scss';

class PurchaseRequestAmountInfo extends PureComponent {
  _getAmountShippingCoupon = () => {
    const { purchaseRequest } = this.props;
    return purchaseRequest?.amount_shipping_coupon || 0;
  };

  render() {
    const { purchaseRequest } = this.props;
    const subTotal = purchaseRequest?.amount + purchaseRequest?.amount_vat;
    const amountShippingCoupon = this._getAmountShippingCoupon();

    return (<Panel
      title="Thông tin thanh toán"
      size="sm"
      panelClassName={classes.Panel}
      content={<div className={classes.Wrap}>
        { purchaseRequest && (
          <>
            <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>Giá trị đơn hàng</label>
              {validNumber(subTotal) ? currencyFormatVN(subTotal) : `-`}
            </div>
            <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>Giảm giá</label>
              {validNumber(purchaseRequest?.amount_discount) ? `-${currencyFormatVN(purchaseRequest.amount_discount)}` : `-`}
            </div>
            <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>Phí vận chuyển</label>
              {validNumber(purchaseRequest?.amount_delivery) ? currencyFormatVN(purchaseRequest?.amount_delivery) : `-`}
            </div>
            {amountShippingCoupon > 0 && (
              <div className={`${classes.Info} ${classes.InfoLine}`}>
                <label>Shipping coupon</label>
                {validNumber(amountShippingCoupon) ? `-${currencyFormatVN(amountShippingCoupon)}` : `-`}
              </div>
            )}
            <div className={`${classes.Info} ${classes.InfoLine} ${classes.InfoTotal}`}>
              <label>Tổng cộng</label>
              <span className={classes.Amount}>{validNumber(purchaseRequest?.amount_total) ? currencyFormatVN(purchaseRequest?.amount_total) : `-`}</span>
            </div>
            <div className={`${classes.Info} ${classes.HelpLine}`}>
              (Đã bao gồm VAT nếu có)
            </div>
          </>)
        }
      </div>}
      isBorder
    />)
  }
}

PurchaseRequestAmountInfo.propTypes = {
  purchaseRequest: PropTypes.object,
};
PurchaseRequestAmountInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  purchaseRequest: makeSelectPurchaseRequest(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PurchaseRequestAmountInfo);
