import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import { validDate } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPurchaseRequestNotes } from 'redux/selectors';

import Panel from 'components/Panel/Panel';

import classes from './PurchaseRequestNotes.module.scss';

class PurchaseRequestNotes extends PureComponent {
  render() {
    const { purchaseRequestNotes } = this.props;

    return (<Panel
      title="Ghi chú"
      panelClassName={classes.Panel}
      content={<div className={classes.Wrap}>
        { purchaseRequestNotes && purchaseRequestNotes.length > 0 ? (<>
            { purchaseRequestNotes.map(note => (
              <div key={`note-${note.id}`} className={classes.NoteItem}>
                <div className={classes.NoteInfo}>
                  <span className={`${classes.NoteOwner} TextSecondary`}>{note.user_name}</span>
                  <span className={classes.NoteDate}>{validDate(note.created_at) ? format(validDate(note.created_at), dateTimeFormat) : ''}</span>
                </div>
                <p className={`${classes.NoteContent} PreWrap`}>{note.note}</p>
              </div>
            )) }
          </>) : <p className="NoData">Không có ghi chú nào</p>
        }
      </div>}
      isBorder
    />)
  }
}

PurchaseRequestNotes.propTypes = {
  purchaseRequestNotes: PropTypes.array,
};

PurchaseRequestNotes.defaultProps = {
  purchaseRequestNotes: [],
};

const mapStateToProps = createStructuredSelector({
  purchaseRequestNotes: makeSelectPurchaseRequestNotes(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PurchaseRequestNotes);
