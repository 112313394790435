import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectShipment } from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { isEqual, debounce } from 'lodash';
import shipmentApi from 'utils/api/oms/shipmentApi';
import { Link } from 'react-router-dom';
import { getShipmentStatusClass } from 'utils/constanst/omsShipmentConstants';
import { getFullAddressStr } from 'utils/helper';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import classes from './ShipmentInfo.module.scss';

class ShipmentInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      codeSearchInput: '',
      codeOptions: [],
      selectedCode: null,
    }
  }
  componentDidMount() {
    if (this.props.Shipment) {
      this.setState({
        ...this._initState(this.props.shipment),
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { shipment } = this.props;
    if (shipment && !isEqual(shipment, prevProps.shipment)) {
      this.setState({
        ...this._initState(shipment),
      });
    }
    const { codeSearchInput } = this.state;
    if (codeSearchInput && codeSearchInput !== prevState.codeSearchInput) {
      this._loadSuggestedCodes({
        params: {
          code: codeSearchInput,
        }
      });
    }
  }

  _initState = (params) => {
    return {
      codeSearchInput: params.code,
      selectedCode: {
        id: params.id,
        code: params.code,
      },
      codeOptions: [{id: params.id, code: params.code}],
    }
  }
  _loadSuggestedCodes = debounce(async ({ params }) => {
    const { data: response } = await shipmentApi.getAll({ params });
    if (!response?.result) return;
    this.setState({
      codeOptions: response.data ? response.data : [],
    });
  }, 500)

  handleChangeSelectedCode = (e, newValue) => {
    if (!newValue) return;
    this.props.history.push(`/shipment/${newValue.code}`);
  }

  render() {
    const { shipment } = this.props;
    const { selectedCode, codeOptions, codeSearchInput } = this.state;

    return (
      <div className={classes.Wrap}>
        <div className={classes.ColInner}>
          <div className={`${classes.Info} ${classes.CodeInfo}`}>
            <label>Mã đơn giao hàng</label>
            <Autocomplete
              disableClearable
              handleHomeEndKeys={false}
              value={selectedCode || null}
              onChange={this.handleChangeSelectedCode}
              inputValue={codeSearchInput}
              onInputChange={(e, newValue) => {
                this.setState({
                  codeSearchInput: newValue,
                });
              }}
              id="combo-box-codes"
              options={codeOptions}
              filterOptions={(x) => x}
              renderOption={(option) => option.code}
              getOptionLabel={(option) => option?.code || ''}
              getOptionSelected={(option, value) =>
                value && option && value.id && option.id ? option.id === value.id : null
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div className={`${classes.Info} ${classes.Center}`}>
            <label>Trạng thái</label>
            <div>
              <span className={getShipmentStatusClass(shipment?.getcare_delivery_status?.id)}>
                {shipment?.getcare_delivery_status?.name}
              </span>
            </div>
          </div>
        </div>
        <div className={classes.ColInner}>
          <div className={classes.Info}>
            <label>Mã đơn hàng</label>
            <ul className={classes.SOCodes}>
              { shipment?.getcare_order_codes?.split(',').map(item => (
                <li key={item}>
                  <Link className="TextSecondary" to={`/so/${item}`}>{ item }</Link>
                </li>
              )) }
            </ul>
          </div>
        </div>
        <div className={`${classes.ColInner} ${classes.CustomerCol}`}>
          <div className={`${classes.Info} ${classes.SecondaryInfo}`}>
            <label>Khách hàng</label>
            {shipment?.getcare_pharmacy?.name}
          </div>
          <div className={`${classes.Info} ${classes.SecondaryInfo}`}>
            <label>Mã khách hàng</label>
            <span>{shipment?.getcare_pharmacy?.code}</span>
          </div>
          <div className={`${classes.Info} ${classes.SecondaryInfo}`}>
            <label>Địa chỉ</label>
            { getFullAddressStr(
                shipment?.getcare_pharmacy?.address,
                { name: shipment?.getcare_pharmacy?.getcare_ward?.name },
                { name: shipment?.getcare_pharmacy?.getcare_district?.name },
                { name: shipment?.getcare_pharmacy?.getcare_province?.name }
            ) }
          </div>
          <div className={`${classes.Info} ${classes.SecondaryInfo}`}>
            <label>Mã số thuế</label>
            {shipment?.getcare_pharmacy?.tax_code || `-`}
          </div>
        </div>
      </div>
    );
  }
}

ShipmentInfo.propTypes = {
  shipment: PropTypes.object,
};

ShipmentInfo.defaultProps = {
  shipment: null,
};

const mapStateToProps = createStructuredSelector({
  shipment: makeSelectShipment(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(ShipmentInfo);
