import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/omsPurchaseRequestConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getPurchaseRequestStatuses } from 'redux/actions/oms/purchaseRequestActions';
import { createStructuredSelector } from 'reselect';
import { makeSelectPurchaseRequestStatuses } from 'redux/selectors';

import FilterField from 'components/FilterField/FilterField';
import PriceRange from './PriceRange/PriceRange';
import DateRange from './DateRange/DateRange';
import MultiSelect from 'components/FilterField/MultiSelect';

import classes from './PurchaseRequestListFilters.module.scss';

class PurchaseRequestListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }
  componentDidMount() {
    this.props.getPurchaseRequestStatuses();
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            ['amount_total'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<PriceRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['created_at'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<DateRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['getcare_purchase_request_status_id'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                customField={<MultiSelect
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  options={this.props.purchaseRequestStatuses}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : (<FilterField
                key={field.name}
                {...field}
                handleFieldChange={this.handleFieldChange}
              />)
          ))}
        </div>
      </div>
    );
  }
}

PurchaseRequestListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  purchaseRequestStatuses: makeSelectPurchaseRequestStatuses(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseRequestStatuses: (payload) => dispatch(getPurchaseRequestStatuses(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PurchaseRequestListFilters);
