import React, { PureComponent } from 'react';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { toast } from 'react-toastify';
import { validNumber, currencyFormatVN } from 'utils/helper';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './MultiOrdersDeliverySentDialog.module.scss';

class MultiOrdersDeliverySentDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }
  _getOrderIds = () => {
    return this.props.selectedPackedOrders.map(item => item.id);
  }
  _getOrderCodesString = () => {
    return this.props.selectedPackedOrders.map(item => item.code).join(', ');
  }

  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await salesOrderApi.sendShipments({
      params: {
        getcare_order_ids: this._getOrderIds(),
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      toast.success(`Bạn đã gửi giao hàng cho đơn ${this._getOrderCodesString()} thành công.`);
      this.props.onSubmitSuccess(response.data);
      this.props.onClose();
    });
  };
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, selectedPackedOrders } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>Xác nhận gửi đơn giao hàng</DialogTitle>
          <DialogContent className={classes.DialogContent}>
            <p className={classes.Info}>Khi bạn xác nhận <strong>Gửi đơn giao hàng</strong> đồng nghĩa các đơn hàng này sẽ được chuyển đi giao hàng cho khách hàng. Các đơn hàng được chọn:</p>
            <div className={`${classes.List}`}>
              <div className={`${classes.Body}`}>
                <div className={`${classes.Row} ${classes.Header}`}>
                  <ListHeaderCol
                    className={`${classes.Col} TextCenter`}
                    label="STT"
                  />
                  <ListHeaderCol
                    className={`${classes.Col}`}
                    label="Mã đơn hàng"
                  />
                  <ListHeaderCol
                    className={`${classes.Col} TextRight`}
                    label="Tổng tiền"
                  />
                </div>
              
                { selectedPackedOrders.length > 0 &&
                  selectedPackedOrders.map((item, index) => (
                    <div key={`so-${item.id}`} className={`${classes.Item} ${classes.Row}`}>
                      <div className={`${classes.Col} TextCenter`}>{ index + 1 }</div>
                      <div className={classes.Col}>{ item.code }</div>
                      <div className={`${classes.Col} TextRight`}>{ validNumber(item.amount_total) ? currencyFormatVN(item.amount_total) : `-` }</div>
                    </div>
                  ))
                }
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button type="button" variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={this.handleSubmit}
              color="primary"
              autoFocus
            >
              Xác nhận
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default MultiOrdersDeliverySentDialog;
