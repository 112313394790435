import { createSelector } from 'reselect';

// authentication
const selectAuthState = (state) => {
  return state.authReducer || {};
};
const makeSelectLoginUser = () =>
  createSelector(selectAuthState, (substate) => substate.user);
const makeSelectAuthLoading = () =>
  createSelector(selectAuthState, (substate) => substate.authLoading);
const makeSelectAuthError = () =>
  createSelector(selectAuthState, (substate) => substate.authError);
const makeSelectUserTeamList = () =>
  createSelector(selectAuthState, (substate) => substate.userTeamList);

// websocket
const selectWSState = (state) => {
  return state.wsReducer || {};
};
const makeSelectGlobalWS= () =>
  createSelector(
    selectWSState,
    (substate) => substate.ws
  );
const makeSelectWSEvents= () =>
  createSelector(
    selectWSState,
    (substate) => substate.events
  );

// purchaseRequest
const selectPurchaseRequestState = (state) => {
  return state.purchaseRequestReducer || {};
};

const makeSelectPurchaseRequestStatuses = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestStatuses
  );

const makeSelectPurchaseRequestList = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestList
  );
const makeSelectPurchaseRequestListTotal = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestListTotal
  );
const makeSelectPurchaseRequestLoading = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.loading
  );

const makeSelectPurchaseRequest = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequest
  );
const makeSelectPurchaseRequestProducts = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestProducts
  );
const makeSelectPurchaseRequestBonuses = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestBonuses
  );
const makeSelectPurchaseRequestDiscounts = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestDiscounts
  );
const makeSelectPurchaseRequestNotes = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestNotes
  );
const makeSelectPurchaseRequestLogistic = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestLogistic
  );
const makeSelectPurchaseRequestSalesUser = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestSalesUser
  );
const makeSelectPurchaseRequestDelivery = () =>
  createSelector(
    selectPurchaseRequestState,
    (substate) => substate.purchaseRequestDelivery
  );

// salesOrder
const selectSalesOrderState = (state) => {
  return state.salesOrderReducer || {};
};

const makeSelectSalesOrderStatuses = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderStatuses
  );

const makeSelectSalesOrderList = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderList
  );

const makeSelectSalesOrderListTotal = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderListTotal
  );

  const makeSelectSalesOrderTransactionList = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderTransaction
  );
  const makeSelectSalesOrderTransactionListTotal = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderListTransactionTotal
  );

const makeSelectSalesOrderLoading = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.loading
  );

const makeSelectSalesOrder = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrder
  );
const makeSelectSalesOrderProducts = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderProducts
  );
const makeSelectSalesOrderBonuses = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderBonuses
  );
const makeSelectSalesOrderDiscounts = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderDiscounts
  );
const makeSelectSalesOrderNotes = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderNotes
  );
const makeSelectSalesOrderLogistic = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderLogistic
  );
const makeSelectSalesOrderSalesUser = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderSalesUser
  );
const makeSelectSalesOrderDelivery = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderDelivery
  );
const makeSelectSalesOrderHistory = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderHistory
  );

// customer
const selectCustomerState = (state) => {
  return state.customerReducer || {};
};

const makeSelectCustomerLoading = () =>
  createSelector(
    selectCustomerState,
    (substate) => substate.loading
  );
const makeSelectCustomerList = () =>
  createSelector(
    selectCustomerState,
    (substate) => substate.customerList
  );
const makeSelectCustomerListTotal = () =>
  createSelector(
    selectCustomerState,
    (substate) => substate.customerListTotal
  );

const makeSelectShipmentCustomer = () =>
  createSelector(
    selectCustomerState,
    (substate) => substate.shipmentCustomer
  );
const makeSelectShipmentCustomerOrders = () =>
  createSelector(
    selectCustomerState,
    (substate) => substate.shipmentCustomerOrders
  );

// shipment
const selectShipmentState = (state) => {
  return state.shipmentReducer || {};
};

const makeSelectShipmentStatuses = () =>
  createSelector(
    selectShipmentState,
    (substate) => substate.shipmentStatuses
  );

const makeSelectShipmentList = () =>
  createSelector(
    selectShipmentState,
    (substate) => substate.shipmentList
  );
const makeSelectShipmentListTotal = () =>
  createSelector(
    selectShipmentState,
    (substate) => substate.shipmentListTotal
  );
const makeSelectShipmentLoading = () =>
  createSelector(
    selectShipmentState,
    (substate) => substate.loading
  );

const makeSelectShipment = () =>
  createSelector(
    selectShipmentState,
    (substate) => substate.shipment
  );
const makeSelectShipmentProducts = () =>
  createSelector(
    selectShipmentState,
    (substate) => substate.shipmentProducts
  );
const makeSelectShipmentLogistic = () =>
  createSelector(
    selectShipmentState,
    (substate) => substate.shipmentLogistic
  );
const makeSelectShipmentDelivery = () =>
  createSelector(
    selectShipmentState,
    (substate) => substate.shipmentDelivery
  );

// delivery management
const selectDeliveryState = (state) => {
  return state.deliveryReducer || {};
};

const makeSelectDeliveryUserList = () =>
  createSelector(
    selectDeliveryState,
    (substate) => substate.deliveryUserList
  );
const makeSelectDeliveryUserListTotal = () =>
  createSelector(
    selectDeliveryState,
    (substate) => substate.deliveryUserListTotal
  );
const makeSelectDeliveryUserLoading = () =>
  createSelector(
    selectDeliveryState,
    (substate) => substate.deliveryUserLoading
  );

// logistic
const selectLogisticState = (state) => {
  return state.logisticReducer || {};
};

const makeSelectDeliveryLogisticList = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.deliveryLogisticList
  );
const makeSelectLogisticTotalFee = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.totalFee
  );
const makeSelectLogisticList = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.logisticList
  );

// location
const selectLocationState = (state) => {
  return state.locationReducer || {};
};

const makeSelectProvinceList = () =>
  createSelector(
    selectLocationState,
    (substate) => substate.provinceList
  );
const makeSelectDistrictList = () =>
  createSelector(
    selectLocationState,
    (substate) => substate.districtList
  );
const makeSelectWardList = () =>
  createSelector(
    selectLocationState,
    (substate) => substate.wardList
  );

// sales channel
const selectSalesChannelState = (state) => {
  return state.salesChannelReducer || {};
};

const makeSelectSalesChannelList = () =>
  createSelector(
    selectSalesChannelState,
    (substate) => substate.salesChannelList
  );
const makeSelectSalesChannelListTotal = () =>
    createSelector(
      selectSalesChannelState,
      (substate) => substate.salesChannelListTotal
    );

export {
  makeSelectLoginUser,
  makeSelectAuthLoading,
  makeSelectAuthError,
  makeSelectUserTeamList,

  makeSelectGlobalWS,
  makeSelectWSEvents,
  makeSelectPurchaseRequestStatuses,
  makeSelectPurchaseRequestList,
  makeSelectPurchaseRequestListTotal,
  makeSelectPurchaseRequestLoading,
  makeSelectPurchaseRequest,
  makeSelectPurchaseRequestProducts,
  makeSelectPurchaseRequestBonuses,
  makeSelectPurchaseRequestDiscounts,
  makeSelectPurchaseRequestNotes,
  makeSelectPurchaseRequestLogistic,
  makeSelectPurchaseRequestSalesUser,
  makeSelectPurchaseRequestDelivery,
  makeSelectDeliveryLogisticList,
  makeSelectLogisticTotalFee,
  makeSelectLogisticList,
  makeSelectSalesOrderList,
  makeSelectSalesOrderListTotal,
  makeSelectSalesOrderTransactionListTotal,
  makeSelectSalesOrderTransactionList,
  makeSelectSalesOrderLoading,
  makeSelectSalesOrderStatuses,
  makeSelectSalesOrder,
  makeSelectSalesOrderProducts,
  makeSelectSalesOrderBonuses,
  makeSelectSalesOrderDiscounts,
  makeSelectSalesOrderNotes,
  makeSelectSalesOrderLogistic,
  makeSelectSalesOrderSalesUser,
  makeSelectSalesOrderDelivery,
  makeSelectSalesOrderHistory,
  makeSelectProvinceList,
  makeSelectDistrictList,
  makeSelectWardList,
  makeSelectCustomerList,
  makeSelectCustomerListTotal,
  makeSelectCustomerLoading,
  makeSelectShipmentCustomer,
  makeSelectShipmentCustomerOrders,
  makeSelectShipmentStatuses,
  makeSelectShipmentList,
  makeSelectShipmentLoading,
  makeSelectShipmentListTotal,
  makeSelectShipment,
  makeSelectShipmentProducts,
  makeSelectShipmentLogistic,
  makeSelectShipmentDelivery,
  makeSelectDeliveryUserList,
  makeSelectDeliveryUserListTotal,
  makeSelectDeliveryUserLoading,
  makeSelectSalesChannelList,
  makeSelectSalesChannelListTotal,
};
