import shipmentApi from 'utils/api/oms/shipmentApi';
import { put } from 'redux-saga/effects';
import { shipmentActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadShipmentStatuses(payload) {
  try {
    yield put(shipmentActions.saveShipmentStatuses([]));

    const { data: response } = yield shipmentApi.getAllStatuses();

    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(
      shipmentActions.saveShipmentStatuses(response.data ? response.data : [])
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadShipmentList(payload) {
  const { params } = payload.payload;
  try {
    yield put(shipmentActions.saveLoading(true));

    const { data: response } = yield shipmentApi.getAll({ params });

    yield put(shipmentActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      shipmentActions.saveShipmentList(response.data ? response.data : [])
    );
    yield put(shipmentActions.saveShipmentListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* loadShipment(payload) {
  try {
    yield put(shipmentActions.saveLoading(true));
    yield put(shipmentActions.saveShipment(null));
    yield put(shipmentActions.saveShipmentProducts([]));
    yield put(shipmentActions.saveShipmentLogistic(null));
    yield put(shipmentActions.saveShipmentDelivery(null));
    const { data: response } = yield shipmentApi.get(payload.id);

    yield put(shipmentActions.saveLoading(false));

    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }

    yield put(
      shipmentActions.saveShipment(response.data ? response.data : null)
    );

    yield put(shipmentActions.saveShipment({
      ...response.data,
      drug_items: undefined,
      logistic_provider: undefined,
      customer_delivery: undefined,
    }));
    yield put(shipmentActions.saveShipmentProducts(response.data.drug_items || []));
    yield put(shipmentActions.saveShipmentLogistic(response.data.logistic_provider || null));
    yield put(shipmentActions.saveShipmentDelivery(response.data.customer_delivery || null));

  } catch (err) {
    console.log(err);
  }
}

export function* printShipment(payload) {
  const { params } = payload.payload;
  try {
    yield put(shipmentActions.saveLoading(true));

    const { data: response } = yield shipmentApi.exportShipment({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.setAttribute('target', `_blank`);
    anchor.click();
    anchor.remove();

    yield put(shipmentActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export function* confirmShipmentDeliverySuccess(payload) {
  const { params } = payload.payload;
  try {
    yield put(shipmentActions.saveLoading(true));

    const { data: response } = yield shipmentApi.confirmDeliverySuccess({ params });

    yield put(shipmentActions.saveLoading(false));
    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(shipmentActions.saveShipmentAllInfo(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* returnShipmentDelivery(payload) {
  const { params } = payload.payload;
  try {
    yield put(shipmentActions.saveLoading(true));

    const { data: response } = yield shipmentApi.returnDelivery({ params });

    yield put(shipmentActions.saveLoading(false));
    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(shipmentActions.saveShipmentAllInfo(response.data));
  } catch (err) {
    console.log(err);
  }
}
