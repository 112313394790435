import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectShipmentProducts } from 'redux/selectors';
import { withRouter } from 'react-router-dom';

import ShipmentProduct from './ShipmentProduct/ShipmentProduct';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './ShipmentProducts.module.scss';
import gridClasses from './ShipmentProductsGrid.module.scss';

class ShipmentProducts extends PureComponent {
  render() {
    const { shipmentProducts } = this.props;

    return (
      <div className={`${classes.Wrap}`}>
        <h4 className={classes.SectionTitle}>Thông tin sản phẩm</h4>
        <p>Nội dung đơn hàng: <strong>Thuốc</strong></p>
        <div className={`${classes.List}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            <ListHeaderCol className={`${gridClasses.Col} TextCenter`} label="STT"/>
            <ListHeaderCol className={`${gridClasses.Col} TextCenter`} label="Số lượng"/>
            <ListHeaderCol className={`${gridClasses.Col}`} label="Đơn vị"/>
          </div>
          <div className={`${classes.Body}`}>
            {shipmentProducts.length ? (
              shipmentProducts.map((item, index) => (
                <ShipmentProduct
                  key={`pr-product-${index}`}
                  {...item}
                  index={index}
                />
              ))
            ) : (
              <p className="NoData">Không có sản phẩm nào</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ShipmentProducts.propTypes = {
  shipmentProducts: PropTypes.array,
};

ShipmentProducts.defaultProps = {
  shipmentProducts: [],
};

const mapStateToProps = createStructuredSelector({
  shipmentProducts: makeSelectShipmentProducts(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(ShipmentProducts);
