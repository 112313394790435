import {
  SAVE_SALES_ORDER_STATUSES,

  SAVE_LOADING,

  SAVE_SALES_ORDER_LIST,
  SAVE_SALES_ORDER_LIST_TOTAL,

  SAVE_SALES_TRANSACTION_LIST_TOTAL,
  SAVE_SALES_TRANSACTION_LIST,

  SAVE_SALES_ORDER,
  SAVE_SALES_ORDER_PRODUCTS,
  SAVE_SALES_ORDER_PRODUCT_ITEM,
  SAVE_SALES_ORDER_BONUSES,
  SAVE_SALES_ORDER_DISCOUNTS,

  SAVE_SALES_ORDER_NOTES,
  SAVE_SALES_ORDER_NOTE_ITEM,
  REMOVE_SALES_ORDER_NOTE_ITEM,

  SAVE_SALES_ORDER_LOGISTIC,
  SAVE_SALES_ORDER_SALES_USER,
  SAVE_SALES_ORDER_DELIVERY,

  SAVE_SALES_ORDER_ALL_INFO,

  SAVE_SALES_ORDER_HISTORY,
} from 'redux/constants/oms/salesOrderConstants';

const initialState = {
  salesOrderStatuses: [],

  loading: false,

  salesOrderList: [],
  salesOrderListTotal: 0,

  salesOrder: null,
  salesOrderProducts: [],
  salesOrderBonuses: [],
  salesOrderDiscounts: [],
  salesOrderNotes: [],
  salesOrderLogistic: null,
  salesOrderSalesUser: null,
  salesOrderDelivery: null,

  salesOrderHistory: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SALES_ORDER_STATUSES: {
      return {
        ...state,
        salesOrderStatuses: action.salesOrderStatuses ? action.salesOrderStatuses : [],
      };
    }

    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_SALES_ORDER_LIST: {
      const list = action.salesOrderList;
      return {
        ...state,
        salesOrderList: list ? [...list] : [],
      };
    }

    case SAVE_SALES_TRANSACTION_LIST: {
      const list = action.salesOrderTransaction;
      return {
        ...state,
        salesOrderTransaction: list ? [...list] : [],
      };
    }

    case SAVE_SALES_TRANSACTION_LIST_TOTAL: {
      return {
        ...state,
        salesOrderListTransactionTotal: action.salesOrderListTransactionTotal,
      };
    }



    case SAVE_SALES_ORDER_LIST_TOTAL: {
      return {
        ...state,
        salesOrderListTotal: action.salesOrderListTotal,
      };
    }

    case SAVE_SALES_ORDER: {
      return {
        ...state,
        salesOrder: action.salesOrder,
      };
    }
    case SAVE_SALES_ORDER_PRODUCTS: {
      return {
        ...state,
        salesOrderProducts: action.salesOrderProducts,
      };
    }
    case SAVE_SALES_ORDER_PRODUCT_ITEM: {
      const list = [...state.salesOrderProducts];
      const comparedField = action.salesOrderProductItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex(
        (p) => p[comparedField] === action.salesOrderProductItem[comparedField]
      );
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.salesOrderProductItem };
      }
      return {
        ...state,
        salesOrderProducts: list,
      };
    }
    case SAVE_SALES_ORDER_BONUSES: {
      return {
        ...state,
        salesOrderBonuses: action.salesOrderBonuses,
      };
    }
    case SAVE_SALES_ORDER_DISCOUNTS: {
      return {
        ...state,
        salesOrderDiscounts: action.salesOrderDiscounts,
      };
    }

    case SAVE_SALES_ORDER_NOTES: {
      return {
        ...state,
        salesOrderNotes: action.salesOrderNotes,
      };
    }
    case SAVE_SALES_ORDER_NOTE_ITEM: {
      const list = [...state.salesOrderNotes];
      const comparedField = action.salesOrderNoteItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex(
        (noteItem) => noteItem[comparedField] === action.salesOrderNoteItem[comparedField]
      );
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.salesOrderNoteItem };
      } else {
        list.push({ ...action.salesOrderNoteItem });
      }
      return {
        ...state,
        salesOrderNotes: list,
      };
    }
    case REMOVE_SALES_ORDER_NOTE_ITEM: {
      const list = state.salesOrderNotes.filter(
        (noteItem) => action.salesOrderNoteItem.id !== noteItem.id
      );
      return {
        ...state,
        salesOrderNotes: list,
      };
    }

    case SAVE_SALES_ORDER_LOGISTIC: {
      return {
        ...state,
        salesOrderLogistic: action.salesOrderLogistic,
      };
    }
    case SAVE_SALES_ORDER_SALES_USER: {
      return {
        ...state,
        salesOrderSalesUser: action.salesOrderSalesUser,
      };
    }
    case SAVE_SALES_ORDER_DELIVERY: {
      return {
        ...state,
        salesOrderDelivery: action.salesOrderDelivery,
      };
    }
    case SAVE_SALES_ORDER_ALL_INFO: {
      return {
        ...state,
        salesOrder: action.salesOrderData ? {
          ...action.salesOrderData,
          getcare_order_items: undefined,
          getcare_order_bonuss: undefined,
          getcare_order_notes: undefined,
        } : null,
        salesOrderProducts: action.salesOrderData?.getcare_order_items || [],
        salesOrderBonuses: action.salesOrderData?.getcare_order_bonuss || [],
        salesOrderNotes: action.salesOrderData?.getcare_order_notes || [],
        salesOrderLogistic: action.salesOrderData?.logistic_provider || null,
        salesOrderDelivery: action.salesOrderData?.customer_delivery || null,
      };
    }

    case SAVE_SALES_ORDER_HISTORY: {
      return {
        ...state,
        salesOrderHistory: action.salesOrderHistory,
      };
    }

    default:
      return state;
  }
};

export default reducer;
