import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getShipmentList } from 'redux/actions/oms/shipmentActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectShipmentLoading,
  makeSelectShipmentList,
  makeSelectShipmentListTotal,
} from 'redux/selectors';
import {
  shipmentListParamsMap,
  shipmentListDisplayFields,
} from 'utils/constanst/omsShipmentConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';

import ShipmentList from 'components/shipment/ShipmentList/ShipmentList';
import ShipmentListFilters from 'components/shipment/ShipmentList/ShipmentListFilters/ShipmentListFilters';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './Shipments.module.scss';

class Shipments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...shipmentListParamsMap },
    };
  }
  componentDidMount() {
    this.props.getShipmentList({
      params: this.state.listParams,
    });
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['cod'].includes(item.name)) {
        const numArr = item.value.map(val => val.trim() !== '' ? Number(val) : '');
        memo[`${item.name}_from`] = numArr[numArr.length - 1] && numArr[0] ? Math.min.apply(null, [...numArr]) : numArr[0];
        memo[`${item.name}_to`] = numArr[numArr.length - 1] && numArr[0] ? Math.max.apply(null, [...numArr]) : numArr[numArr.length - 1];
      } else {
        memo[item.name] = item.value;
      }
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getShipmentList({
      params: newParams,
    });
  };

  render() {
    const { shipmentList, shipmentListTotal, loading } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách đơn giao hàng</h1>
          </div>
          <div className={classes.PageMain}>
            <ShipmentListFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <ShipmentList
              displayFields={getDisplayFields(
                listParams,
                shipmentListDisplayFields
              )}
              shipmentList={shipmentList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={shipmentListTotal}
              listName="đơn giao hàng"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  shipmentList: makeSelectShipmentList(),
  shipmentListTotal: makeSelectShipmentListTotal(),
  loading: makeSelectShipmentLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getShipmentList: (payload) => dispatch(getShipmentList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Shipments);
