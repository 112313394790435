import React from 'react';
import { useTable, usePagination, useFilters, useSortBy, useGlobalFilter, useAsyncDebounce} from 'react-table';
import { isFunction } from 'lodash';
import classNames from 'classnames';

import { 
  withStyles,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TablePagination,
  CircularProgress,
  TableSortLabel,
} from '@material-ui/core';
import { 
  Pagination 
} from '@material-ui/lab';

import styles from './dataTableStyles';

const defaultPropGetter = () => ({})

const StyledProgress = withStyles(styles)(({ classes }) => <div className={classes.styledProgressRoot}>
  <div className={classes.styledProgressIcon}>
    <CircularProgress
      variant="determinate"
      className={classes.styledProgressCircleBottom}
      size={40}
      thickness={4}
      value={100}
    />
    <CircularProgress
      variant="indeterminate"
      disableShrink
      className={classes.styledProgressCircleTop}
      classes={{
        circle: classes.styledProgressCircle,
      }}
      size={40}
      thickness={4}
    />
  </div>
  <p className={classes.styledProgressContent}>Đang tải...</p>
</div>)

const StyledDataTable = (props) => {
  // const [controlledPageIndex, setControlledPage] = React.useState(0)
  // const [statePageSize, setStatePageSize] = React.useState(null)

  const { 
    name,
    classes,
    columns,
    data,
    onFetchData,
    loading,
    initialState,
    manualPagination = false,
    manualGlobalFilter = false,
    manualSortBy = false,

    pageCount: controlledPageCount,
    totalCount = 0,
    pageOptions,
    autoResetPage = false,
    autoResetSortBy = false,
    defaultCanSort = false,
    disableSortBy = false,

    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
    getHeaderGroupProps = defaultPropGetter,

    componentBeforeTable,
    tableProps = {},
    tableWrapper = () => <React.Fragment/>
  } = props;
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    // pageCount,
    state: { 
      pageIndex, 
      pageSize,
      globalFilter,
      sortBy,
    },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
    useControlledState: (state) => {
      return React.useMemo(
        () => ({
          ...state,
        }),
        [state]
      );
    },
    initialState: { 
      pageIndex: 0,
      ...initialState,
    }, 
    manualPagination,
    autoResetPage,
    autoResetSortBy,
    defaultCanSort,
    disableSortBy,
    manualSortBy,
    manualGlobalFilter,
    pageCount: controlledPageCount,
  },useGlobalFilter,useFilters,useSortBy,usePagination)

  const onFetchDataDebounced = useAsyncDebounce(onFetchData,500);

  React.useEffect(() => {
    gotoPage(0)
  }, [pageSize, sortBy, globalFilter])
  React.useEffect(() => {
    onFetchData && onFetchDataDebounced({ 
      pageIndex, 
      pageSize,
      filter: globalFilter,
      sortBy,
    })
  }, [onFetchDataDebounced, pageIndex, pageSize, sortBy, globalFilter])

  return (<>
    {
      componentBeforeTable && (
        isFunction(componentBeforeTable) ? componentBeforeTable({preGlobalFilteredRows,globalFilter,setGlobalFilter}) : componentBeforeTable
      )
    }
    {
      <tableWrapper.component { ...tableWrapper.props }>
        <Table {...getTableProps()} {...tableProps}>
          <TableHead>
            {headerGroups.map( headerGroup => {
              return (
                <TableRow 
                  { ...(isFunction(getHeaderGroupProps) ? getHeaderGroupProps(headerGroup) : getHeaderGroupProps) } 
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map(column => {
                    const headerCellProps = isFunction(column.headerCellProps) ? column.headerCellProps(column) : column.headerCellProps;
                    const headerCellStyles = {
                      ...(isFunction(column.style) ? column.style(column) : column.style),
                      ...(isFunction(column.headerCellStyle) ? column.headerCellStyle(column) : column.headerCellStyle),
                    }
                    const columnProps = isFunction(column.columnProps) ? column.columnProps(column) : column.columnProps;
                    const headerCellClassName = `${(isFunction(column.className) ? column.className(column) : column.className) || ''} 
                                        ${(isFunction(column.headerCellClassName) ? column.headerCellClassName(column) : column.headerCellClassName) || ''}`;
                    const sortByToggleProps = column.getSortByToggleProps();
                    
                    return (
                      <TableCell 
                        {...column.getHeaderProps([
                          getColumnProps(column),
                          getHeaderProps(column),
                          {
                            className: headerCellClassName,
                            style: {
                              ...headerCellStyles,
                            },
                            ...columnProps,
                            ...headerCellProps,
                          },
                        ])}
                      >
                        {
                          !column.disableSortBy && column.canSort ? 
                          <TableSortLabel
                            active={column.isSorted}
                            hideSortIcon
                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                            onClick={sortByToggleProps.onClick}
                          >
                            { column.render('Header') }
                          </TableSortLabel>
                          : column.render('Header')
                        }
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
              })
            }
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {
              loading ? <TableRow>
                <TableCell colSpan={9999}>
                  <StyledProgress/>
                </TableCell>
              </TableRow> : (
                page.length > 0 ?
                page.map((row, ri) => {
                  prepareRow(row)
                  return (
                    <TableRow 
                      key={`row_${ri}`} 
                      { ...(isFunction(getRowProps) ? getRowProps(row) : getRowProps) } 
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell,ci) => {
                        const cellProps = isFunction(cell.column.cellProps) ? cell.column.cellProps(cell) : cell.column.cellProps;
                        const columnProps = isFunction(cell.column.columnProps) ? cell.column.columnProps(cell.column) : cell.column.columnProps;
                        const cellStyles = {
                          ...(isFunction(cell.column.style) ? cell.column.style(cell) : cell.column.style),
                          ...(isFunction(cell.column.cellStyle) ? cell.column.cellStyle(cell) : cell.column.cellStyle),
                        }
                        const cellClassName = `${(isFunction(cell.column.className) ? cell.column.className(cell) : cell.column.className) || ''} 
                                            ${(isFunction(cell.column.cellClassName) ? cell.column.cellClassName(cell) : cell.column.cellClassName) || ''}`;
                        return (
                          <TableCell 
                            key={`row_${ri}_cell_${ci}`}
                            {...cell.getCellProps([
                              getColumnProps(cell),
                              getCellProps(cell),
                              {
                                className: cellClassName,
                                style: {...cellStyles},
                                ...columnProps,
                                ...cellProps,
                              },
                            ])}
                          >
                            {cell.render('Cell',{ key: `row_${ri}_cell_${ci}`})}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                }) : <TableRow>
                  <TableCell align="center" colSpan={999}>
                    Không tìm thấy dữ liệu
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </tableWrapper.component>
    }
    <TablePagination
      component="div"
      classes={{
        toolbar: classes.tablePaginationToolbar,
        // root: classes.tablePaginationRoot,
        // spacer: classes.tablePaginationSpacer,
        // input: classes.tablePaginationInput,
      }}
      count={manualPagination ? totalCount : data.length}
      rowsPerPageOptions={pageOptions}
      page={pageIndex}
      rowsPerPage={pageSize}
      onPageChange={(newPage) => {
        // alert(newPage)
        gotoPage(newPage)
      }}
      onRowsPerPageChange={(e) => setPageSize(e.target.value)}
      ActionsComponent={(tablePaginationProps) => {
        // console.log("tablePaginationProps",tablePaginationProps)
        return <Pagination
          classes={{
            ul: classes.paginationUl
          }}
          showLastButton
          showFirstButton
          page={tablePaginationProps.page + 1}
          count={Math.ceil(tablePaginationProps.count/tablePaginationProps.rowsPerPage)} 
          color="primary" 
          siblingCount={2}
          onChange={(e,newPage) => tablePaginationProps.onPageChange(newPage - 1)}
      />}}
      labelDisplayedRows={(tablePaginationProps) => {
        // console.log("tablePaginationProps",tablePaginationProps)
        return <>{tablePaginationProps.count} kết quả ({tablePaginationProps.from} - {tablePaginationProps.to})</>
      }}
      labelRowsPerPage="Hiển thị"
    />
  </>)
}
export default withStyles(styles)(React.memo(StyledDataTable));