import axiosAuth from 'utils/axios/axiosAuth';

export default {
  getList: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`/ecom/service_request/connect_support/support`, {
      params: queryParams,
      cancelToken,
    });
  },
  get: ({ params, cancelToken }) => {
    return axiosAuth.get(`/ecom/service_request/connect_support/support/${params.project_id}`, {
      cancelToken,
    });
  }
};
