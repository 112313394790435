import {
  SAVE_GLOBAL_WS,
  SAVE_WS_EVENT,
  REMOVE_WS_EVENT,
  REMOVE_WS_EVENT_ITEMS,
} from 'redux/constants/wsConstants';

const initialState = {
  ws: undefined,
  events: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_GLOBAL_WS: {
      return {
        ...state,
        ws: action.ws,
      };
    }
    case SAVE_WS_EVENT: {
      const list = [ ...state.events ];
      const result = { ...action.event };
      const eventIndex = list.findIndex(msg => {
        return msg.data?.id === result.data?.id && msg.event === result.event
      });
      if (eventIndex > -1) {
        list[eventIndex] = result;
      } else {
        list.push(result);
      }
      return {
        ...state,
        events: list,
      };
    }
    case REMOVE_WS_EVENT: {
      const list = [ ...state.events ];
      const eventWillRemove = { ...action.event };
      const eventIndex = list.findIndex(msg => {
        return msg.data?.id === eventWillRemove.data?.id && msg.event === eventWillRemove.event
      });
      if (eventIndex > -1) {
        list.splice(eventIndex, 1);
      }
      return {
        ...state,
        events: list,
      };
    }
    case REMOVE_WS_EVENT_ITEMS: {
      const items = [ ...action.items ];
      const list = state.events.filter(
        evt => !items.some(item => item.data?.id === evt.data?.id && item.event === evt.event )
      );
      return {
        ...state,
        events: list,
      };
    }
    default:
      return state;
  }
};

export default reducer;
