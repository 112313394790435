import {
  GET_PURCHASE_REQUEST_STATUSES,
  GET_PURCHASE_REQUEST_LIST,
  GET_PURCHASE_REQUEST,

  SAVE_PURCHASE_REQUEST_STATUSES,

  SAVE_LOADING,

  SAVE_PURCHASE_REQUEST_LIST,
  SAVE_PURCHASE_REQUEST_LIST_TOTAL,

  SAVE_PURCHASE_REQUEST,
  SAVE_PURCHASE_REQUEST_PRODUCTS,

  SAVE_PURCHASE_REQUEST_BONUSES,
  SAVE_PURCHASE_REQUEST_DISCOUNTS,

  SAVE_PURCHASE_REQUEST_NOTES,
  SAVE_PURCHASE_REQUEST_LOGISTIC,
  SAVE_PURCHASE_REQUEST_SALES_USER,
  SAVE_PURCHASE_REQUEST_DELIVERY,
} from 'redux/constants/oms/purchaseRequestConstants';

export function getPurchaseRequestStatuses(payload) {
  return {
    type: GET_PURCHASE_REQUEST_STATUSES,
    payload,
  };
}

export function getPurchaseRequestList(payload) {
  return {
    type: GET_PURCHASE_REQUEST_LIST,
    payload,
  };
}
export function getPurchaseRequest(id) {
  return {
    type: GET_PURCHASE_REQUEST,
    id,
  };
}

// mutation
export function savePurchaseRequestStatuses(purchaseRequestStatuses) {
  return {
    type: SAVE_PURCHASE_REQUEST_STATUSES,
    purchaseRequestStatuses,
  };
}

export function savePurchaseRequestList(purchaseRequestList) {
  return {
    type: SAVE_PURCHASE_REQUEST_LIST,
    purchaseRequestList,
  };
}
export function savePurchaseRequestListTotal(purchaseRequestListTotal) {
  return {
    type: SAVE_PURCHASE_REQUEST_LIST_TOTAL,
    purchaseRequestListTotal,
  };
}
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}

export function savePurchaseRequest(purchaseRequest) {
  return {
    type: SAVE_PURCHASE_REQUEST,
    purchaseRequest,
  };
}
export function savePurchaseRequestProducts(purchaseRequestProducts) {
  return {
    type: SAVE_PURCHASE_REQUEST_PRODUCTS,
    purchaseRequestProducts,
  };
}
export function savePurchaseRequestBonuses(purchaseRequestBonuses) {
  return {
    type: SAVE_PURCHASE_REQUEST_BONUSES,
    purchaseRequestBonuses,
  };
}
export function savePurchaseRequestDiscounts(purchaseRequestDiscounts) {
  return {
    type: SAVE_PURCHASE_REQUEST_DISCOUNTS,
    purchaseRequestDiscounts,
  };
}
export function savePurchaseRequestNotes(purchaseRequestNotes) {
  return {
    type: SAVE_PURCHASE_REQUEST_NOTES,
    purchaseRequestNotes,
  };
}
export function savePurchaseRequestLogistic(purchaseRequestLogistic) {
  return {
    type: SAVE_PURCHASE_REQUEST_LOGISTIC,
    purchaseRequestLogistic,
  };
}
export function savePurchaseRequestSalesUser(purchaseRequestSalesUser) {
  return {
    type: SAVE_PURCHASE_REQUEST_SALES_USER,
    purchaseRequestSalesUser,
  };
}
export function savePurchaseRequestDelivery(purchaseRequestDelivery) {
  return {
    type: SAVE_PURCHASE_REQUEST_DELIVERY,
    purchaseRequestDelivery,
  };
}
