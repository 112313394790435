import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { validDate, currencyFormatVN, getFullAddressStr, validNumber } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { get3PLStatusClass, IN_PROGRESS_PACKING, PACKED } from 'utils/constanst/omsShipmentConstants';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton'
import ArchiveIcon from '@material-ui/icons/Archive';
import PackingFormDialog from 'components/so/AwaitingDeliveryOrderList/PackingFormDialog/PackingFormDialog';

import gridClasses from '../AwaitingDeliveryOrderListGrid.module.scss';
import classes from './AwaitingDeliveryOrderListItem.module.scss';

class AwaitingDeliveryOrderListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isPackingFormDialogOpen: false,
    }
  }
  goToDetails = (e) => {
    this.props.history.push(`/so/${this.props.code}`);
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  };
  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      order: { ...this.props },
      isSelected: !this.props.isSelected,
    });
  };
  handleOpenPackingFormDialog = (e) => {
    e.stopPropagation();
    this.setState({
      isPackingFormDialogOpen: true,
    });
  }
  handleClosePackingFormDialog = () => {
    this.setState({
      isPackingFormDialogOpen: false,
    });
  }

  render() {
    const {
      pr_code,
      code,
      customer_name,
      customer_phone,
      customer_delivery,
      logistic_provider,
      created_at,
      sales_name,
      sales_phone,
      amount_total,
      packing_status,
      isSelected,
    } = this.props;
    const { isPackingFormDialogOpen } = this.state;

    return <>
      <div className={`${classes.Item} ${gridClasses.Row}`}>
        <div className={gridClasses.Col}>
          { [PACKED].includes(packing_status?.id)
            ? <Checkbox
              size="small"
              className={classes.Checkbox}
              checked={isSelected}
              onClick={this.stopPropagation}
              onChange={this.handleCheckboxChange}
            /> : <IconButton
              color="primary"
              size="small"
              className={classes.Checkbox}
              onClick={this.handleOpenPackingFormDialog}
            ><ArchiveIcon fontSize="small"/></IconButton>
          }

        </div>
        <div className={gridClasses.Col}><a className="TextSecondary" href={`/pr/${pr_code}`} target="_blank" rel="noopener noreferrer" >{ pr_code }</a></div>
        <div className={gridClasses.Col}><a className="TextSecondary" href={`/so/${code}`} target="_blank" rel="noopener noreferrer" >{ code }</a></div>
        <div className={gridClasses.Col}>
          <p>{ customer_name }</p>
          { customer_phone && <p className={classes.CustomerCode}>{ customer_phone }</p> }
        </div>
        <div className={`${gridClasses.Col}`}>
          { getFullAddressStr(customer_delivery?.address, customer_delivery?.getcare_ward, customer_delivery?.getcare_district, customer_delivery?.getcare_country) }
        </div>
        <div className={gridClasses.Col}>
          <p>{ logistic_provider?.name }</p>
          { !['', null, undefined].includes(logistic_provider?.total_fee) &&
            <p className={classes.CustomerCode}>Vận phí: { currencyFormatVN(logistic_provider?.total_fee) }</p>
          }
        </div>
        <div className={gridClasses.Col}>{ validDate(created_at) ? format(validDate(created_at), dateTimeFormat) : '' }</div>
        <div className={gridClasses.Col}>
          <p>{ sales_name }</p>
          { sales_phone && <p>{ sales_phone }</p> }
        </div>
        <div className={`${gridClasses.Col}`}>
          { validNumber(amount_total) ? currencyFormatVN(amount_total) : '' }
        </div>
        <div className={`${gridClasses.Col} TextCenter`}>
          { packing_status && <>
              <span className={get3PLStatusClass(packing_status?.id)}>
                {packing_status?.name}
              </span>
              { [IN_PROGRESS_PACKING].includes(packing_status?.id)
                ? <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<ArchiveIcon fontSize="small"/>}
                    onClick={this.handleOpenPackingFormDialog}
                  >
                    Đóng hàng
                  </Button>
                : ''
              }
            </>
          }
        </div>
        <div className={`${gridClasses.Col} ${classes.LatestNoteCol}`}>{ logistic_provider?.note ? `"${logistic_provider.note}"` : '' }</div>
      </div>
      { isPackingFormDialogOpen &&
        <PackingFormDialog
          isOpen={isPackingFormDialogOpen}
          salesOrderData={{...this.props}}
          onClose={this.handleClosePackingFormDialog}
          onSubmitSuccess={this.props.onSubmitDeliverySuccess}
        />
      }
    </>
  }
}

AwaitingDeliveryOrderListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  isSelected: PropTypes.bool,
  onItemSelectedToggle: PropTypes.func,
  onSubmitDeliverySuccess: PropTypes.func,
};

AwaitingDeliveryOrderListItem.defaultProps = {
  isSelected: false,
};

export default withRouter(AwaitingDeliveryOrderListItem);
