import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeliveryView from 'components/so/SalesOrderDeliveryInfo/DeliveryView/DeliveryView';
import DeliverySentForm from 'components/so/SalesOrderDeliverySentDialog/DeliverySentForm/DeliverySentForm';

import classes from './ShipmentCustomerOrdersDelivery.module.scss';

class ShipmentCustomerOrdersDelivery extends PureComponent {
  _getOrdersTotalAmountGoods = () => {
    return this.props.selectedOrders.reduce((memo, order) => {
      return memo += Number((order?.amount || 0) + (order?.amount_vat || 0) - (order?.amount_discount || 0) - (order?.amount_paid || 0));
    }, 0);
  }
  _getOrdersTotalAmountFee = () => {
    return this.props.selectedOrders.reduce((memo, order) => {
      return memo += Number((order?.amount_delivery || 0) - (order?.amount_shipping_coupon || 0));
    }, 0);
  }

  render() {
    const { customerDeliveryOptions, selectedOrders } = this.props;
    const amountGoods = this._getOrdersTotalAmountGoods();
    const amountFee = this._getOrdersTotalAmountFee();

    return (
      <div className={`${classes.Wrap}`}>
        <h4 className={classes.SectionTitle}>Thông tin vận chuyển</h4>
        <div className={classes.SectionContent}>
          <div className={classes.SectionContentSide}>
            <div className={classes.Title}>
              Chọn thông tin nhận hàng
            </div>
            <div className={classes.Content}>
              <Formik
                innerRef={this.props.customerDeliveryFormRef}
                initialValues={{
                  customer_delivery_hash: '',
                }}
                onSubmit={this.props.onSubmitCustomerDelivery}
                validationSchema={Yup.object().shape({
                  customer_delivery_hash: Yup.string().required("Vui lòng chọn thông tin vận chuyển"),
                })}
              >
                {(props) => {
                  const { values, errors, handleChange } = props;

                  return (
                    <form noValidate autoComplete="off">
                      { errors && errors.customer_delivery_hash && <p className="TextPrimary">{errors.customer_delivery_hash}</p>}
                      <RadioGroup name="customer_delivery_hash" value={values.customer_delivery_hash} onChange={handleChange}>
                        { customerDeliveryOptions.map(customerDelivery =>
                          <FormControlLabel
                            className={classes.DeliveryItem}
                            key={customerDelivery.hash}
                            value={customerDelivery.hash}
                            control={<Radio size="small" style={{ padding: '4px', marginRight: '12px' }}/>}
                            label={<DeliveryView
                              salesOrderDelivery={{...customerDelivery}}
                              willShowVATInfo={false}
                            />}
                          />
                        )}
                      </RadioGroup>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <div className={classes.SectionContentMain}>
            <div className={classes.Title}>Chọn đơn vị vận chuyển</div>
            <div className={classes.Content}>
              <DeliverySentForm
                formRef={this.props.logisticProviderFormRef}
                isShipmentCombined
                selectedOrders={[...selectedOrders]}
                amountGoods={amountGoods}
                deliveryFeeTotal={amountFee}
                customerCode={this.props.shipmentCustomer?.getcare_pharmacy?.code}
                customerDelivery={{...this.props.latestOrder?.customer_delivery}}
                salesOrderLogistic={{...this.props.latestOrder?.logistic_provider}}
                onSubmitDelivery={this.props.onSubmitLogisticProvider}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ShipmentCustomerOrdersDelivery.propTypes = {
  customerDeliveryOptions: PropTypes.array,
  latestOrder: PropTypes.object,
  shipmentCustomer: PropTypes.object,
  selectedOrders: PropTypes.array,
};

ShipmentCustomerOrdersDelivery.defaultProps = {
  customerDeliveryOptions: [],
  latestOrder: null,
  shipmentCustomer: null,
  selectedOrders: [],
};

export default ShipmentCustomerOrdersDelivery;
