import React, { PureComponent } from 'react';

import classes from './ShipmentProduct.module.scss';
import gridClasses from '../ShipmentProductsGrid.module.scss';

class ShipmentProduct extends PureComponent {
  render() {
    const {
      index,
      quantity,
      uom,
    } = this.props;

    return (<>
      <div className={`${gridClasses.Row} ${classes.Item}`}>
        <div className={`${gridClasses.Col} TextCenter`}>
          {index + 1}
        </div>
        <div className={`${gridClasses.Col} TextCenter`}>
          {quantity}
        </div>
        <div className={`${gridClasses.Col}`}>
          {uom}
        </div>
      </div>
    </>);
  }
}

export default ShipmentProduct;
