import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  isThirdPartyLogisticProvider,
  isChanhXeLogisticProvider,
  getAvailableLogisticProviders,
} from 'utils/constanst/common';
import { currencyFormatVN } from 'utils/helper';

import DialogContent from '@material-ui/core/DialogContent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import classes from './LogisticProviderSelectionForm.module.scss';

class LogisticProviderSelectionForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedProviderId: props.salesOrderLogistic?.id,
    }
  }

  _getSelectedProvider = (providerId) => {
    const { deliveryLogisticList } = this.props;
    return deliveryLogisticList.find(item => item.id === providerId);
  }
  _getSelectedService = (providerId, serviceId) => {
    const selectedProvider = this._getSelectedProvider(providerId);
    return selectedProvider?.services ? selectedProvider.services.find(item => item.service_id === serviceId) : null;
  }

  handleSubmit = (values) => {
    this.props.onSubmitEdit({
      id: values.providerId,
      name: this._getSelectedProvider(values.providerId)?.name || '',
      service_id: isThirdPartyLogisticProvider(values.providerId) ? values.serviceId : 0,
      service_name: this._getSelectedService(values.providerId, values.serviceId)?.service_name || '',
      note: isChanhXeLogisticProvider(values.providerId) ? values.note : undefined,
    });
  }

  render() {
    const { deliveryLogisticList, salesOrderLogistic } = this.props;
    const availableProviders = getAvailableLogisticProviders(deliveryLogisticList, salesOrderLogistic?.cod);

    return (
      <Formik
        innerRef={this.props.formRef}
        initialValues={{
          providerId: salesOrderLogistic?.id,
          serviceId: salesOrderLogistic?.service_id,
        }}
        onSubmit={this.handleSubmit}
        validateOnChange={false}
        validateOnBlur={true}
        validationSchema={Yup.object().shape({
          providerId: Yup.string().required("Vui lòng chọn dịch vụ vận chuyển"),
          serviceId: isThirdPartyLogisticProvider(this.state.selectedProviderId) ? Yup.string().required("Vui lòng chọn loại dịch vụ") : undefined,
          note: isChanhXeLogisticProvider(this.state.selectedProviderId) ? Yup.string().required("Vui lòng nhập ghi chú") : undefined,
        })}
      >
        {(props) => {
          const {
            values,
            errors,
            handleChange,
            setFieldValue,
          } = props;
          return (
            <form noValidate autoComplete="off" className={classes.Dialog}>
              <DialogContent className={classes.DialogContent}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="logistic provider"
                    name="providerId"
                    value={values.providerId}
                    className={classes.RadioGroup}
                    onChange={(e) => {
                      setFieldValue('providerId', parseFloat(e.target.value));
                      this.setState({ selectedProviderId: parseFloat(e.target.value) });
                    }}
                  >
                    { availableProviders && availableProviders.map(provider => (<div key={`provider-${provider.id}`}>
                      <FormControlLabel
                        value={provider.id}
                        control={<Radio size="small" style={{padding: '4px 0', marginRight: '12px'}} />}
                        fontSize="small"
                        className={classes.FormControlLabel}
                        label={`${provider.name}${!isThirdPartyLogisticProvider(provider.id) ? ` (Phí vận chuyển sẽ do Phahub chỉ định sau)` : ''}`}
                      />
                      { isChanhXeLogisticProvider(values.providerId) && (values.providerId === provider.id)
                        && <TextField
                          className={classes.FormControlInput}
                          autoComplete="off"
                          rowsMax={3}
                          multiline
                          value={values.note}
                          error={!!errors.note}
                          helperText={errors.note}
                          placeholder="Nhập ghi chú giao hàng"
                          name="note"
                          onChange={handleChange}
                        />
                      }
                      { isThirdPartyLogisticProvider(values.providerId)
                        && (values.providerId === provider.id)
                        && provider.services
                        && (<FormControl component="fieldset">
                          <RadioGroup
                            aria-label="logistic provider service"
                            name="serviceId"
                            value={values.serviceId}
                            className={classes.RadioGroup}
                            defaultValue={provider.services[0].service_id}
                            onChange={(e) => {setFieldValue('serviceId', parseFloat(e.target.value))}}
                          >
                            { provider.services.map(service => (
                              <FormControlLabel
                                key={`provider-service-${service.service_id}`}
                                value={service.service_id}
                                control={<Radio size="small" color="primary" style={{padding: '0', marginRight: '12px'}} />}
                                fontSize="small"
                                className={classes.FormControlLabel}
                                label={ `${service.service_name} ${![null, undefined, '', 0].includes(service.fee?.total) ? '(' + currencyFormatVN(service.fee?.total) + ')' : ``}` }
                              />
                            )) }
                          </RadioGroup>
                          { errors.serviceId ? errors.serviceId : '' }
                        </FormControl>
                      )}
                    </div>)) }
                  </RadioGroup>
                  { errors.providerId ? errors.providerId : '' }
                </FormControl>
              </DialogContent>
            </form>
          );
        }}
      </Formik>
    );
  }
}

LogisticProviderSelectionForm.propTypes = {
  deliveryLogisticList: PropTypes.array,
  salesOrderLogistic: PropTypes.object,
  onSubmitEdit: PropTypes.func,
};

LogisticProviderSelectionForm.defaultProps = {
  deliveryLogisticList: [],
  salesOrderLogistic: null,
};

export default LogisticProviderSelectionForm;
