import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectShipment } from 'redux/selectors';

import Panel from 'components/Panel/Panel';

import classes from './ShipmentSalesInfo.module.scss';

class ShipmentSalesInfo extends PureComponent {

  render() {
    const { shipment } = this.props;
    const salesUser = shipment?.getcare_sales && shipment?.getcare_sales.length > 0 ? shipment?.getcare_sales[0] : null;

    return (<>
      { salesUser && <Panel
          title="Phụ trách bởi"
          size="sm"
          panelClassName={classes.Panel}
          content={<div className={classes.Wrap}>
            { shipment?.getcare_sales?.map((salesUser, index) => (
              <div key={`sales-info-${index}`} className={classes.InfoWrap}>
                <div className={classes.Info}>
                  <label>Trình Dược Viên</label>
                  {salesUser?.name || `-`}
                </div>
                <div className={classes.Info}>
                  <label>Số điện thoại</label>
                  {salesUser?.phone || `-`}
                </div>
              </div>)
            )}
          </div>}
          isBorder
        />
      }
    </>)
  }
}

ShipmentSalesInfo.propTypes = {
  shipment: PropTypes.object,
};
ShipmentSalesInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  shipment: makeSelectShipment(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(ShipmentSalesInfo);
