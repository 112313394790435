import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import shipmentApi from 'utils/api/oms/shipmentApi';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import classes from './ShipmentCancelFormDialog.module.scss';

class ShipmentCancelFormDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }

  _getOrderCodesLength = () => {
    const { shipmentOrderCodes } = this.props;
    if (!shipmentOrderCodes) return 0;
    return shipmentOrderCodes.split(',').length;
  }
  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await shipmentApi.cancelShipment({
      params: {
        id: this.props.shipmentId,
        cancel_reason: values.reason,
        delete: Number(values.willCancelPacking) === 1,
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      toast.success(`Bạn đã huỷ đơn giao hàng ${this.props.shipmentCode} thành công.`);
      this.props.onCancelSuccess(response.data);
      this.props.onClose();
    });
  };
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;
    const { isLoading } = this.state;
    const willForceCancelPacking = this._getOrderCodesLength() > 1;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>Xác nhận huỷ đơn giao hàng</DialogTitle>
          <Formik
            initialValues={{
              reason: '',
              willCancelPacking: willForceCancelPacking ? "1" : "0",
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validationSchema={Yup.object().shape({
              reason: Yup.string().required("Vui lòng nhập lý do").test('len', 'Tối đa 255 ký tự', val => val?.length <= 255),
              willCancelPacking: Yup.string().required("Vui lòng chọn"),
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <form noValidate autoComplete="off">
                  <DialogContent className={classes.DialogContent}>
                    <p className={classes.Info}>Khi bạn xác nhận huỷ giao hàng đồng nghĩa đơn giao hàng trên <strong>hệ thống của 3PL cũng được huỷ</strong>.</p>
                    <div className={classes.FieldControl}>
                      <label>
                        Để xác nhận, bạn phải nhập lý do ở đây: <span className={classes.RequiredMark}>*</span>
                      </label>
                      <TextField
                        className={classes.Field}
                        autoComplete="off"
                        rowsMax={3}
                        multiline
                        value={values.reason}
                        placeholder="Nhập lý do huỷ giao hàng ở đây"
                        name="reason"
                        error={!!errors.reason}
                        helperText={errors.reason}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>
                        Bạn muốn <strong>huỷ đóng hàng</strong> luôn không?
                      </label>
                      { errors.willCancelPacking && <p className="TextDanger">{ errors.willCancelPacking }</p> }
                      <RadioGroup
                        aria-label="willCancelPacking"
                        name="willCancelPacking"
                        value={values.willCancelPacking}
                        className={`${classes.Field} ${classes.RadioGroup}`}
                        onChange={(e) => {
                          setFieldValue('willCancelPacking', e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio size="small" style={{padding: '4px 0', marginRight: '12px'}} />}
                          fontSize="small"
                          className={classes.FormControlLabel}
                          label="Có"
                        />
                        { !willForceCancelPacking &&
                          <FormControlLabel
                            value="0"
                            control={<Radio size="small" style={{padding: '4px 0', marginRight: '12px'}} />}
                            fontSize="small"
                            className={classes.FormControlLabel}
                            label="Không"
                          />
                        }
                      </RadioGroup>
                    </div>
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      autoFocus
                    >
                      Xác nhận
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

export default ShipmentCancelFormDialog;
