import React, { Component } from 'react';
import { Switch } from "react-router-dom";
import RouteWithSubRoutes from 'components/RouteWithSubRoutes';
import PageHeader from 'components/PageHeader/PageHeader';
import PermissionDenied from 'views/PermissionDenied/PermissionDenied';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { checkAuth } from 'redux/actions/authActions';
import { createStructuredSelector } from 'reselect';
import { makeSelectLoginUser } from 'redux/selectors';
import { ToastContainer } from 'react-toastify';
import { getAvailableRoutes } from 'utils/helper/authorization';

import { DEFAULT_PAGE } from 'utils/constanst/common';

import 'react-toastify/dist/ReactToastify.css';
import classes from './LayoutAdmin.module.scss';

class LayoutAdmin extends Component {
  componentDidMount() {
    this.props.checkAuth();
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user && user !== prevProps.user && !user.id) {

      // check auth failed
      window.location.href = `${process.env.REACT_APP_PATH_SSO}?continue=${process.env.REACT_APP_PATH_OMS}`;
    }
    if (prevProps.user && prevProps.user.id && (!user || !user.id)) {

      // logout success
      window.location.href = `${process.env.REACT_APP_PATH_SSO}?continue=${process.env.REACT_APP_PATH_OMS}`;
    }
    if (prevProps.user && prevProps.user.id && user && user.id && (prevProps.user.id !== user.id)) {

      // the second user logout success
      window.location.href = DEFAULT_PAGE;
    }
  }

  render() {
    const { routes, user } = this.props;
    const hasAuth = user && user.id;
    const availableRoutes = getAvailableRoutes(user, routes);

    return (<>
      { hasAuth &&
        <div className={classes.Wrap}>
          <PageHeader availableRoutes={[...availableRoutes]}/>
          { availableRoutes && user.token &&
            <Switch>
              {availableRoutes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          }
          { !user.token && <PermissionDenied/> }
        </div>
      }
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick
      />
    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    checkAuth: () => dispatch(checkAuth()),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LayoutAdmin);
