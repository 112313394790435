import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classes from './CustomerDetails.module.scss';

class CustomerDetails extends PureComponent {
  render() {
    const { shipmentCustomer } = this.props;

    return (
      <div className={classes.Wrap}>
        <div className={classes.ColInner}>
          <div className={`${classes.Info}`}>
            <label>Mã khách hàng</label>
            <span>{ shipmentCustomer?.getcare_pharmacy?.code || `-` }</span>
          </div>
          <div className={`${classes.Info} ${classes.CodeInfo}`}>
            <label>Tên khách hàng</label>
            <span>{ shipmentCustomer?.getcare_pharmacy?.name || `-` }</span>
          </div>
          <div className={`${classes.Info} ${classes.CodeInfo}`}>
            <label>Mã số thuế</label>
            <span>{ shipmentCustomer?.getcare_pharmacy?.tax_code || `-` }</span>
          </div>
          <div className={`${classes.Info} ${classes.CodeInfo}`}>
            <label>Tên người đại diện</label>
            <span>{ shipmentCustomer?.getcare_pharmacy?.representative_name || `-` }</span>
          </div>
        </div>
        <div className={classes.ColInner}>
          <div className={classes.Info}>
            <label>Địa chỉ</label>
            <span>{ shipmentCustomer?.getcare_pharmacy?.address || `-` }</span>
          </div>
          <div className={classes.Info}>
            <label>Tỉnh/TP</label>
            <span>{ shipmentCustomer?.getcare_pharmacy?.getcare_province?.name || `-` }</span>
          </div>
          <div className={classes.Info}>
            <label>Quận/Huyện</label>
            <span>{ shipmentCustomer?.getcare_pharmacy?.getcare_district?.name || `-` }</span>
          </div>
          <div className={classes.Info}>
            <label>Phường/Xã</label>
            <span>{ shipmentCustomer?.getcare_pharmacy?.getcare_ward?.name || `-` }</span>
          </div>
        </div>
      </div>
    );
  }
}

CustomerDetails.propTypes = {
  shipmentCustomer: PropTypes.object,
};

CustomerDetails.defaultProps = {
  shipmentCustomer: null,
};

export default CustomerDetails;
