import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { shipmentFilterFields } from 'utils/constanst/omsShipmentConstants';
import { getShipmentStatuses } from 'redux/actions/oms/shipmentActions';
import { getLogisticList } from 'redux/actions/logisticActions';
import { createStructuredSelector } from 'reselect';
import { makeSelectShipmentStatuses, makeSelectLogisticList } from 'redux/selectors';

import FilterField from 'components/FilterField/FilterField';
import PriceRange from 'components/FilterField/PriceRange/PriceRange';
import MultiSelect from 'components/FilterField/MultiSelect';

import classes from './ShipmentListFilters.module.scss';

class ShipmentListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...shipmentFilterFields],
    };
  }
  componentDidMount() {
    this.props.getShipmentStatuses();
    this.props.getLogisticList({ params: {} });
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            ['cod'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<PriceRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['getcare_delivery_status_id'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                customField={<MultiSelect
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  options={this.props.shipmentStatuses}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['logistic_provider_id'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                customField={<MultiSelect
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  options={this.props.logisticList}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : (<FilterField
                key={field.name}
                {...field}
                handleFieldChange={this.handleFieldChange}
              />)
          ))}
        </div>
      </div>
    );
  }
}

ShipmentListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  shipmentStatuses: makeSelectShipmentStatuses(),
  logisticList: makeSelectLogisticList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getShipmentStatuses: (payload) => dispatch(getShipmentStatuses(payload)),
    getLogisticList: (payload) => dispatch(getLogisticList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ShipmentListFilters);
