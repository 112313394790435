import {
  GET_DELIVERY_LOGISTIC_LIST,
  SAVE_DELIVERY_LOGISTIC_LIST,
  GET_LOGISTIC_LIST,
  SAVE_LOGISTIC_LIST,
} from 'redux/constants/logisticConstants';

export function getDeliveryLogisticList(payload) {
  return {
    type: GET_DELIVERY_LOGISTIC_LIST,
    payload
  };
}
export function getLogisticList(payload) {
  return {
    type: GET_LOGISTIC_LIST,
    payload
  };
}

// mutation
export function saveDeliveryLogisticList(deliveryLogisticList) {
  return {
    type: SAVE_DELIVERY_LOGISTIC_LIST,
    deliveryLogisticList
  };
}
export function saveLogisticList(logisticList) {
  return {
    type: SAVE_LOGISTIC_LIST,
    logisticList
  };
}
