export const CHECK_AUTH = 'getcare/sso/CHECK_AUTH';
export const SIGN_OUT = 'getcare/sso/SIGN_OUT';
export const GET_USER_TEAM_LIST = 'getcare/sso/GET_USER_TEAM_LIST';

// mutation
export const SAVE_AUTH_LOADING = 'getcare/sso/SAVE_AUTH_LOADING';
export const SAVE_AUTH_ERROR = 'getcare/sso/SAVE_AUTH_ERROR';
export const SAVE_AUTH = 'getcare/sso/SAVE_AUTH';
export const REMOVE_AUTH = 'getcare/sso/REMOVE_AUTH';
export const SAVE_USER_TEAM_LIST = 'getcare/sso/SAVE_USER_TEAM_LIST';
