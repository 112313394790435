import {
  SAVE_CUSTOMER_LIST,
  SAVE_LOADING,
  SAVE_CUSTOMER_LIST_TOTAL,
  SAVE_SHIPMENT_CUSTOMER_INFO,
  SAVE_SHIPMENT_CUSTOMER_ORDERS,
  SAVE_SHIPMENT_CUSTOMER_ORDER_ITEM,
} from 'redux/constants/oms/customerConstants';

const initialState = {
  loading: false,

  customerList: [],
  customerListTotal: 0,

  shipmentCustomer: null,
  shipmentCustomerOrders: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_CUSTOMER_LIST: {
      const list = action.customerList;
      return {
        ...state,
        customerList: list ? [...list] : [],
      };
    }
    case SAVE_CUSTOMER_LIST_TOTAL: {
      return {
        ...state,
        customerListTotal: action.customerListTotal,
      };
    }

    case SAVE_SHIPMENT_CUSTOMER_INFO: {
      return {
        ...state,
        shipmentCustomer: { ...action.shipmentCustomer },
      };
    }
    case SAVE_SHIPMENT_CUSTOMER_ORDERS: {
      return {
        ...state,
        shipmentCustomerOrders: [...action.shipmentCustomerOrders],
      };
    }
    case SAVE_SHIPMENT_CUSTOMER_ORDER_ITEM: {
      return {
        ...state,
        shipmentCustomerOrderItem: { ...action.shipmentCustomerOrderItem },
      };
    }

    default:
      return state;
  }
};

export default reducer;
