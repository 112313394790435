import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { VN_ID } from 'utils/constanst/locationConstants';
import { debounce, isEqual } from 'lodash';
import locationApi from 'utils/api/locationApi';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import classes from '../DeliverySentForm.module.scss';

class FromAddress extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      provinces: [],
      districts: [],
      wards: [],
      isLoadingProvinces: false,
      isLoadingDistricts: false,
      isLoadingWards: false,
      selectedProvince: null,
      selectedDistrict: null,
      selectedWard: null,
    };
  }
  componentDidMount() {
    this._loadProvinces({ name: '', getcare_country_id: VN_ID });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedProvince, selectedDistrict } = this.state;
    if (selectedProvince && !isEqual(selectedProvince,prevState.selectedProvince)) {
      this._loadDistricts({ name: '', getcare_province_id: selectedProvince?.id });
    }
    if (selectedDistrict && !isEqual(selectedDistrict,prevState.selectedDistrict)) {
      this._loadWards({ name: '', getcare_district_id: selectedDistrict?.id });
    }
  };
  _loadProvinces = debounce(async ({ name, getcare_country_id }) => {
    this.setState({ isLoadingProvinces: true });
    const { data: response } = await locationApi.getProvinces({ name, getcare_country_id });
    if (!response?.result) return;
    this.setState({ provinces: [...response.data], isLoadingProvinces: false });
  }, 500);
  _loadDistricts = debounce(async ({ name, getcare_province_id }) => {
    const { setFieldValue } = this.props;
    this.setState({ isLoadingDistricts: true });
    const { data: response } = await locationApi.getDistricts({ name, getcare_province_id });
    if (!response?.result) return;
    this.setState({ districts: [...response.data] });
    const selectedItem = response.data.find(
      (item) => item.id === this.state.selectedDistrict?.id
    );
    if (!selectedItem) {
      this.setState({ selectedDistrict: null, selectedWard: null, isLoadingDistricts: false });
      setFieldValue('from_address_getcare_district_id','');
      setFieldValue('from_address_getcare_ward_id','');
    }
  }, 500);
  _loadWards = debounce(async ({ name, getcare_district_id }) => {
    const { setFieldValue } = this.props;
    this.setState({ isLoadingWards: true });
    const { data: response } = await locationApi.getWards({ name, getcare_district_id });
    if (!response?.result) return;
    this.setState({ wards: [...response.data], isLoadingWards: false });
    const selectedItem = response.data.find(
      (item) => item.id === this.state.selectedWard?.id
    );
    if (!selectedItem) {
      this.setState({ selectedWard: null });
      setFieldValue('from_address_getcare_ward_id','');
    }
  }, 500);

  render() {
    const { 
      values, 
      errors, 
      handleChange, 
      setFieldValue, 
    } = this.props;
    const {
      provinces,
      districts,
      wards,
      selectedProvince,
      selectedDistrict,
      selectedWard,
      isLoadingProvinces,
      isLoadingDistricts,
      isLoadingWards,
    } = this.state;

    return (<>
      <div className={classes.FieldControl}>
        <FormControlLabel
          style={{margin: 0}}
          className={classes.FormControlLabel}
          control={
            <Checkbox
              size="small"
              checked={values.open_from_address}
              name="open_from_address"
              label="ASD"
              style={{padding: '4px 0', marginRight: '12px'}}
              onChange={(e) => {
                setFieldValue('open_from_address',e.target.checked);
              }}
            />
          }
          label="Đổi địa chỉ lấy hàng"
        />
      </div>
      {
        values.open_from_address &&
        <>
          <div className={`${classes.FieldGroup}`}>
            <div className={classes.FieldControl}>
              <label>
                Tỉnh/TP <span className={classes.RequiredMark}>*</span> 
              </label>
              <Autocomplete
                disableClearable
                name="getcare_province_id"
                handleHomeEndKeys={false}
                value={selectedProvince || null}
                options={isLoadingProvinces ? [] : provinces}
                getOptionLabel={(option) => option?.name || ''}
                getOptionSelected={(option, value) => option.id === selectedProvince?.id}
                loading={isLoadingProvinces}
                loadingText="Đang tải..."
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    placeholder="- Chọn -"
                    {...params}
                    error={!!errors.from_address_getcare_province_id}
                    helperText={errors.from_address_getcare_province_id}
                  />
                )}
                onChange={(e, newValue) => {
                  this.setState({
                    selectedProvince: newValue
                  })
                  setFieldValue('from_address_getcare_province_id', newValue?.id || '');
                }}
              />
            </div>
            <div className={classes.FieldControl}>
              <label>
                Quận/Huyện <span className={classes.RequiredMark}>*</span> 
              </label>
              <Autocomplete
                disableClearable
                name="getcare_district_id"
                handleHomeEndKeys={false}
                value={selectedDistrict || null}
                options={isLoadingDistricts || !selectedProvince ? [] : districts}
                getOptionLabel={(option) => option?.name || ''}
                getOptionSelected={(option, value) => option.id === selectedDistrict?.id}
                loading={isLoadingDistricts || !selectedProvince}
                loadingText={isLoadingDistricts ? 'Đang tải...' : 'Vui lòng chọn Tỉnh/TP'}
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    placeholder="- Chọn -"
                    {...params}
                    error={!!errors.from_address_getcare_district_id}
                    helperText={errors.from_address_getcare_district_id}
                  />
                )}
                onChange={(e, newValue) => {
                  this.setState({
                    selectedDistrict: newValue
                  })
                  setFieldValue('from_address_getcare_district_id', newValue?.id || '');
                }}
              />
            </div>
          </div>
          <div className={classes.FieldControl}>
            <label>
              Phường/Xã <span className={classes.RequiredMark}>*</span> 
            </label>
            <Autocomplete
              disableClearable
              name="getcare_ward_id"
              handleHomeEndKeys={false}
              value={selectedWard || null}
              options={isLoadingWards || !selectedDistrict ? [] : wards}
              getOptionLabel={(option) => option?.name || ''}
              getOptionSelected={(option, value) => option.id === selectedWard?.id}
              loading={isLoadingWards || !selectedDistrict}
              loadingText={isLoadingWards ? 'Đang tải...' : 'Vui lòng chọn Quận/Huyện'}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  placeholder="- Chọn -"
                  {...params}
                  error={!!errors.from_address_getcare_ward_id}
                  helperText={errors.from_address_getcare_ward_id}
                />
              )}
              onChange={(e, newValue) => {
                this.setState({
                  selectedWard: newValue
                })
                setFieldValue('from_address_getcare_ward_id', newValue?.id || '');
              }}
            />
          </div>
          <div className={classes.FieldControl}>
            <label>
              Địa chỉ <span className={classes.RequiredMark}>*</span> 
            </label>
            <TextField
              autoComplete="off"
              value={values.from_address_address}
              name="from_address_address"
              error={!!errors.from_address_address}
              placeholder="Nhập chi tiết địa chỉ"
              onChange={handleChange}
            />
          </div>
        </>
      }
    </>)
  }
}

FromAddress.propTypes = {
  values: PropTypes.object, 
  errors: PropTypes.object, 
  handleChange: PropTypes.func, 
  setFieldValue: PropTypes.func, 
};

FromAddress.defaultProps = {};

export default FromAddress;
