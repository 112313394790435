import {
  GET_DELIVERY_USER_LIST,
  SAVE_DELIVERY_USER_LOADING,
  SAVE_DELIVERY_USER_LIST,
  SAVE_DELIVERY_USER_LIST_TOTAL,
} from 'redux/constants/oms/deliveryConstans';

export function getDeliveryUserList(payload) {
  return {
    type: GET_DELIVERY_USER_LIST,
    payload,
  };
}

// mutation
export function saveDeliveryUserList(deliveryUserList) {
  return {
    type: SAVE_DELIVERY_USER_LIST,
    deliveryUserList,
  };
}
export function saveDeliveryUserListTotal(deliveryUserListTotal) {
  return {
    type: SAVE_DELIVERY_USER_LIST_TOTAL,
    deliveryUserListTotal,
  };
}

export function saveDeliveryUserLoading(deliveryUserLoading) {
  return {
    type: SAVE_DELIVERY_USER_LOADING,
    deliveryUserLoading,
  };
}
