export const GET_PURCHASE_REQUEST_STATUSES = 'getcare/oms/pr/GET_PURCHASE_REQUEST_STATUSES';

export const GET_PURCHASE_REQUEST_LIST = 'getcare/oms/pr/GET_PURCHASE_REQUEST_LIST';
export const GET_PURCHASE_REQUEST = 'getcare/oms/pr/GET_PURCHASE_REQUEST';

// mutation
export const SAVE_PURCHASE_REQUEST_STATUSES = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_STATUSES';

export const SAVE_LOADING = 'getcare/oms/pr/SAVE_LOADING';

export const SAVE_PURCHASE_REQUEST_LIST = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_LIST';
export const SAVE_PURCHASE_REQUEST_LIST_TOTAL = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_LIST_TOTAL';

export const SAVE_PURCHASE_REQUEST = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST';
export const SAVE_PURCHASE_REQUEST_PRODUCTS = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_PRODUCTS';

export const SAVE_PURCHASE_REQUEST_BONUSES = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_BONUSES';
export const SAVE_PURCHASE_REQUEST_DISCOUNTS = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_DISCOUNTS';

export const SAVE_PURCHASE_REQUEST_NOTES = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_NOTES';
export const SAVE_PURCHASE_REQUEST_LOGISTIC = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_LOGISTIC';
export const SAVE_PURCHASE_REQUEST_SALES_USER = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_SALES_USER';
export const SAVE_PURCHASE_REQUEST_DELIVERY = 'getcare/oms/pr/SAVE_PURCHASE_REQUEST_DELIVERY';
