import {
  GET_SHIPMENT_STATUSES,
  GET_SHIPMENT_LIST,
  GET_SHIPMENT,
  SAVE_LOADING,
  SAVE_SHIPMENT_LIST,
  SAVE_SHIPMENT_LIST_TOTAL,
  SAVE_SHIPMENT_STATUSES,

  SAVE_SHIPMENT,
  SAVE_SHIPMENT_PRODUCTS,

  SAVE_SHIPMENT_LOGISTIC,
  SAVE_SHIPMENT_DELIVERY,

  SAVE_SHIPMENT_ALL_INFO,

  PRINT_SHIPMENT,
  CONFIRM_SHIPMENT_SUCCESS_DELIVERY,
  RETURN_SHIPMENT_DELIVERY,
} from 'redux/constants/oms/shipmentConstants';

export function getShipmentStatuses(payload) {
  return {
    type: GET_SHIPMENT_STATUSES,
    payload,
  };
}

export function getShipmentList(payload) {
  return {
    type: GET_SHIPMENT_LIST,
    payload,
  };
}
export function getShipment(id) {
  return {
    type: GET_SHIPMENT,
    id,
  };
}

export function confirmSuccessDelivery(payload) {
  return {
    type: CONFIRM_SHIPMENT_SUCCESS_DELIVERY,
    payload,
  };
}
export function returnShipmentDelivery(payload) {
  return {
    type: RETURN_SHIPMENT_DELIVERY,
    payload,
  };
}

export function printShipment(payload) {
  return {
    type: PRINT_SHIPMENT,
    payload,
  };
}

// mutation
export function saveShipmentStatuses(shipmentStatuses) {
  return {
    type: SAVE_SHIPMENT_STATUSES,
    shipmentStatuses,
  };
}

export function saveShipmentList(shipmentList) {
  return {
    type: SAVE_SHIPMENT_LIST,
    shipmentList,
  };
}
export function saveShipmentListTotal(shipmentListTotal) {
  return {
    type: SAVE_SHIPMENT_LIST_TOTAL,
    shipmentListTotal,
  };
}

export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}

export function saveShipment(shipment) {
  return {
    type: SAVE_SHIPMENT,
    shipment,
  };
}
export function saveShipmentProducts(shipmentProducts) {
  return {
    type: SAVE_SHIPMENT_PRODUCTS,
    shipmentProducts,
  };
}

export function saveShipmentLogistic(shipmentLogistic) {
  return {
    type: SAVE_SHIPMENT_LOGISTIC,
    shipmentLogistic,
  };
}
export function saveShipmentDelivery(shipmentDelivery) {
  return {
    type: SAVE_SHIPMENT_DELIVERY,
    shipmentDelivery,
  };
}
export function saveShipmentAllInfo(shipmentData) {
  return {
    type: SAVE_SHIPMENT_ALL_INFO,
    shipmentData,
  };
}
