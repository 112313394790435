import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import {
  
} from 'redux/selectors';
import { } from 'utils/helper';
import { withRouter } from 'react-router-dom';

import serviceRequestAssignApi from 'utils/api/ecom/serviceRequest/serviceRequestAssign/serviceRequestAssignApi'

import {
  Box,
  Button,
} from '@material-ui/core';
import MainPage from 'components/Styled/MainPage/MainPage';
import ProjectList from './ProjectList/ProjectList'
import ProjectDetails from './ProjectDetails/ProjectDetails'
import DonateProductList from './DonateProductList/DonateProductList'

import styles from './CharityProject.module.scss';

class CharityProject extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedProjectId: null,
      selectedProjectServiceRequestItemId: null,
      supportProductList: [],
      assigningServiceRequest: false,
    };
    this.ProjectDetailsEl = React.createRef();
  }
  handleGetFirstData = (project) => {
    if ( !this.state.selectedProjectId ) {
      this.setState({ selectedProjectId: project.id })
    }
  }
  handleSelectProject = (projectId) => {
    this.setState({ 
      selectedProjectId: projectId, 
      selectedProjectServiceRequestItemId: null, 
      supportProductList: [] 
    })
  }
  handleSelectServiceRequestItemId = (itemId) => {
    this.setState({ 
      selectedProjectServiceRequestItemId: itemId, 
      supportProductList: [] 
    })
  }
  handleChangeSupportProductList = (supportProductList) => {
    this.setState({
      supportProductList: supportProductList.map(item => ({
        donate_id: item.donate_id,
        item_id: this.state.selectedProjectServiceRequestItemId,
        quantity_number: item._quantity_number
      }))
    })
  }
  handleAssign = async () => {
    this.setState({ assigningServiceRequest: true });
    const { data: response } = await serviceRequestAssignApi.assign({ params: {
      project_request_id: this.state.selectedProjectId,
      items: [...this.state.supportProductList],
    }})
    if ( !response?.result ) {
      toast.error(response?.message)
      return
    }
    toast.success("Xác nhận thành công")
    this.setState({ 
      assigningServiceRequest: false,
      selectedProjectServiceRequestItemId: null,
    });
    this.ProjectDetailsEl.current.fetchProjectDetails();
  }

  render() {
    const {  } = this.props;

    return (
      <React.Fragment>
        <MainPage
          title="Phân bổ theo dự án"
        >
          <ProjectList
            className="margin__bottom"
            selectedProjectId={this.state.selectedProjectId}
            onGetFirstData={this.handleGetFirstData}
            onSelectProject={this.handleSelectProject}
          />
          {
            this.state.selectedProjectId &&
            <ProjectDetails
              ref={this.ProjectDetailsEl}
              className="margin__bottom--2"
              selectedProjectId={this.state.selectedProjectId}
              selectedProjectServiceRequestItemId={this.state.selectedProjectServiceRequestItemId}
              onSelectServiceRequestItemId={this.handleSelectServiceRequestItemId}
            />
          }
          {
            this.state.selectedProjectServiceRequestItemId &&
            <DonateProductList
              className="margin__bottom--2"
              selectedProjectId={this.state.selectedProjectId}
              selectedProjectServiceRequestItemId={this.state.selectedProjectServiceRequestItemId}
              onChangeSupportProductList={this.handleChangeSupportProductList}
            />
          }
          {
            this.state.supportProductList.length > 0 &&
            <Box display="flex" justifyContent="center">
              <Button variant="contained" color="primary" onClick={this.handleAssign}>
                Xác nhận
              </Button>
            </Box>
          }
        </MainPage>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({

});
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(CharityProject);
