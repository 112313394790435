import axios from 'axios';
import { allocateRoute } from 'utils/helper';

const commonAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

commonAxios.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

commonAxios.interceptors.response.use(
  (res) => {
    if (!['', null, undefined].includes(res?.data?.error_code)) {
      allocateRoute(res.data.error_code);
    }
    return res;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);

export default commonAxios;
