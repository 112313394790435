import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

const StyledPaper = withStyles(theme => ({
  root: {
    boxShadow: theme.shadows[3]
  }
}))(Paper);

const styles = (theme) => {
  return {
    root: {
      
    }
  }
};

export default withStyles(styles)(
  React.forwardRef( ({ classes, innerRef, color, ...other }, ref) =>
    <TableContainer component={StyledPaper} ref={ref} classes={classes} {...other} />
  )
);