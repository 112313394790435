import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SalesOrderListItem from './SalesOrderListItem/SalesOrderListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './SalesOrderListGrid.module.scss';
import classes from './SalesOrderList.module.scss';


class SalesOrderList extends PureComponent {

  render() {
    const { salesOrderTransaction, isLoading, displayFields } = this.props;

    return (
     
    <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
       {
        salesOrderTransaction !== '' ?
        <>
        <div className={`$${classes.Header}`}>
      <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item,index) => (
            <ListHeaderCol
              key={item.name}
              index={index}
              className={`${gridClasses.Col} ${item.className}`}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
         </div>
   <div className={`${classes.Body}`}>
        { !isLoading  && salesOrderTransaction !== ''
          ? salesOrderTransaction.map((item,index) => (
            <SalesOrderListItem
              key={`SalesOrder-${item.id}`}
              index={index}
              {...item}
            />))
          : (<p className="NoData">Không có đơn hàng nào</p>)
        }
      </div>
        </>
        :null
        }
  
    </div>
    
    )
  }
}

SalesOrderList.propTypes = {
  displayFields: PropTypes.array,
  salesOrderList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

SalesOrderList.defaultProps = {
  displayFields: [],
  salesOrderList: [],
  isLoading: false,
};
export default SalesOrderList;
