import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import salesOrderApi from 'utils/api/oms/salesOrderApi';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import classes from './SalesOrderCancelFormDialog.module.scss';

class SalesOrderCancelFormDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedReason: null,
    }
    this.reasonOptions = [
      {
        id: 1,
        name: 'Khách hàng yêu cầu huỷ',
      },
      {
        id: 2,
        name: 'Thông tin giao hàng không chính xác',
      },
      {
        id: 3,
        name: 'Khác',
      },
    ]
  }

  _getSelectedReason = (id) => {
    return this.reasonOptions.find(item => item.id === id);
  }

  handleSubmit = async (values) => {
    const selectedReason = this._getSelectedReason(parseFloat(values.reasonId));
    this.setState({
      isLoading: true,
    });
    const { data: response } = await salesOrderApi.cancelSalesOrder({
      params: {
        id: this.props.salesOrderId,
        reason: selectedReason?.id !== 3 ? selectedReason.name : values.otherReason,
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      this.props.onCancelSuccess(response.data);
      this.props.onClose();
    });
  };
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;
    const { isLoading, selectedReason } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>Huỷ đơn hàng</DialogTitle>
          <Formik
            initialValues={{
              reasonId: '',
              otherReason: '',
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validateOnChange={false}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
              reasonId: Yup.string().required("Vui lòng chọn lý do"),
              otherReason: selectedReason?.id === 3
                ? Yup.string().required("Vui lòng nhập lý do khác")
                  .test('len', 'Tối đa 255 ký tự', val => val?.length <= 255)
                : undefined,
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <form noValidate autoComplete="off">
                  <DialogContent className={classes.DialogContent}>
                    <div className={classes.FieldControl}>
                      <label>
                        Chọn lý do <span className={classes.RequiredMark}>*</span>
                      </label>
                      <Autocomplete
                        selectOnFocus
                        name="reasonId"
                        handleHomeEndKeys={false}
                        value={this._getSelectedReason(values.reasonId) || null}
                        options={this.reasonOptions}
                        getOptionLabel={(option) => option?.name || ''}
                        filterOptions={(x) => x}
                        renderOption={(option) => option?.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            autoFocus
                            placeholder="Chọn..."
                            {...params}
                            error={!!errors.reasonId}
                            helperText={errors.reasonId}
                          />
                        )}
                        onChange={(e, newValue) => {
                          setFieldValue('reasonId', newValue?.id || '');
                          this.setState({
                            selectedReason: newValue,
                          });
                        }}
                      />
                    </div>
                    { selectedReason?.id === 3 &&
                      <div className={`${classes.FieldControl} ${classes.OneColumn}`}>
                        <TextField
                          autoComplete="off"
                          rowsMax={3}
                          multiline
                          value={values.otherReason}
                          placeholder="Nhập lý do khác"
                          name="otherReason"
                          error={!!errors.otherReason}
                          helperText={errors.otherReason}
                          onChange={handleChange}
                        />
                      </div>
                    }
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      autoFocus
                    >
                      Xác nhận
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

export default SalesOrderCancelFormDialog;
