import React, { PureComponent } from 'react';
import { currencyFormatVN } from 'utils/helper';

import classes from '../PurchaseRequestProduct/PurchaseRequestProduct.module.scss';
import gridClasses from '../PurchaseRequestProductsGrid.module.scss';

class PurchaseRequestDiscount extends PureComponent {
  render() {
    const { amount, promotion_code, promotion_name } = this.props;

    return (<div className={`${gridClasses.Row} ${gridClasses.DiscountRow} ${classes.Item} ${classes.DiscountItem}`}>
      <div className={`${gridClasses.Col}`}></div>
      <div className={gridClasses.GroupCol}>
        <div className={`${gridClasses.Col}`}>{`Chương trình khuyến mãi ${promotion_name ? promotion_name + `- ` : ``}${promotion_code}`}</div>
        <div className={`${gridClasses.Col} TextRight`}>{ ['', null, undefined].includes(amount) ? '' : `(${currencyFormatVN(amount)})` }</div>
      </div>
    </div>);
  }
}

export default PurchaseRequestDiscount;
