import authReducer from './authReducer';
import wsReducer from './wsReducer';
import { combineReducers } from 'redux';
import salesChannelReducer from './mdm/salesChannelReducer';
import purchaseRequestReducer from './oms/purchaseRequestReducer';
import salesOrderReducer from './oms/salesOrderReducer';
import shipmentReducer from './oms/shipmentReducer';
import customerReducer from './oms/customerReducer';
import deliveryReducer from './oms/deliveryReducer';
import logisticReducer from './logisticReducer';
import locationReducer from './locationReducer';

export default combineReducers({
  authReducer,
  wsReducer,
  purchaseRequestReducer,
  salesOrderReducer,
  shipmentReducer,
  logisticReducer,
  locationReducer,
  customerReducer,
  deliveryReducer,
  salesChannelReducer,
});
