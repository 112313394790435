import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { validNumber, currencyFormatVN, getFullAddressStr } from 'utils/helper';
import { getShipmentStatusClass } from 'utils/constanst/omsShipmentConstants';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import gridClasses from '../ShipmentListGrid.module.scss';
import classes from './ShipmentListItem.module.scss';

class ShipmentListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSOFull: false,
    }
    this.maxSOLine = 3;
  }

  _getSOList = () => {
    const { getcare_order_codes } = this.props;
    return getcare_order_codes ? getcare_order_codes.split(',') : [];
  }
  _getSOShortList = () => {
    const fullList = this._getSOList();
    return fullList.length > this.maxSOLine ? fullList.slice(0, this.maxSOLine) : [...fullList];
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  };
  handleOrderCodeClick = (e) => {
    const { tracking_url } = this.props;
    if (!tracking_url) return;
    window.open(tracking_url, '_blank');
    e.preventDefault();
  }
  handleToggleLessMore = (e) => {
    this.setState({
      isSOFull: !this.state.isSOFull,
    });
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const {
      index,
      code,
      order_code,
      getcare_logistic_provider,
      getcare_delivery_status,
      getcare_customer,
      to_address,
      to_ward,
      cod,
      fee,
    } = this.props;
    const { isSOFull } = this.state;
    const fullSOList = this._getSOList();

    return <Link
      to={`/shipment/${code}`}
      className={`${classes.Item} ${gridClasses.Row}`}
    >
      <div className={`${gridClasses.Col} TextCenter`}>{ index + 1 }</div>
      <div className={gridClasses.Col}>{ code }</div>
      <div className={gridClasses.Col}>
        { isSOFull && <>
            { this._getSOList().map((item, idx) => <p key={item}>
              {item}
              { fullSOList.length > this.maxSOLine && idx === this._getSOList().length - 1 &&
                <IconButton
                  color="primary"
                  size="small"
                  style={{padding: 0}}
                  type="button"
                  onClick={this.handleToggleLessMore}><ArrowDropUpIcon/></IconButton>
              }
            </p>) }
          </>
        }
        { !isSOFull && <>
            { this._getSOShortList().map((item, idx) => <p key={item}>
              {item}
              { fullSOList.length > this.maxSOLine && idx === this._getSOShortList().length - 1 &&
                <IconButton
                  color="primary"
                  size="small"
                  style={{padding: 0}}
                  type="button"
                  onClick={this.handleToggleLessMore}><ArrowDropDownIcon/></IconButton>
              }
            </p>) }
          </>
        }
      </div>
      <div className={`${gridClasses.Col} TextCenter`}>
        { order_code &&
          <Button color="primary" style={{padding: 0}} onClick={this.handleOrderCodeClick}>{ order_code }</Button>
        }
      </div>
      <div className={gridClasses.Col}>
        { getcare_delivery_status &&
          <span className={getShipmentStatusClass(getcare_delivery_status.id)}>
            {getcare_delivery_status?.name}
          </span>
        }
      </div>
      <div className={gridClasses.Col}>{ getcare_logistic_provider?.name }</div>
      <div className={gridClasses.Col}><p>{ getcare_customer?.name }</p><p className={classes.CustomerCode}>{ getcare_customer?.phone }</p></div>
      <div className={`${gridClasses.Col}`}>
        { getFullAddressStr(
            to_address,
            { name: to_ward?.name },
            { name: to_ward?.getcare_district?.name },
            { name: to_ward?.getcare_district?.getcare_province?.name }
          )
        }
      </div>
      <div className={`${gridClasses.Col} TextRight`}>
        { validNumber(cod) ? currencyFormatVN(cod) : `-` }
      </div>
      <div className={`${gridClasses.Col} TextRight`}>
        { validNumber(fee) ? currencyFormatVN(fee) : `-` }
      </div>
    </Link>;
  }
}

ShipmentListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

ShipmentListItem.defaultProps = {
};

export default withRouter(ShipmentListItem);
