import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import classes from '../SalesOrderDeliveryInfo.module.scss';

class DeliveryView extends PureComponent {
  render() {
    const { salesOrderDelivery, salesOrder, willShowVATInfo } = this.props;
    const hasTaxCode = !!salesOrder?.is_taxcode;

    return (<div className={classes.Wrap}>
      { salesOrderDelivery && (
        <>
          { (salesOrderDelivery.name || salesOrderDelivery.phone) &&
            <div className={classes.Info}>
              <label>{`${salesOrderDelivery.name}${salesOrderDelivery.phone ? ' | ' + salesOrderDelivery.phone : ''}`}</label>
            </div>
          }
          <div className={classes.Info}>
            <p>{salesOrderDelivery.label}</p>
            <p>{salesOrderDelivery.address}</p>
            <p>{salesOrderDelivery.getcare_ward?.name}</p>
            <p>{salesOrderDelivery.getcare_district?.name}</p>
            <p>{salesOrderDelivery.getcare_province?.name}</p>
            <p>{`ĐT: ${salesOrderDelivery.phone}`}</p>
          </div>
          { willShowVATInfo &&
            <div className={`${classes.Info} ${classes.VATOption}`}>
              { hasTaxCode ? <CheckBoxIcon fontSize="small" color="secondary"/> : <CheckBoxOutlineBlankIcon fontSize="small" color="secondary"/> }
              <p className={classes.VATInfo}>
                <span>Xuất hoá đơn GTGT</span>
                <span className={classes.TaxInfo}>
                  {hasTaxCode && salesOrder?.tax_name ? <>Tên công ty: <strong>{ salesOrder?.tax_name }</strong></> : ``}
                </span>
                <span className={classes.TaxInfo}>
                  {hasTaxCode && salesOrder?.taxcode ? <>Mã số thuế: <strong>{ salesOrder?.taxcode }</strong></> : ``}
                </span>
                <span className={classes.TaxInfo}>
                  {hasTaxCode && salesOrder?.tax_address ? <>Địa chỉ: <strong>{ salesOrder?.tax_address }</strong></> : ``}
                </span>
              </p>
            </div>
          }
        </>)
      }
    </div>)
  }
}

DeliveryView.propTypes = {
  salesOrderDelivery: PropTypes.object,
  salesOrder: PropTypes.object,
  willShowVATInfo: PropTypes.bool,
};
DeliveryView.defaultProps = {
  salesOrderDelivery: null,
  salesOrder: null,
  willShowVATInfo: true,
};

export default DeliveryView;
