import salesChannelApi from 'utils/api/mdm/salesChannelApi';
import { put } from 'redux-saga/effects';
import { salesChannelActions } from 'redux/actions';

export function* loadSalesChannelList(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesChannelActions.saveSalesChannelList(null));

    const { data: response } = yield salesChannelApi.getAll({ params });

    if (!response?.result) {
      yield put(salesChannelActions.saveSalesChannelList([]));
      yield put(salesChannelActions.saveSalesChannelListTotal(0));
      return;
    }
    yield put(salesChannelActions.saveSalesChannelList(response.data ? [...response.data] : []));
    yield put(salesChannelActions.saveSalesChannelListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
