import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getPurchaseRequest } from 'redux/actions/oms/purchaseRequestActions';
import { createStructuredSelector } from "reselect";
import { makeSelectPurchaseRequest } from 'redux/selectors';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Tooltip from '@material-ui/core/Tooltip';
import PurchaseRequestInfo from 'components/pr/PurchaseRequestInfo/PurchaseRequestInfo';
import PurchaseRequestProducts from 'components/pr/PurchaseRequestProducts/PurchaseRequestProducts';
import PurchaseRequestBonuses from 'components/pr/PurchaseRequestBonuses/PurchaseRequestBonuses';
import PurchaseRequestLogisticProvider from 'components/pr/PurchaseRequestLogisticProvider/PurchaseRequestLogisticProvider';
import PurchaseRequestNotes from 'components/pr/PurchaseRequestNotes/PurchaseRequestNotes';
import PurchaseRequestSalesInfo from 'components/pr/PurchaseRequestSalesInfo/PurchaseRequestSalesInfo';
import PurchaseRequestDeliveryInfo from 'components/pr/PurchaseRequestDeliveryInfo/PurchaseRequestDeliveryInfo';
import PurchaseRequestAmountInfo from 'components/pr/PurchaseRequestAmountInfo/PurchaseRequestAmountInfo';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';

import classes from './PurchaseRequest.module.scss';

class PurchaseRequest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nextPurchaseRequestId: null,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
  }
  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id && id !== prevProps.match.params.id) {
      this._loadData(id);
    }
  }

  _loadData = (id) => {
    this.props.getPurchaseRequest(id);
  }
  _backToList = () => {
    this.props.history.push('/pr');
  }
  _goToNextPurchaseRequest = () => {
    this.props.history.push(`/pr/${this.state.nextPurchaseRequestId}`);
  }
  _goToAfterItem = () => {
    this.props.history.push(`/pr/${this.props.purchaseRequest?.next_code}`);
  }
  _goToBeforeItem = () => {
    this.props.history.push(`/pr/${this.props.purchaseRequest?.prev_code}`);
  }

  render() {
    const { purchaseRequest } = this.props;

    return (
      <div className={classes.PageWrap}>
        <div className={classes.PageHeader}>
          <IconButton disabled={!purchaseRequest?.prev_code} size="small" onClick={this._goToBeforeItem}>
            <ArrowBackIosOutlined fontSize="small"/>
          </IconButton>
          <IconButton disabled={!purchaseRequest?.next_code} size="small" onClick={this._goToAfterItem}>
            <ArrowForwardIosOutlined fontSize="small"/>
          </IconButton>
          <h1 className={classes.PageTitle}>
            Chi tiết đơn đặt hàng
            { purchaseRequest?.is_taxcode && (
              <Tooltip title={`Xuất hoá đơn GTGT`} arrow placement="top">
                <LocalOfferIcon className={classes.InvoiceIcon} color="secondary"/>
              </Tooltip>
            ) }
          </h1>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={this._backToList}
          >Trở về</Button>
        </div>
        <div className={classes.PageMain}>
          <div className={classes.PurchaseRequestInfo}>
            <PurchaseRequestInfo/>
          </div>
          <div className={classes.PurchaseRequestContent}>
            <PurchaseRequestProducts/>
            <PurchaseRequestBonuses/>
            <PurchaseRequestLogisticProvider/>
            <PurchaseRequestNotes/>
          </div>
          <div className={classes.PurchaseRequestSide}>
            <PurchaseRequestSalesInfo/>
            <PurchaseRequestDeliveryInfo/>
            <PurchaseRequestAmountInfo/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  purchaseRequest: makeSelectPurchaseRequest(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseRequest: (payload) => dispatch(getPurchaseRequest(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(PurchaseRequest);
