import LayoutAdmin from 'layouts/LayoutAdmin/LayoutAdmin';
import PurchaseRequests from 'views/oms/PurchaseRequests/PurchaseRequests';
import PurchaseRequest from 'views/oms/PurchaseRequest/PurchaseRequest';
import SalesOrders from 'views/oms/SalesOrders/SalesOrders';
import PaymentOrder from 'views/oms/PaymentOrder/PaymentOrder';

import SalesOrder from 'views/oms/SalesOrder/SalesOrder';
import AwaitingDeliveryOrders from 'views/oms/AwaitingDeliveryOrders/AwaitingDeliveryOrders';

import Shipments from 'views/oms/Shipments/Shipments';
import Shipment from 'views/oms/Shipment/Shipment';

import CharityProject from 'views/oms/CharityProject/CharityProject';

import PermissionDenied from 'views/PermissionDenied/PermissionDenied';
import PageNotFound from 'views/PageNotFound/PageNotFound';

export const routes = [
  {
    path: '/',
    component: LayoutAdmin,
    routes: [
      {
        path: '/pr',
        component: PurchaseRequests,
        exact: true,
        userComponent: 'purchase_request',
      },
      {
        path: '/pr/:id',
        component: PurchaseRequest,
        exact: true,
        userComponent: 'purchase_request',
      },
      {
        path: '/so',
        component: SalesOrders,
        exact: true,
        userComponent: 'order',
      },
      {
        path: '/so/:id',
        component: SalesOrder,
        exact: true,
        userComponent: 'order',
      },
      {
        path: '/shipment',
        component: Shipments,
        exact: true,
        userComponent: 'shipment',
      },
      {
        path: '/shipment/:id',
        component: Shipment,
        exact: true,
        userComponent: 'shipment',
      },
      {
        path: '/awaiting-delivery-so',
        component: AwaitingDeliveryOrders,
        exact: true,
        userComponent: 'shipment',
      },
      {
        path: '/wallet',
        component: PaymentOrder,
        exact: true,
        userComponent: 'order',
      },
      {
        path: '/charity-goods-coordination/project',
        component: CharityProject,
        exact: true,
        userComponent: 'order',
      },
      {
        path: '/403',
        component: PermissionDenied,
        exact: true,
      },
      {
        path: '/*',
        component: PageNotFound,
      },
    ],
  },
];
