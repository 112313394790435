import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN, numberFormat } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectShipmentLogistic, makeSelectShipment } from 'redux/selectors';
import { REQUIRED_NOTE_MAP } from 'utils/constanst/omsSalesOrderConstants';

import Panel from 'components/Panel/Panel';

import classes from './ShipmentLogisticProvider.module.scss';

class ShipmentLogisticProvider extends PureComponent {
  _renderProviderRightAfterDeliverySent = (selectedLogistic) => {
    return (<div className={classes.Wrap}>
      <div className={`${classes.WrapAfterSent}`}>
        <div className={classes.Info}>
          <label>Đơn vị vận chuyển</label>
          <h4><strong>{selectedLogistic?.getcare_logistic_provider?.name}</strong></h4>
        </div>
        { !!selectedLogistic?.order_code &&
          <div className={classes.Info}>
            <label>Mã vận đơn</label>
            <span className="TextSecondary">
              { selectedLogistic.tracking_url
                ? <a className="TextSecondary" href={selectedLogistic.tracking_url} rel="noopener noreferrer" target="_blank">{ selectedLogistic.order_code }</a>
                : selectedLogistic.order_code
              }
            </span>
          </div>
        }
        { !!selectedLogistic?.cod &&
          <div className={classes.Info}>
            <label>Tiền thu khách</label>
            {currencyFormatVN(selectedLogistic.cod)}
          </div>
        }
        { !!selectedLogistic?.total_fee &&
          <div className={classes.Info}>
            <label>Phí vận chuyển</label>
            {currencyFormatVN(selectedLogistic.total_fee)}
          </div>
        }
        { !['', null, undefined].includes(selectedLogistic?.insurance_value) &&
          <div className={classes.Info}>
            <label>Bảo hiểm hàng hoá</label>
            {selectedLogistic.insurance_value > 0 ? 'Có' : 'Không'}
          </div>
        }
        { !!selectedLogistic?.insurance_value &&
          <div className={classes.Info}>
            <label>Giá trị bảo hiểm</label>
            {currencyFormatVN(selectedLogistic.insurance_value)}
          </div>
        }
        { !!selectedLogistic?.name_2 &&
          <div className={classes.Info}>
            <label>Nhà xe</label>
            {selectedLogistic?.name_2}
          </div>
        }
        { !!selectedLogistic?.transporter_user &&
          <div className={classes.Info}>
            <label>Người giao</label>
            {selectedLogistic.transporter_user.name}
          </div>
        }
        { !!selectedLogistic?.required_note &&
          <div className={classes.Info}>
            <label>Yêu cầu khi giao</label>
            {REQUIRED_NOTE_MAP[selectedLogistic.required_note]}
          </div>
        }
        <div className={classes.Info}>
          <label>Trọng lượng</label>
          {!['', null, undefined].includes(selectedLogistic?.weight) ? `${numberFormat(selectedLogistic.weight/1000)} kg` : `-`}
        </div>
        <div className={`${classes.Info} ${classes.InfoSizes}`}>
          <div className={classes.Info}>
            <label>Dài</label>
            {!['', null, undefined].includes(selectedLogistic?.length) ? `${numberFormat(selectedLogistic.length)} cm` : `-`}
          </div>
          <div className={classes.Info}>
            <label>Rộng</label>
            {!['', null, undefined].includes(selectedLogistic?.width) ? `${numberFormat(selectedLogistic.width)} cm` : `-`}
          </div>
          <div className={classes.Info}>
            <label>Cao</label>
            {!['', null, undefined].includes(selectedLogistic?.height) ? `${numberFormat(selectedLogistic.height)} cm` : `-`}
          </div>
        </div>
      </div>
      { selectedLogistic?.note && <p className={`${classes.NoteContent} PreWrap`}>{selectedLogistic.note}</p> }
    </div>)
  }

  render() {
    const { shipment } = this.props;

    return (<>
      <Panel
        title="Thông tin vận chuyển"
        panelClassName={classes.Panel}
        content={<>
          { shipment
            ? this._renderProviderRightAfterDeliverySent(shipment)
            : <p className="NoData">Không có dịch vụ vận chuyển</p>
          }
        </>}
        isBorder
      />
    </>)
  }
}

ShipmentLogisticProvider.propTypes = {
  shipmentLogistic: PropTypes.object,
};

ShipmentLogisticProvider.defaultProps = {
  shipmentLogistic: null,
};

const mapStateToProps = createStructuredSelector({
  shipmentLogistic: makeSelectShipmentLogistic(),
  shipment: makeSelectShipment(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(ShipmentLogisticProvider);
