import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDisplayFields } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderBonuses } from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import { soBonusesParamsMap, soBonusesDisplayFields } from 'utils/constanst/omsSalesOrderConstants';

import SalesOrderBonus from './SalesOrderBonus/SalesOrderBonus';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './SalesOrderBonuses.module.scss';
import gridClasses from './SalesOrderBonusesGrid.module.scss';

class SalesOrderBonuses extends PureComponent {
  render() {
    const { salesOrderBonuses } = this.props;
    const displayFields = getDisplayFields(soBonusesParamsMap, soBonusesDisplayFields);

    return (<>
      { salesOrderBonuses && salesOrderBonuses.length > 0 &&
        <div className={`${classes.Wrap}`}>
          <h4 className={classes.SectionTitle}><strong>Danh sách hàng khuyến mãi</strong></h4>
          <div className={`${classes.List}`}>
            <div className={`${gridClasses.Row} ${classes.Header}`}>
              <ListHeaderCol label="#"/>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={gridClasses.Col}
                  {...item}
                />
              ))}
            </div>
            <div className={`${classes.Body}`}>
              {salesOrderBonuses.map((item, index) => (
                <SalesOrderBonus
                  key={`so-bonus-${item.id}`}
                  {...item}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      }
    </>);
  }
}

SalesOrderBonuses.propTypes = {
  salesOrderBonuses: PropTypes.array,
};

SalesOrderBonuses.defaultProps = {
  salesOrderBonuses: [],
};

const mapStateToProps = createStructuredSelector({
  salesOrderBonuses: makeSelectSalesOrderBonuses(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(SalesOrderBonuses);
