import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrder } from 'redux/selectors';
import {
  UPDATE_SALESMAN,
  getActionItem,
} from 'utils/constanst/omsSalesOrderConstants';

import Panel from 'components/Panel/Panel';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import SalesOrderSalesFormDialog from '../SalesOrderSalesFormDialog/SalesOrderSalesFormDialog';

import classes from './SalesOrderSalesInfo.module.scss';

class SalesOrderSalesInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openSalesUserDialog: false,
    }
  }

  handleOpenSalesUserDialog = () => {
    this.setState({
      openSalesUserDialog: true,
    });
  }
  handleCloseSalesUserDialog = () => {
    this.setState({
      openSalesUserDialog: false,
    });
  }
  handleSaveSalesUserSuccess = (newSalesOrderData) => {
    this.props.onSaveSalesInfoSuccess(newSalesOrderData);
  }

  render() {
    const { salesOrder } = this.props;
    const actions = salesOrder?.actions;

    return (<>
      <Panel
        title="Phụ trách bởi"
        titleAction={
          getActionItem(UPDATE_SALESMAN, actions) ? (<IconButton
            size="small"
            variant="contained"
            color="primary"
            onClick={this.handleOpenSalesUserDialog}
          ><EditIcon fontSize="small" /></IconButton>) : ''
        }
        size="sm"
        panelClassName={classes.Panel}
        content={<div className={classes.Wrap}>
          { salesOrder && salesOrder?.sales_name && (
            <>
              <div className={classes.Info}>
                <label>Trình Dược Viên</label>
                {salesOrder?.sales_name || `-`}
              </div>
              <div className={classes.Info}>
                <label>Số điện thoại</label>
                {salesOrder?.sales_phone || `-`}
              </div>
              { salesOrder?.sales_note &&
                (<div className={`${classes.Info} ${classes.Note}`}>
                  <div className={classes.NoteContent}>{salesOrder.sales_note}</div>
                </div>)
              }
            </>)
          }
        </div>}
        isBorder
      />
      {this.state.openSalesUserDialog &&
        <SalesOrderSalesFormDialog
          isOpen={this.state.openSalesUserDialog}
          onClose={this.handleCloseSalesUserDialog}
          salesOrderId={salesOrder?.id}
          salesId={salesOrder?.getcare_sales_id}
          salesName={salesOrder?.sales_name}
          onSaveSuccess={this.handleSaveSalesUserSuccess}
        />
      }
    </>)
  }
}

SalesOrderSalesInfo.propTypes = {
  salesOrder: PropTypes.object,
  onSaveSalesInfoSuccess: PropTypes.func,
};
SalesOrderSalesInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SalesOrderSalesInfo);
