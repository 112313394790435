import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectSalesOrder,
  makeSelectSalesOrderHistory,
} from 'redux/selectors';
import { currencyFormatVN, validNumber } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import { validDate } from 'utils/helper';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import Moment from 'moment';
import 'moment/locale/vi';
import { Link } from 'react-router-dom';

import Panel from 'components/Panel/Panel';

import classes from './SalesOrderPaymentInfo.module.scss';

class SalesOrderPaymentInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      VNPAY: false,
    };
    this.innerFormRef = null;
  }

  _getAmountShippingCoupon = () => {
    const { salesOrder } = this.props;
    return salesOrder?.amount_shipping_coupon || 0;
  };

  async componentDidUpdate(prevProps, prevState) {
    const { salesOrderCode, salesOrderHistory } = this.props;

    if (
      salesOrderHistory &&
      salesOrderHistory !== prevProps.salesOrderHistory
    ) {
      const paidPayment = salesOrderHistory.find(
        (x) => x.getcare_order_status.name === 'Giao hàng thành công'
      );
      this.setState({
        paidPayment: paidPayment ? paidPayment : '',
      });
    }
    if (salesOrderCode && salesOrderCode !== prevProps.salesOrderCode) {
      const { data: response } = await salesOrderApi.getPayment(salesOrderCode);

      if (!response?.result || response.data.length === 0) {
      } else {
        this.setState({
          vnPayment: response.data[0] ? response.data[0] : [],
          VNPAY: true,
        });
      }
    }
  }

  render() {
    const { salesOrder } = this.props;
    const { VNPAY, vnPayment, paidPayment } = this.state;
    const subTotal = salesOrder?.amount + salesOrder?.amount_vat;

    return (
      <Panel
        title={
          <>
            Thông tin thanh toán
            {salesOrder ? (
              <>
                {VNPAY === true ? (
                  <span className={classes.UpdateInfo}>
                    Thanh toán qua VNPAY{` `}
                    lúc
                    <span>
                      {' '}
                      {`${
                        validDate(vnPayment?.created_at)
                          ? Moment(vnPayment?.created_at)
                              .lang('vi')
                              .format(' HH:mm dddd DD/MM/YYYY')
                          : ''
                      }`}
                    </span>
                  </span>
                ) : (
                  <span className={classes.UpdateInfo}>
                    Thanh toán khi nhận hàng (COD) 
                      {
                        paidPayment ?
                         <span> - Đã thanh toán lúc <span className={classes.UpdateTime}>  {Moment(paidPayment?.created_at).lang('vi').format(' HH:mm dddd DD/MM/YYYY')} </span></span> 
                          : ''
                      }
                  </span>
                )}
              </>
            ) : (
              ''
            )}
          </>
        }
        size="sm"
        panelClassName={classes.Panel}
        content={
          <div className={classes.Wrap}>
            {salesOrder ? (
              <>
                {VNPAY === true ? (
                  <>
                    <table className={`${classes.InfoTable}`}>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Số tiền</strong>
                          </td>
                          <td>
                            {validNumber(vnPayment.amount)
                              ? currencyFormatVN(vnPayment.amount)
                              : `-`}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mã đơn hàng</strong>
                          </td>
                          <td>
                            {salesOrder?.code ? (
                              <Link
                                className="TextSecondary"
                                to={`/so/${salesOrder?.code}`}
                              >
                                {salesOrder?.code}
                              </Link>
                            ) : (
                              `-`
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mã giao dịch trên VNPAY</strong>
                          </td>
                          <td>
                            {vnPayment?.transaction_code
                              ? vnPayment?.transaction_code
                              : `-`}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Ngân hàng</strong>
                          </td>
                          <td>
                            {vnPayment?.bank_code ? vnPayment?.bank_code : `-`}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Tài khoản/Thẻ</strong>
                          </td>
                          <td>
                            {vnPayment?.card_type ? vnPayment?.card_type : `-`}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mã giao dịch tại ngân hàng</strong>
                          </td>
                          <td>
                            {vnPayment?.bank_tran_no
                              ? vnPayment?.bank_tran_no
                              : `-`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <table className={`${classes.InfoTable}`}>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Số tiền phải thanh toán</strong>
                          </td>
                          <td>
                            {' '}
                            {validNumber(subTotal)
                              ? currencyFormatVN(subTotal)
                              : `-`}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Mã đơn hàng</strong>
                          </td>
                          <td>
                            {' '}
                            {salesOrder?.code ? (
                              <Link
                                className="TextSecondary"
                                to={`/so/${salesOrder?.code}`}
                              >
                                {salesOrder?.code}
                              </Link>
                            ) : (
                              `-`
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </>
            ) : (
              <p className="NoData">Không có dịch vụ vận chuyển</p>
            )}
          </div>
        }
        isBorder
      />
    );
  }
}

SalesOrderPaymentInfo.propTypes = {
  salesOrder: PropTypes.object,
};
SalesOrderPaymentInfo.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
  salesOrderHistory: makeSelectSalesOrderHistory(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SalesOrderPaymentInfo);
