import { takeLatest } from 'redux-saga/effects';

import {
  CHECK_AUTH,
  SIGN_OUT,
  GET_USER_TEAM_LIST,
} from 'redux/constants/authConstants';
import {
  checkAuthentication,
  signMeOut,
  loadUserTeamList,
} from 'saga/authSaga';

import {
  GET_PURCHASE_REQUEST_LIST,
  GET_PURCHASE_REQUEST,
  GET_PURCHASE_REQUEST_STATUSES,
} from 'redux/constants/oms/purchaseRequestConstants';
import {
  loadPurchaseRequestList,
  loadPurchaseRequest,
  loadPurchaseRequestStatuses,
} from 'saga/oms/purchaseRequestSaga';

import {
  GET_SALES_ORDER_LIST,
  GET_SALES_TRANSITION_LIST,
  GET_SALES_ORDER,
  GET_SALES_ORDER_STATUSES,
  UPDATE_LOGISTIC_PROVIDER,
  UPDATE_DELIVERY_INFO,
  CONFIRM_PURCHASING,
  CONFIRM_SUCCESS,
  GET_SALES_ORDER_HISTORY,
  PRINT_DELIVERY_PAPER,
  CONFIRM_SUCCESS_DELIVERY,
  RETURN_DELIVERY,
  CONFIRM_SHIP_FROM_VENDOR_SUCCESS,
} from 'redux/constants/oms/salesOrderConstants';
import {
  loadSalesOrderList,
  loadSalesTransitionList,
  loadSalesOrder,
  loadSalesOrderStatuses,
  updateSalesOrderLogisticProvider,
  updateSalesOrderDeliveryInfo,
  confirmSalesOrderPurchasing,
  confirmSalesOrderSuccess,
  loadSalesOrderHistory,
  printSalesOrderDeliveryPaper,
  confirmSalesOrderDeliverySuccess,
  returnSalesOrderDelivery,
  confirmSalesOrderShipFromVendorSuccess,
} from 'saga/oms/salesOrderSaga';

import { GET_SALES_CHANNEL_LIST } from 'redux/constants/mdm/salesChannelConstants';
import { loadSalesChannelList } from 'saga/mdm/salesChannelSaga';

import {
  GET_DELIVERY_LOGISTIC_LIST,
  GET_LOGISTIC_LIST,
} from 'redux/constants/logisticConstants';
import {
  loadDeliveryLogisticList,
  loadLogisticList,
} from 'saga/logisticSaga';

import {
  GET_PROVINCE_LIST,
  GET_DISTRICT_LIST,
  GET_WARD_LIST,
} from 'redux/constants/locationConstants';
import {
  loadProvinceList,
  loadDistrictList,
  loadWardList,
} from 'saga/locationSaga';

// customer
import {
  GET_CUSTOMER_LIST,
  GET_SHIPMENT_CUSTOMER,
  CREATE_CUSTOMER_SHIPMENT,
} from 'redux/constants/oms/customerConstants';
import {
  loadCustomerList,
  loadShipmentCustomer,
  createCustomerShipment,
} from 'saga/oms/shipmentCustomerSaga';

// shipment
import {
  GET_SHIPMENT_STATUSES,
  GET_SHIPMENT_LIST,
  GET_SHIPMENT,
  PRINT_SHIPMENT,
  CONFIRM_SHIPMENT_SUCCESS_DELIVERY,
  RETURN_SHIPMENT_DELIVERY,
} from 'redux/constants/oms/shipmentConstants';
import {
  loadShipmentStatuses,
  loadShipmentList,
  loadShipment,
  printShipment,
  confirmShipmentDeliverySuccess,
  returnShipmentDelivery,
} from 'saga/oms/shipmentSaga';

// delivery
import { GET_DELIVERY_USER_LIST } from 'redux/constants/oms/deliveryConstans';
import { loadDeliveryUserList } from 'saga/oms/deliverySaga';

/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount

  // auth
  yield takeLatest(CHECK_AUTH, checkAuthentication);
  yield takeLatest(SIGN_OUT, signMeOut);
  yield takeLatest(GET_USER_TEAM_LIST, loadUserTeamList);

  // logistic
  yield takeLatest(GET_DELIVERY_LOGISTIC_LIST, loadDeliveryLogisticList);
  yield takeLatest(GET_LOGISTIC_LIST, loadLogisticList);

  // purchase request
  yield takeLatest(GET_PURCHASE_REQUEST_LIST, loadPurchaseRequestList);
  yield takeLatest(GET_PURCHASE_REQUEST, loadPurchaseRequest);
  yield takeLatest(GET_PURCHASE_REQUEST_STATUSES, loadPurchaseRequestStatuses);

  // sales order
  yield takeLatest(GET_SALES_ORDER_STATUSES, loadSalesOrderStatuses);
  yield takeLatest(GET_SALES_ORDER_LIST, loadSalesOrderList);
  yield takeLatest(GET_SALES_TRANSITION_LIST, loadSalesTransitionList);

  yield takeLatest(GET_SALES_ORDER, loadSalesOrder);
  yield takeLatest(UPDATE_LOGISTIC_PROVIDER, updateSalesOrderLogisticProvider);
  yield takeLatest(UPDATE_DELIVERY_INFO, updateSalesOrderDeliveryInfo);
  yield takeLatest(CONFIRM_PURCHASING, confirmSalesOrderPurchasing);
  yield takeLatest(CONFIRM_SUCCESS, confirmSalesOrderSuccess);
  yield takeLatest(CONFIRM_SHIP_FROM_VENDOR_SUCCESS, confirmSalesOrderShipFromVendorSuccess);
  yield takeLatest(GET_SALES_ORDER_HISTORY, loadSalesOrderHistory);
  yield takeLatest(PRINT_DELIVERY_PAPER, printSalesOrderDeliveryPaper);
  yield takeLatest(CONFIRM_SUCCESS_DELIVERY, confirmSalesOrderDeliverySuccess);
  yield takeLatest(RETURN_DELIVERY, returnSalesOrderDelivery);

  // location
  yield takeLatest(GET_PROVINCE_LIST, loadProvinceList);
  yield takeLatest(GET_DISTRICT_LIST, loadDistrictList);
  yield takeLatest(GET_WARD_LIST, loadWardList);

  // customer
  yield takeLatest(GET_CUSTOMER_LIST, loadCustomerList);
  yield takeLatest(GET_SHIPMENT_CUSTOMER, loadShipmentCustomer);
  yield takeLatest(CREATE_CUSTOMER_SHIPMENT, createCustomerShipment);

  // shipment
  yield takeLatest(GET_SHIPMENT_STATUSES, loadShipmentStatuses);
  yield takeLatest(GET_SHIPMENT_LIST, loadShipmentList);
  yield takeLatest(GET_SHIPMENT, loadShipment);
  yield takeLatest(PRINT_SHIPMENT, printShipment);
  yield takeLatest(CONFIRM_SHIPMENT_SUCCESS_DELIVERY, confirmShipmentDeliverySuccess);
  yield takeLatest(RETURN_SHIPMENT_DELIVERY, returnShipmentDelivery);

  // delivery
  yield takeLatest(GET_DELIVERY_USER_LIST, loadDeliveryUserList);

  // sales channels
  yield takeLatest(GET_SALES_CHANNEL_LIST, loadSalesChannelList);
}
