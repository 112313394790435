import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { doPathsExist } from 'utils/helper/authorization';

import { Link, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import UserDropdown from './UserDropdown/UserDropdown';
import ServicesPanel from 'components/PageHeader/ServicesPanel/ServicesPanel';

import classes from './PageHeader.module.scss';

class PageHeader extends PureComponent {
  getNavLinkClass = (pathArr) => {
    return pathArr.some((path) => this.props.location.pathname.split('/').includes(path.replace('/', '')))
      ? classes.Active
      : '';
  };

  render() {
    const { availableRoutes } = this.props;

    return (
      <div className={classes.Header}>
        <Link to="/" className={classes.Brand}>
        <img className={classes.Logo} src={`${process.env.REACT_APP_PATH}static/logo/logo-phahub.svg`} height="100" alt="Phahub" />
        </Link>
        <nav className={classes.HeaderNav}>
          <ul>
            { doPathsExist(['/pr'], availableRoutes) &&
              <li>
                <NavLink
                  to="/pr"
                  className={this.getNavLinkClass(['/pr'])}
                >
                  Đặt hàng
                </NavLink>
              </li>
            }
            { doPathsExist(['/so'], availableRoutes) &&
              <li>
                <NavLink
                  to="/so"
                  className={this.getNavLinkClass(['/so'])}
                >
                  Bán hàng
                </NavLink>
              </li>
            }
            { doPathsExist(['/shipment', '/awaiting-delivery-so'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/shipment"
                  className={this.getNavLinkClass(['/shipment', '/awaiting-delivery-so'])}
                >
                  Giao hàng
                </NavLink>
                <ul className={classes.SubNav}>
                  { doPathsExist(['/shipment'], availableRoutes) &&
                    <li>
                      <NavLink to="/shipment" exact activeClassName={classes.Active}>
                        Danh sách đơn giao hàng
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/awaiting-delivery-so'], availableRoutes) &&
                    <li>
                      <NavLink to="/awaiting-delivery-so" exact activeClassName={classes.Active}>
                        Danh sách đơn chờ giao hàng
                      </NavLink>
                    </li>
                  }
                  { doPathsExist(['/shipment'], availableRoutes) &&
                    <li>
                      <NavLink to="/shipment/customer" exact activeClassName={classes.Active}>
                        Danh sách khách chờ giao hàng
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
              { doPathsExist(['/wallet'], availableRoutes) &&
              <li>
                <NavLink
                  to="/wallet"
                  className={this.getNavLinkClass(['/wallet'])}
                >
                  Ví Phahub
                </NavLink>
              </li>
            }
            { doPathsExist(['/charity-goods-coordination/project'], availableRoutes) &&
              <li>
                <NavLink
                  disabled
                  to="/charity-goods-coordination/project"
                  className={this.getNavLinkClass(['/charity-goods-coordination'])}
                >
                  Điều phối hàng từ thiện
                </NavLink>
                <ul className={classes.SubNav}>
                  { doPathsExist(['/charity-goods-coordination/project'], availableRoutes) &&
                    <li>
                      <NavLink to="/charity-goods-coordination/project" exact activeClassName={classes.Active}>
                        Phân bổ theo dự án
                      </NavLink>
                    </li>
                  }
                </ul>
              </li>
            }
          </ul>
        </nav>
        <ul className={classes.RightNav}>
          <li>
            <ServicesPanel iconSize="default" />
          </li>
          <li>
            <UserDropdown />
          </li>
        </ul>
      </div>
    );
  }
}

PageHeader.propTypes = {
  availableRoutes: PropTypes.array,
};

PageHeader.defaultProps = {
  availableRoutes: [],
};

export default withRouter(PageHeader);
