import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getPurchaseRequestList } from 'redux/actions/oms/purchaseRequestActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPurchaseRequestLoading,
  makeSelectPurchaseRequestList,
  makeSelectPurchaseRequestListTotal,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/omsPurchaseRequestConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';

import PurchaseRequestList from 'components/pr/PurchaseRequestList/PurchaseRequestList';
import PurchaseRequestListFilters from 'components/pr/PurchaseRequestList/PurchaseRequestListFilters/PurchaseRequestListFilters';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './PurchaseRequests.module.scss';

class PurchaseRequests extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }
  componentDidMount() {
    this.props.getPurchaseRequestList({
      params: this.state.listParams,
    });
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['amount_total'].includes(item.name)) {
        const numArr = item.value.map(val => val.trim() !== '' ? Number(val) : '');
        memo[`${item.name}_from`] = numArr[numArr.length - 1] && numArr[0] ? Math.min.apply(null, [...numArr]) : numArr[0];
        memo[`${item.name}_to`] = numArr[numArr.length - 1] && numArr[0] ? Math.max.apply(null, [...numArr]) : numArr[numArr.length - 1];
      } else if (['created_at'].includes(item.name)) {
        memo[`${item.name}_from`] = item.value[0];
        memo[`${item.name}_to`] = item.value[1];
      } else {
        memo[item.name] = item.value;
      }
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getPurchaseRequestList({
      params: newParams,
    });
  };

  render() {
    const { purchaseRequestList, purchaseRequestListTotal, loading } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;

    return (
      <div className={classes.PageWrap}>
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>Danh sách đơn đặt hàng</h1>
        </div>
        <div className={classes.PageMain}>
          <PurchaseRequestListFilters
            onFilterChange={debounce(this.handleFilterChange, 500)}
          />
          <PurchaseRequestList
            displayFields={getDisplayFields(
              listParams,
              listDisplayFields
            )}
            purchaseRequestList={purchaseRequestList}
            isLoading={isListLoading}
            onSortChange={this.handleSortChange}
          />
        </div>
        <div className={classes.PageFooter}>
          <ListPagination
            page={listParams.page}
            pageSize={listParams.page_size}
            total={purchaseRequestListTotal}
            listName="đơn đặt hàng"
            onFilterChange={this.handleFilterChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  purchaseRequestList: makeSelectPurchaseRequestList(),
  purchaseRequestListTotal: makeSelectPurchaseRequestListTotal(),
  loading: makeSelectPurchaseRequestLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseRequestList: (payload) => dispatch(getPurchaseRequestList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(PurchaseRequests);
