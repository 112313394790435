import {
  SAVE_SHIPMENT_STATUSES,

  SAVE_LOADING,

  SAVE_SHIPMENT_LIST,
  SAVE_SHIPMENT_LIST_TOTAL,

  SAVE_SHIPMENT,
  SAVE_SHIPMENT_PRODUCTS,

  SAVE_SHIPMENT_LOGISTIC,
  SAVE_SHIPMENT_DELIVERY,

  SAVE_SHIPMENT_ALL_INFO,
} from 'redux/constants/oms/shipmentConstants';

const initialState = {
  shipmentStatuses: [],

  loading: false,

  shipmentList: [],
  shipmentListTotal: 0,

  shipment: null,
  shipmentProducts: [],
  shipmentLogistic: null,
  shipmentDelivery: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SHIPMENT_STATUSES: {
      return {
        ...state,
        shipmentStatuses: action.shipmentStatuses ? action.shipmentStatuses : [],
      };
    }

    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_SHIPMENT_LIST: {
      const list = action.shipmentList;
      return {
        ...state,
        shipmentList: list ? [...list] : [],
      };
    }
    case SAVE_SHIPMENT_LIST_TOTAL: {
      return {
        ...state,
        shipmentListTotal: action.shipmentListTotal,
      };
    }

    case SAVE_SHIPMENT: {
      return {
        ...state,
        shipment: action.shipment,
      };
    }
    case SAVE_SHIPMENT_PRODUCTS: {
      return {
        ...state,
        shipmentProducts: action.shipmentProducts,
      };
    }

    case SAVE_SHIPMENT_LOGISTIC: {
      return {
        ...state,
        shipmentLogistic: action.shipmentLogistic,
      };
    }
    case SAVE_SHIPMENT_DELIVERY: {
      return {
        ...state,
        shipmentDelivery: action.shipmentDelivery,
      };
    }
    case SAVE_SHIPMENT_ALL_INFO: {
      return {
        ...state,
        shipment: action.shipmentData ? {
          ...action.shipmentData,
          getcare_order_items: undefined,
        } : null,
        shipmentProducts: action.shipmentData?.getcare_order_items || [],
        shipmentNotes: action.shipmentData?.getcare_order_notes || [],
        shipmentLogistic: action.shipmentData?.logistic_provider || null,
        shipmentDelivery: action.shipmentData?.customer_delivery || null,
      };
    }

    default:
      return state;
  }
};

export default reducer;
