import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getSalesOrderList } from 'redux/actions/oms/salesOrderActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectSalesOrderLoading,
  makeSelectSalesOrderList,
  makeSelectSalesOrderListTotal,
} from 'redux/selectors';
import {
  awaitingDeliveryOrdersDisplayFields,
  awaitingDeliveryOrderListParamsMap,
} from 'utils/constanst/omsSalesOrderConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import AwaitingDeliveryOrderList from 'components/so/AwaitingDeliveryOrderList/AwaitingDeliveryOrderList';
import AwaitingDeliveryOrderListFilters from 'components/so/AwaitingDeliveryOrderList/AwaitingDeliveryOrderListFilters/AwaitingDeliveryOrderListFilters';
import MultiOrdersDeliverySentDialog from 'components/so/AwaitingDeliveryOrderList/MultiOrdersDeliverySentDialog/MultiOrdersDeliverySentDialog';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './AwaitingDeliveryOrders.module.scss';

class AwaitingDeliveryOrders extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...awaitingDeliveryOrderListParamsMap },

      selectedPackedOrders: [],
      isMultiOrdersDeliverySentDialogOpen: false,
    };
  }
  componentDidMount() {
    this.props.getSalesOrderList({
      params: this.state.listParams,
    });
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['amount_total'].includes(item.name)) {
        const numArr = item.value.map(val => val.trim() !== '' ? Number(val) : '');
        memo[`${item.name}_from`] = numArr[numArr.length - 1] && numArr[0] ? Math.min.apply(null, [...numArr]) : numArr[0];
        memo[`${item.name}_to`] = numArr[numArr.length - 1] && numArr[0] ? Math.max.apply(null, [...numArr]) : numArr[numArr.length - 1];
      } else if (['created_at'].includes(item.name)) {
        memo[`${item.name}_from`] = item.value[0];
        memo[`${item.name}_to`] = item.value[1];
      } else {
        memo[item.name] = item.value;
      }
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getSalesOrderList({
      params: newParams,
    });
  };
  handlePackedItemSelectedToggle = ({ order, isSelected }) => {
    const remainOrders = this.state.selectedPackedOrders.filter(item => item.id !== order.id);
    this.setState({
      selectedPackedOrders: isSelected ? [...remainOrders, { ...order }] : remainOrders,
    });
  }

  handleOpenMultiOrdersDeliverySentDialog = () => {
    this.setState({
      isMultiOrdersDeliverySentDialogOpen: true,
    });
  }
  handleCloseMultiOrdersDeliverySentDialog = () => {
    this.setState({
      isMultiOrdersDeliverySentDialogOpen: false,
    });
  }
  handleSentDeliveryMultiOrdersSuccess = () => {
    // reset selectedPackedOrders and reload list
    this.setState({
      selectedPackedOrders: [],
    });
    this.props.getSalesOrderList({
      params: this.state.listParams,
    });
  }
  handleSubmitDeliverySuccess = () => {
    // reload list
    this.props.getSalesOrderList({
      params: this.state.listParams,
    });
  }

  render() {
    const { salesOrderList, salesOrderListTotal, loading } = this.props;
    const { listParams, selectedPackedOrders, isMultiOrdersDeliverySentDialogOpen } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách đơn chờ giao hàng</h1>
            <div className={classes.PageActions}>
              { selectedPackedOrders.length > 0 &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleOpenMultiOrdersDeliverySentDialog}
                >
                  Gửi giao hàng
                </Button>
              }
            </div>
          </div>
          <div className={classes.PageMain}>
            <AwaitingDeliveryOrderListFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <AwaitingDeliveryOrderList
              displayFields={getDisplayFields(
                listParams,
                awaitingDeliveryOrdersDisplayFields
              )}
              salesOrderList={salesOrderList}
              isLoading={isListLoading}
              selectedPackedOrders={selectedPackedOrders}
              onSortChange={this.handleSortChange}
              onPackedItemSelectedToggle={this.handlePackedItemSelectedToggle}
              onSubmitDeliverySuccess={this.handleSubmitDeliverySuccess}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={salesOrderListTotal}
              listName="đơn hàng"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>

        { isMultiOrdersDeliverySentDialogOpen &&
          <MultiOrdersDeliverySentDialog
            isOpen={isMultiOrdersDeliverySentDialogOpen}
            selectedPackedOrders={selectedPackedOrders}
            onClose={this.handleCloseMultiOrdersDeliverySentDialog}
            onSubmitSuccess={this.handleSentDeliveryMultiOrdersSuccess}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesOrderList: makeSelectSalesOrderList(),
  salesOrderListTotal: makeSelectSalesOrderListTotal(),
  loading: makeSelectSalesOrderLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrderList: (payload) => dispatch(getSalesOrderList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(AwaitingDeliveryOrders);
