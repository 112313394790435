import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { compose } from 'redux';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { makeSelectLoginUser } from 'redux/selectors';
import paymentWalletApi from 'utils/api/oms/PaymentWallet';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classes from './SalesOrderListExportDialog.module.scss';
import {withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import { toast } from 'react-toastify';
import { currencyFormatVN } from 'utils/helper';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);


class SalesOrderListExportDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      codeSearchInputName: '',
      codeSearchInputID: '',
      userList: [],
      nameList: []
    }
  }


 async componentDidMount() {
    const { user } = this.props;
    const { data: response } = await paymentWalletApi.paymentUser();

    const user_data = {
      code : user?.code.toString(),
    }
    const user_name = {
      name : user?.name.toString(),
    }
  this.setState({
    userData: user_data,
    userName: user_name
  });
    const bank_name = response.data.map(x => (
      {
        code: x.code,
        name: x.name
      } 
    ));
    this.setState({
      bank_name: bank_name
    });
  
  }

  async componentDidUpdate(prevProps, prevState) {
    const { codeSearchInputID,codeSearchInputName } = this.state;
    if (codeSearchInputID && codeSearchInputID !== prevState.codeSearchInputID) {
      const { data: responseData } = await paymentWalletApi.paymentUserList(codeSearchInputID);
    const userList = responseData.data.map(x => (
      {
        code: x.code,
        name: x.name
      } 
    ));
    this.setState({
      userList
    });
    }
    if (codeSearchInputName && codeSearchInputName !== prevState.codeSearchInputName) {
      const { data: responseData } = await paymentWalletApi.paymentUserList(codeSearchInputName);
    const nameList = responseData.data.map(x => (
      {
        code: x.code,
        name: x.name
      } 
    ));
    this.setState({
      nameList
    });
    }
  }

  handleSubmit = async (values) => {
      const params = {
        getcare_customer_code: values.userID,
        order_info: values.bankNote,
        bank_no: '',
        bank_code: '',
        amount: values.bankMoney,
        getcare_customer_phone: values.selectPhone
      };
      const queryString = new URLSearchParams(params).toString();
      const { data: response } = await paymentWalletApi.paymentUserUpdate(queryString);
      if (response?.result ===  false) {
        toast.error(response?.message );
      }else{
        window.location.replace(response.data.url);
      }
  };
  onClose = () => {
    this.props.onClose();
  };


  render() {
    const { isOpen } = this.props;
    const { isLoading,bank_name,userList,codeSearchInputID,codeSearchInputName,nameList} = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <div className={classes.DialogTitle}>
            <DialogTitle>Thêm hoạt động ví</DialogTitle>
            <IconButton size="small" onClick={this.props.onClose}><Close/></IconButton>
          </div>
          <Formik
            initialValues={{
              user_id :  '',
              userID:  '',
              userName:  '',
              user_name : '',
              bank_code: '',
              selectMethod: 'Nạp tiền',
              bank: '',
              bank_number:'',
              bankMoney: '',
              bankNote:'',
              selectPhone: '0377033681'
            }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              userID: Yup.string().required("Vui lòng chọn"),
              userName: Yup.string().required("Vui lòng chọn"),
              bank: Yup.string().required("Vui lòng chọn"),
              bank_number: Yup.string().required("Vui lòng chọn"),
              selectMethod: Yup.string().required("Vui lòng chọn"),
              bankMoney: Yup.string().required("Vui lòng chọn"),
              bankNote: Yup.string().required("Vui lòng chọn"),
              selectPhone: Yup.string().required("Vui lòng chọn"),
              // user_id: Yup.string().required("Vui lòng chọn"),
            })}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                touched,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <form noValidate autoComplete="off">
                  <DialogContent className={classes.DialogContent}>
                <div className={classes.ProductOptions}>
                    <span className={classes.ProductOptionsLabel}>Customer ID</span>
                    <Autocomplete
      id="combo-box-demo"
      disableClearable
      handleHomeEndKeys={false}
      options={userList }
      value={values.user_id || null}
      inputValue={codeSearchInputID}
      onInputChange={(e, newValue) => {
        this.setState({
          codeSearchInputID: newValue,
        });
      }}
      // filterOptions={(x) => x}
      getOptionLabel={(option) => option?.code || ''}
      renderOption={(option) => option?.code}
      getOptionSelected={(option, value) =>
        value && option && value.code && option.code ? option.code === value.code : null
      }
      onChange={(e, newValue) => {
        setFieldValue('user_id', newValue);
        setFieldValue('userID', newValue?.code ? newValue?.code : '');
        setFieldValue('user_name', newValue);
        setFieldValue('userName', newValue?.name ? newValue?.name : '');
      }}
      
      renderInput={(params) => <TextField {...params}  variant="outlined" 
      className={classes.selectAdornment}
      placeholder="Nhập..."
      InputProps={{
        ...params.InputProps,
        endAdornment: (
            <React.Fragment>
               <InputAdornment  position="end">
               <SearchIcon color="inherit" size={20} /> 
      </InputAdornment>
            </React.Fragment>
        ),
    }}
      error={!!errors.user_id}
      helperText={errors.user_id} />}
    />
                  </div>


                  <div className={classes.ProductOptions}>
                    <span className={classes.ProductOptionsLabel}>Tên tài khoản</span>
                    <Autocomplete
      id="combo-box-demo"
      disableClearable
      handleHomeEndKeys={false}
      options={nameList}
      inputValue={codeSearchInputName}
      onInputChange={(e, newValue) => {
        this.setState({
          codeSearchInputName: newValue,
        });
      }}
      value={values.user_name ? values.user_name  : null}
      getOptionLabel={(option) => option?.name || ''}
      renderOption={(option) => option?.name}
      getOptionSelected={(option, value) =>
        value && option && value.name && option.name ? option.name === value.name : null
      }
      onChange={(e, newValue) => {
        setFieldValue('user_name', newValue);
        setFieldValue('userName', newValue?.name ? newValue?.name : '');
        setFieldValue('user_id', newValue);
        setFieldValue('userID', newValue?.code ? newValue?.code : '');
      }}
      
      renderInput={(params) => <TextField {...params}  variant="outlined" 
      size="small"
      className={classes.selectAdornment}
      placeholder="Nhập..."
      InputProps={{
        ...params.InputProps,
        endAdornment: (
            <React.Fragment>
               <InputAdornment  position="end">
               <SearchIcon color="inherit" size={20} /> 
      </InputAdornment>
            </React.Fragment>
        ),
    }}
      error={!!errors.user_name}
      helperText={errors.user_name} />}
    />
                  </div>

                  <div className={classes.ProductOptions}>
                    <span className={classes.ProductOptionsLabel}>Ngân hàng</span>
                    <Autocomplete
      id="combo-box-demo"
      disableClearable
      handleHomeEndKeys={false}
      options={bank_name ? bank_name : []}
      value={values.bank_code ? values.bank_code : null}
      getOptionLabel={(option) => option?.name || ''}
      renderOption={(option) => option?.name}
      getOptionSelected={(option, value) =>
        option.name === value.name
      }
      onChange={(e, newValue) => {
        setFieldValue('bank_code', newValue);
        setFieldValue('bank', newValue?.code? newValue?.code  : '' );
      }}
      
      renderInput={(params) => <TextField {...params}  variant="outlined" 
      className={classes.selectAdornment}
      size="small"
      placeholder="Nhập..."
      InputProps={{
        ...params.InputProps,
        endAdornment: (
            <React.Fragment>
               <InputAdornment  position="end">
               <SearchIcon color="inherit" size={20} /> 
      </InputAdornment>
            </React.Fragment>
        ),
    }}
      error={!!errors.bank}
      helperText={errors.bank} />}
    />
                  </div> 
                  <div className={classes.ProductOptions}>
                    <span className={classes.ProductOptionsLabel}>Số tài khoản ngân hàng</span>
                  <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        value={values.bank_number ? values.bank_number : null}
        options={[]}
        selectOnFocus
        openOnFocus
        onChange={(e, newValue) => {
          setFieldValue('bank_number', e.target.value ? e.target.value  : '');
        }}
        
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            type="number"
            error={!!errors.bank_number}
            helperText={errors.bank_number}
            placeholder="Nhập..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                  <React.Fragment>
                     <InputAdornment  position="end">
                     <SearchIcon color="inherit" size={20} /> 
            </InputAdornment>
                  </React.Fragment>
              ),
          }}
            onChange={(e, newValue) => {
              setFieldValue('bank_number', e.target.value ? e.target.value  : '');
            }}
          />
        )}
      />
  </div> 
  <div className={classes.ProductOptions}>
                    <span className={classes.ProductOptionsLabel}>Hoạt động</span>
                    <FormControl className={classes.margin}>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={values.selectMethod}
          onChange={(e, newValue) => {
            setFieldValue('selectMethod', e.target.value ? e.target.value  : '');
          }}
          input={<BootstrapInput />}
        >
          <MenuItem value={'Nạp tiền'}>Nạp tiền</MenuItem>
          <MenuItem value={'Rút tiền'}>Rút tiền</MenuItem>
        </Select>
        <FormHelperText>{(errors.selectMethod && touched.selectMethod) && errors.selectMethod}</FormHelperText>
      </FormControl>
            </div>
            <div className={classes.ProductOptions}>
                    <span className={classes.ProductOptionsLabel}>Số tiền</span>
                    <TextField id="outlined-basic"  variant="outlined" 
                     size="small"
                     placeholder="Nhập..."
                     type="number"
                     error={!!errors.bankMoney}
                     helperText={errors.bankMoney}
                     value={values.bankMoney}
                    onChange={(e, newValue) => {
                      setFieldValue('bankMoney', e.target.value ? e.target.value  : '');
                    }}
                    />
  </div>
  <div className={classes.ProductOptions}>
                    <span className={classes.ProductOptionsLabel}>Ghi chú</span>
      <FormControl className={classes.FormControl}>
  <TextareaAutosize
      minRows={4}
      className={classes.TextArea}
      aria-label="maximum height"
      placeholder="Nhập ghi chú"
      onChange={(e, newValue) => {
        setFieldValue('bankNote', e.target.value ? e.target.value  : '');
      }}
      defaultValue={values.bankNote ?values.bankNote : null}
    />
    <FormHelperText className={classes.HelperText}>{(errors.bankNote && touched.bankNote) && errors.bankNote}</FormHelperText>
    </FormControl>
    </div>
    <div className={classes.ProductOptions}>
                    <span className={classes.ProductOptionsLabel}>Số điện thoại OTP duyệt</span>
                    <FormControl className={classes.margin}>
        <Select
          id="selectPhone"
          value={values.selectPhone ? values.selectPhone : null}
          onChange={(e, newValue) => {
            setFieldValue('selectPhone', e.target.value ? e.target.value  : '');
          }}
          input={<BootstrapInput />}
        >
          <MenuItem value={'0377033681'}>0377033681</MenuItem>
        </Select>
        <FormHelperText>{(errors.selectPhone && touched.selectPhone) && errors.selectPhone}</FormHelperText>
      </FormControl>
            </div>
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      // disabled={!isEmpty(errors) || !list.length}
                      onClick={handleSubmit}
                      color="primary"
                    >
                      Xác nhận
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}



const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(SalesOrderListExportDialog);

