export const DF_FS = 16;
export const REM = '1rem';

export const defaultTheme = {
  palette: {
    primary: {
      light: '#D55958',
      main: '#CB302F',
      dark: '#8E2120',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    fontSize: 14,
    button: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontWeight: 600,
      letterSpacing: 0,
      textTransform: 'none',
      fontSize: 14,
    },
    body1: {
      letterSpacing: 0,
      fontSize: 14,
    },
    body2: {
      letterSpacing: 0,
      fontSize: 14,
    }
  },
};
