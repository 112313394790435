import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';

import { 
  Typography 
} from '@material-ui/core';

import styles from './MainPage.module.scss';

class MainPage extends PureComponent {
  render() {
    const { 
      title,
      children 
    } = this.props;

    return (
      <React.Fragment>
        <div className={styles.wrap}>
          {
            title && <div className={styles.header}>
              <Typography variant="h5">{title}</Typography>
            </div>
          }
          <div className={styles.content}>
            {children}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({

});
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(MainPage);
