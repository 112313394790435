import {
  GET_CUSTOMER_LIST,
  SAVE_CUSTOMER_LIST,
  SAVE_CUSTOMER_LIST_TOTAL,
  SAVE_LOADING,
  GET_SHIPMENT_CUSTOMER,
  SAVE_SHIPMENT_CUSTOMER_INFO,
  SAVE_SHIPMENT_CUSTOMER_ORDERS,
  SAVE_SHIPMENT_CUSTOMER_ORDER_ITEM,
  CREATE_CUSTOMER_SHIPMENT,
} from 'redux/constants/oms/customerConstants';

export function getCustomerList(payload) {
  return {
    type: GET_CUSTOMER_LIST,
    payload,
  };
}
export function getShipmentCustomer(payload) {
  return {
    type: GET_SHIPMENT_CUSTOMER,
    payload,
  };
}
export function createCustomerShipment(payload) {
  return {
    type: CREATE_CUSTOMER_SHIPMENT,
    payload,
  };
}

// mutation
export function saveCustomerList(customerList) {
  return {
    type: SAVE_CUSTOMER_LIST,
    customerList,
  };
}
export function saveCustomerListTotal(customerListTotal) {
  return {
    type: SAVE_CUSTOMER_LIST_TOTAL,
    customerListTotal,
  };
}
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}

export function saveShipmentCustomerInfo(shipmentCustomer) {
  return {
    type: SAVE_SHIPMENT_CUSTOMER_INFO,
    shipmentCustomer,
  };
}
export function saveShipmentCustomerOrders(shipmentCustomerOrders) {
  return {
    type: SAVE_SHIPMENT_CUSTOMER_ORDERS,
    shipmentCustomerOrders,
  };
}
export function saveShipmentCustomerOrderItem(shipmentCustomerOrderItem) {
  return {
    type: SAVE_SHIPMENT_CUSTOMER_ORDER_ITEM,
    shipmentCustomerOrderItem,
  };
}
