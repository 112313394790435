import axiosAuth from 'utils/axios/axiosAuth';

const purchaseRequestApi = {
  getAllStatuses: () => {
    return axiosAuth.get(`getcare_purchase_request_status`);
  },

  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_purchase_request`, {
      params: queryParams,
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_purchase_request/${id}`);
  },
};

export default purchaseRequestApi;
