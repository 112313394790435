export const listDisplayFields = [
  {
    name: 'name',
    label: 'Khách hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'delivery_address',
    label: 'Địa chỉ nhận hàng',
  },
  {
    name: 'number_order',
    label: 'Số đơn chờ giao',
    sortable: true,
    sortDir: '',
    className: 'TextCenter',
  },
  {
    name: 'order_price',
    label: 'Giá trị đơn hàng',
    sortable: true,
    sortDir: '',
    className: 'TextRight',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};
export const filterFields = [
  {
    name: 'name',
    label: 'Khách hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'delivery_address',
    label: 'Địa chỉ nhận hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'order_price',
    label: 'Giá trị đơn hàng',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập...',
  },
];
