import axiosAuth from 'utils/axios/axiosAuth';

const shipmentApi = {
  getAllStatuses: () => {
    return axiosAuth.get(`getcare_delivery_status`);
  },

  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_delivery_order`, {
      params: queryParams,
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_delivery_order/${id}`);
  },

  cancelShipment: ({ params }) => {
    return axiosAuth.post(`getcare_delivery_order_action/cancel_shipment`, params);
  },
  exportShipment: ({ params }) => {
    return axiosAuth.post(`getcare_delivery_order_action/purchase_delivery_export`, params);
  },
  confirmDeliverySuccess: ({ params }) => {
    return axiosAuth.post(`getcare_delivery_order_action/success_shipment`, params);
  },
  returnDelivery: ({ params }) => {
    return axiosAuth.post(`getcare_delivery_order_action/return_shipment`, params);
  },
};

export default shipmentApi;
