import React, { PureComponent } from 'react';
import { currencyFormatVN, roundCurrency } from 'utils/helper';
import { isLackingStock } from 'utils/constanst/omsSalesOrderConstants';

import Tooltip from '@material-ui/core/Tooltip';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import classes from './SalesOrderProduct.module.scss';
import gridClasses from '../SalesOrderProductsGrid.module.scss';

class SalesOrderProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmDialogOpen: false,
    }
  }

  handleRemove = () => {
    this.props.onRemoveProduct({
      id: this.props.id,
      willRemove: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleSubmitRemoveProductItem = () => {
    this.handleRemove();
    this.handleConfirmDialogClose();
  }

  render() {
    const {
      index,
      product_name,
      vendor_name,
      uom_base_name,
      price_sales_total,
      available_number,
      quantity_number,
      vat,
      getcare_order_item_discounts,
      disabledRemoving,
      salesOrderStatusId,
      type_label,
    } = this.props;
    const isQtyAvailable = !['', null, undefined].includes(available_number);
    const isItemLackingStock = isQtyAvailable && (available_number < quantity_number);
    const realQty = isQtyAvailable ? available_number : quantity_number;

    return (<>
      <div className={`${gridClasses.Row} ${classes.Item}`}>
        <div className={`${gridClasses.Col} TextCenter`}>{index + 1}</div>
        <div className={`${gridClasses.Col}`}>
          {product_name}
          { ['', null, undefined].includes(vat) && (
            <Tooltip title={`Sản phẩm không xuất hoá đơn`} arrow placement="top">
              <ReportProblemOutlinedIcon className={classes.NoInvoiceIcon} fontSize="small"/>
            </Tooltip>
          ) }
        </div>
        <div className={`${gridClasses.Col}`}>{vendor_name}</div>
        <div className={`${gridClasses.Col}`}>{uom_base_name}</div>
        <div className={`${gridClasses.Col}`}>{type_label || `-`}</div>
        <div className={`${gridClasses.Col} TextRight`}>{![null, undefined, ''].includes(price_sales_total) ? currencyFormatVN(price_sales_total) : ``}</div>
        <div className={`${gridClasses.Col} TextCenter`}>{quantity_number}</div>
        <div className={`${gridClasses.Col} ${isItemLackingStock ? classes.LackingStock : ''} TextCenter`}>
          { isQtyAvailable ? available_number : `-` }
        </div>
        <div className={`${gridClasses.Col} TextRight`}>
          { currencyFormatVN(roundCurrency(price_sales_total * Number(realQty))) }
        </div>
        { isLackingStock(salesOrderStatusId) && isItemLackingStock && !disabledRemoving &&
          <div className={`${gridClasses.Col} ${gridClasses.ActionsCol}`}>
            <IconButton
              disabled={disabledRemoving}
              onClick={this.handleConfirmDialogOpen}
              style={{ padding: '0' }}
              size="small"
            >
              <DeleteOutline fontSize="small" />
            </IconButton>
          </div>
        }
      </div>
      { getcare_order_item_discounts && getcare_order_item_discounts.length > 0 &&
        getcare_order_item_discounts.map((item, index) => (
          <div key={`pr-discount-${index}`} className={`${gridClasses.Row} ${gridClasses.LineDiscountRow} ${classes.Item} ${classes.DiscountItem}`}>
            <div className={`${gridClasses.Col}`}></div>
            <div className={`${gridClasses.Col}`}></div>
            <div className={gridClasses.GroupCol}>
              <div className={`${gridClasses.Col}`}>{`${item.promotion_bonus_name ? item.promotion_bonus_name + ` ` : ``}${item.promotion_code}`}</div>
              <div className={`${gridClasses.Col} TextRight`}>{ ['', null, undefined].includes(item.amount) ? '' : `(${currencyFormatVN(item.amount)})` }</div>
            </div>
            <div className={`${gridClasses.Col}`}></div>
          </div>
        ))
      }

      { this.state.isConfirmDialogOpen
        && <ConfirmationDialog
          isOpen={this.state.isConfirmDialogOpen}
          title="Xác nhận bỏ sản phẩm khỏi đơn hàng"
          message={<>Bạn có chắc bỏ sản phẩm <strong>{ product_name }</strong> khỏi đơn hàng để xác nhận mua?</>}
          onClose={this.handleConfirmDialogClose}
          onSubmit={this.handleSubmitRemoveProductItem}
        />
      }
    </>);
  }
}

export default SalesOrderProduct;
