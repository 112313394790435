import locationApi from 'utils/api/locationApi';
import { put } from 'redux-saga/effects';
import { locationActions } from 'redux/actions';

export function* loadProvinceList(payload) {
  const { params } = payload.payload;
  try {
    yield put(locationActions.saveProvinceList([]));

    const { data: response } = yield locationApi.getProvinces(params);

    if (!response?.result || !response.data) {
      return;
    }
    yield put(locationActions.saveProvinceList(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* loadDistrictList(payload) {
  const { params } = payload.payload;
  try {
    yield put(locationActions.saveDistrictList([]));

    if (!params.getcare_province_id) return;

    const { data: response } = yield locationApi.getDistricts(params);

    if (!response?.result || !response.data) {
      return;
    }
    yield put(locationActions.saveDistrictList(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* loadWardList(payload) {
  const { params } = payload.payload;
  try {
    yield put(locationActions.saveWardList([]));

    if (!params.getcare_district_id) return;

    const { data: response } = yield locationApi.getWards(params);

    if (!response?.result || !response.data) {
      return;
    }
    yield put(locationActions.saveWardList(response.data));
  } catch (err) {
    console.log(err);
  }
}
