import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { matchingKeyword } from 'utils/helper';

import {
  Toolbar,
  TableContainer,
  Typography,
  TextField,
  InputAdornment,
  Radio,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import StyledTableContainer from 'components/Styled/TableContainer/TableContainer';
import StyledDataTable from 'components/Styled/DataTable';

import charityProjectStyles from '../../CharityProject.module.scss';

class SupportProductList extends PureComponent {
  constructor(props) {
    super(props);
    const pageSizeOptions = [50,100,150];
    this.state = {
      dataTable: {
        initialState: {
          pageIndex: 0,
          pageSize: pageSizeOptions[0],
          // globalFilter: ''
        },
        pageOptions: pageSizeOptions,
      }
    };
  }
  
  // filteredData = () => {
  //   return this.state.dataParams.keyword ? this.props.supportProductList.filter( item => 
  //     matchingKeyword(this.state.dataParams.keyword,item.name) 
  //     || matchingKeyword(this.state.dataParams.keyword,item.manufacturer_name) 
  //     || matchingKeyword(this.state.dataParams.keyword,item.category_ecom_name) 
  //     || matchingKeyword(this.state.dataParams.keyword,item.uom_base_name) 
  //   ) : this.props.supportProductList;
  // }

  render() {
    const { className } = this.props;
    const columns = [
      {
        Header: '',
        accessor: 'item_id',
        Cell: rowData => {
          return <Radio
            checked={rowData.value === this.props.selectedProjectServiceRequestItemId}
            onChange={() => this.props.onSelectServiceRequestItemId && this.props.onSelectServiceRequestItemId(rowData.value)}
            value={rowData.id}
            name="project_id"
          />
        },
        cellProps: {
          padding: 'checkbox'
        }
      },
      {
        Header: 'Tên sản phẩm',
        accessor: 'name',
      },
      {
        Header: 'Nhà sản xuất',
        accessor: 'manufacturer_name',
      },

      {
        Header: 'Danh mục',
        accessor: "category_ecom_name",
      },
      {
        Header: 'Đơn vị',
        accessor: "uom_base_name",
      },
      {
        Header: 'Số lượng cần hỗ trợ',
        accessor: "quantity_number",
      },
      {
        Header: 'Số lượng đã nhận',
        accessor: "quantity_number_donate",
      },
    ]

    return (
      <div className={className}>
        <Typography className="margin__bottom--2" variant="h6">Danh sách sản phẩm cần hỗ trợ</Typography>
        <StyledTableContainer className={charityProjectStyles.dataTable}>
          <StyledDataTable
            {...this.state.dataTable}
            data={this.props.supportProductList}
            columns={columns}
            tableProps={{ stickyHeader: true }}
            getRowProps={(row) => ({
              selected: this.props.selectedProjectServiceRequestItemId === row.values.item_id,
              hover: true,
            })}
            componentBeforeTable={(props) => {
              return (
                <Toolbar>
                  <TextField
                    label="Tìm kiếm"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Tìm kiếm tên sản phẩm/nhà sản xuất/danh mục/đơn vị"
                    value={props.globalFilter}
                    name="keyword"
                    onChange={ (e) => props.setGlobalFilter(e.target.value)}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Toolbar>
              )
            }}
            tableWrapper={{
              component: TableContainer,
              props: {
                style: {
                  maxHeight: '588px'
                }
              }
            }}
          />
        </StyledTableContainer>
      </div>
    );
  }
}

SupportProductList.propTypes = {
  selectedProjectId: PropTypes.number,
  selectedProjectServiceRequestItemId: PropTypes.number,
  onSelectServiceRequestItemId: PropTypes.func,
  className: PropTypes.string,
  supportProductList: PropTypes.array,
  loading: PropTypes.bool,
};

SupportProductList.defaultProps = {
  selectedProjectId: null,
  selectedProjectServiceRequestItemId: null,
  className: '',
  loading: false,
  supportProductList: []
};

export default SupportProductList;
