import {
  SAVE_SALES_CHANNEL_LIST,
  SAVE_SALES_CHANNEL_LIST_TOTAL,
} from 'redux/constants/mdm/salesChannelConstants';

const initialState = {
  salesChannelList: null,
  salesChannelListTotal: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SALES_CHANNEL_LIST: {
      const list = action.salesChannelList;
      return {
        ...state,
        salesChannelList: list ? [ ...list ] : null,
      };
    }
    case SAVE_SALES_CHANNEL_LIST_TOTAL: {
      return {
        ...state,
        salesChannelListTotal: action.salesChannelListTotal,
      };
    }
    default:
      return state;
  }
}

export default reducer;
