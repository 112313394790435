import React, { PureComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { 
  Typography,
  Paper,
} from '@material-ui/core';

import styles from './Widget.module.scss';

const StyledProgress = () => <div className={styles.loading}>
  <div className={styles.loading__icon}>
    <CircularProgress
      variant="determinate"
      className={styles.loading__circleBottom}
      size={40}
      thickness={4}
      value={100}
    />
    <CircularProgress
      variant="indeterminate"
      disableShrink
      className={styles.loading__circleTop}
      classes={{
        circle: styles.loading__circle,
      }}
      size={40}
      thickness={4}
    />
  </div>
  <p className={styles.loading__content}>Đang tải...</p>
</div>

class Widget extends PureComponent {
  render() {
    const { 
      title,
      children,
      loading,
    } = this.props;

    return (
      <React.Fragment>
        <Paper elevation={3} className={styles.container}>
          {
            !loading ? <>
              {
                title && <div className={styles.header}>
                  <Typography variant="h6">{title}</Typography>
                </div>
              }
              <div className={styles.body}>
                {children}
              </div>
            </> : <StyledProgress/>
          }
        </Paper>
      </React.Fragment>
    );
  }
}

export default Widget;
