import React, { PureComponent } from 'react';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderDelivery, makeSelectSalesOrder, makeSelectSalesOrderLogistic } from 'redux/selectors';
import {
  UPDATE_CUSTOMER_DELIVERY,
  getActionItem,
} from 'utils/constanst/omsSalesOrderConstants';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DeliverySentForm from './DeliverySentForm/DeliverySentForm';
import DeliveryView from 'components/so/SalesOrderDeliveryInfo/DeliveryView/DeliveryView';
import SalesOrderCustomerDeliveryForm from 'components/so/SalesOrderCustomerDeliveryForm/SalesOrderCustomerDeliveryForm';

import classes from './SalesOrderDeliverySentDialog.module.scss';

class SalesOrderDeliverySentDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isCustomerDeliveryEditing: false,
    }
    this.innerFormRef = null;
  }
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
  };
  handleEditCustomerDelivery = () => {
    this.setState({
      isCustomerDeliveryEditing: true,
    });
  }
  handleCustomerDeliveryCancel = () => {
    this.setState({
      isCustomerDeliveryEditing: false,
    });
  }
  handleSubmitCustomerDelivery = async (values) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await salesOrderApi.updateCustomerDelivery({
      params: {
        id: this.props.salesOrderId,
        is_taxcode: values.hasTaxCode,
        taxcode: values.taxCode,
        tax_name: values.taxName,
        tax_address: values.taxAddress,
        name: values.name,
        phone: (/^(84)/).test(values.phone) ? values.phone.replace(/^(84)/, '+84') : values.phone,
        label: values.label,
        address: values.address,
        getcare_country_id: values.countryId,
        getcare_province_id: values.provinceId,
        getcare_district_id: values.districtId,
        getcare_ward_id: values.wardId,
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
      isCustomerDeliveryEditing: false,
    }, () => {
      this.props.onSaveDeliverySentSuccess(response.data);
    });
  }
  handleSaveDelivery = () => {
    this.innerFormRef.submitForm();
  }
  handleSubmitDelivery = async (params) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await salesOrderApi.updateDeliveryInfo({
      params: {
        id: this.props.salesOrderId,
        logistic_provider: {...params.logistic_provider},
        from_address: params.from_address ? {...params.from_address} : undefined,
      },
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      this.props.onClose();
      this.props.onSaveDeliverySentSuccess(response.data);
    });
  }

  render() {
    const { isOpen, salesOrderActions, salesOrder } = this.props;
    const { isLoading, isCustomerDeliveryEditing } = this.state;
    const amountGoods = Number((salesOrder?.amount || 0) + (salesOrder?.amount_vat || 0) - (salesOrder?.amount_discount || 0) - (salesOrder?.amount_paid || 0));
    const amountFee = Number((salesOrder?.amount_delivery || 0) - (salesOrder?.amount_shipping_coupon || 0));

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogContent className={classes.DialogContent}>
            <div className={classes.DialogContentSide}>
              <div className={classes.TitleWrap}>
                <DialogTitle className={classes.DialogTitle}>Thông tin nhận hàng</DialogTitle>
                { !isCustomerDeliveryEditing && getActionItem(UPDATE_CUSTOMER_DELIVERY, salesOrderActions) && <IconButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={this.handleEditCustomerDelivery}
                  ><EditIcon fontSize="small" /></IconButton>
                }
              </div>
              <div className={classes.Content}>
                { isCustomerDeliveryEditing
                  ? <SalesOrderCustomerDeliveryForm
                      onCancel={this.handleCustomerDeliveryCancel}
                      onSubmit={this.handleSubmitCustomerDelivery}
                    />
                  : <DeliveryView
                      salesOrder={{...this.props.salesOrder}}
                      salesOrderDelivery={{...this.props.salesOrderDelivery}}
                    />
                }
              </div>
            </div>
            <div className={classes.DialogContentMain}>
              <DialogTitle className={classes.DialogTitle}>Đối tác vận chuyển</DialogTitle>
              <div className={classes.Content}>
                <DeliverySentForm
                  formRef={ref => {
                    this.innerFormRef = ref;
                  }}
                  salesOrder={{...this.props.salesOrder}}
                  amountGoods={amountGoods}
                  deliveryFeeTotal={amountFee}
                  customerCode={this.props.salesOrder?.customer_code}
                  customerDelivery={{...this.props.salesOrderDelivery}}
                  salesOrderLogistic={{...this.props.salesOrderLogistic}}
                  onSubmitDelivery={this.handleSubmitDelivery}
                />
              </div>
              <div className={classes.Actions}>
                <Button type="button" variant="outlined" onClick={this.props.onClose}>
                  Huỷ bỏ
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  onClick={this.handleSaveDelivery}
                  color="primary"
                  autoFocus
                >
                  Xác nhận gửi đơn
                </Button>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
  salesOrderDelivery: makeSelectSalesOrderDelivery(),
  salesOrderLogistic: makeSelectSalesOrderLogistic(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SalesOrderDeliverySentDialog);
