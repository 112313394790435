import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectShipment, makeSelectShipmentDelivery } from 'redux/selectors';

import Panel from 'components/Panel/Panel';
import DeliveryView from 'components/so/SalesOrderDeliveryInfo/DeliveryView/DeliveryView';

import classes from './ShipmentDeliveryInfo.module.scss';

class ShipmentDeliveryInfo extends PureComponent {
  _generateOrderDeliveryInfo = () => {
    const { shipment } = this.props;
    return {
      name: shipment?.to_name,
      phone: shipment?.to_phone,
      label: shipment?.to_label,
      address: shipment?.to_address,
      getcare_ward: shipment?.to_ward,
      getcare_district: shipment?.to_ward?.getcare_district,
      getcare_province: shipment?.to_ward?.getcare_district?.getcare_province,
    }
  }

  render() {
    return (<>
      <Panel
        title="Thông tin nhận hàng"
        size="sm"
        panelClassName={classes.Panel}
        content={<DeliveryView
          willShowVATInfo={false}
          salesOrderDelivery={{...this._generateOrderDeliveryInfo()}}
        />}
        isBorder
      />
    </>)
  }
}

ShipmentDeliveryInfo.propTypes = {
};
ShipmentDeliveryInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  shipment: makeSelectShipment(),
  shipmentDelivery: makeSelectShipmentDelivery(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(ShipmentDeliveryInfo);
