import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { startOfMonth, format } from 'date-fns';
import { isEmpty } from 'lodash';

import { validDate } from 'utils/helper';
import { dateFormat, apiDateFormat } from 'utils/constanst/dateConstants';
import salesOrderApi from 'utils/api/oms/salesOrderApi';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Close from '@material-ui/icons/Close';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FiltersExportSelection from './FiltersExportSelection/FiltersExportSelection';

import classes from './SalesOrderListExportDialog.module.scss';

class SalesOrderListExportDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }

  handleSubmit = async (values) => {
    this.setState({ isLoading: true });

    const { data: response } = await salesOrderApi.exportSalesOrders({
      params: {
        created_at_from: format(new Date(values.created_at_from), apiDateFormat),
        created_at_to: format(new Date(values.created_at_to), apiDateFormat),
        getcare_team_id: values.getcare_team_id,
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    this.setState({
      isLoading: false,
    });
  };
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, list } = this.props;
    const { isLoading } = this.state;
    const initSelectedIds = list.map(item => item.id);

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <div className={classes.DialogTitle}>
            <DialogTitle>Xuất đơn hàng</DialogTitle>
            <IconButton size="small" onClick={this.props.onClose}><Close/></IconButton>
          </div>
          <Formik
            initialValues={{
              created_at_from: startOfMonth(new Date()).toISOString(),
              created_at_to: (new Date()).toISOString(),
              getcare_team_id: [...initSelectedIds],
            }}
            validationSchema={Yup.object().shape({
              created_at_from: Yup.string().trim().required("Vui lòng chọn"),
              created_at_to: Yup.string().trim().required("Vui lòng chọn"),
              getcare_team_id: Yup.array().required().min(1),

            })}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
          >
            {(props) => {
              const {
                values,
                errors,
                handleSubmit,
                setFieldValue,
              } = props;
              return (
                <form noValidate autoComplete="off">
                  <DialogContent className={classes.DialogContent}>
                    <div className={classes.FieldControlGroup}>
                      <div className={classes.FieldControl}>
                        <label>Từ ngày</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            variant="inline"
                            format={dateFormat}
                            name="created_at_from"
                            value={validDate(values.created_at_from) ? validDate(values.created_at_from) : null}
                            maxDate={validDate(values.created_at_to)}
                            onChange={(date) => {
                              const value = validDate(date) ? date.toISOString() : '';
                              setFieldValue('created_at_from', value);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                              style: { padding: 0 },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className={classes.FieldControl}>
                        <label>Đến ngày</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            variant="inline"
                            format={dateFormat}
                            name="created_at_to"
                            value={validDate(values.created_at_to) ? validDate(values.created_at_to) : null}
                            minDate={validDate(values.created_at_from)}
                            onChange={(date) => {
                              const value = validDate(date) ? date.toISOString() : '';
                              setFieldValue('created_at_to', value);
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                              style: { padding: 0 },
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                    <FiltersExportSelection
                      list={[...list]}
                      onSelectedIdsChange={(ids) => {
                        setFieldValue('getcare_team_id', ids);
                      }}
                    />
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      disabled={!isEmpty(errors) || !list.length}
                      onClick={handleSubmit}
                      color="primary"
                    >
                      Xuất đơn hàng
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

SalesOrderListExportDialog.propTypes = {
  list: PropTypes.array,
};

SalesOrderListExportDialog.defaultProps = {
  list: [],
};
export default SalesOrderListExportDialog;
