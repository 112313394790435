// 3PL statuses
export const IN_PROGRESS_PACKING = 1; // chưa đóng hàng
export const PACKED = 2; // đã đóng hàng

export function get3PLStatusClass(soStatus) {
  if ([PACKED].includes(soStatus)) {
    return 'StatusLabel Success';
  }
  return 'StatusLabel Default';
}

// shipment statuses
export const DELIVERY_SERVICE_SENT = 1; // Gửi giao hàng
export const PICKED_UP = 2; // Đã lấy hàng
export const IN_PROGRESS_DELIVERY = 3; // Đang giao hàng
export const DELIVERY_SUCCESS = 4; // Giao hàng thành công
export const IS_PROGRESS_RETURN = 5; // Đang trả hàng
export const RETURNED = 6; // Đã trả hàng
export const CANCELLED_DELIVERY = 7; // Huỷ giao hàng

export function getShipmentStatusClass(status) {
  if ([IS_PROGRESS_RETURN, RETURNED ].includes(status)) {
    return 'StatusLabel Warning';
  }
  if ([PICKED_UP, IN_PROGRESS_DELIVERY ].includes(status)) {
    return 'StatusLabel Info';
  }
  if ([DELIVERY_SUCCESS].includes(status)) {
    return 'StatusLabel Success';
  }
  if ([CANCELLED_DELIVERY].includes(status)) {
    return 'StatusLabel Danger';
  }
  return 'StatusLabel Default';
}

// shipment list
export const shipmentListDisplayFields = [
  {
    name: 'code',
    label: 'Mã đơn GH',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_order_codes',
    label: 'Mã đơn hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'order_code',
    label: 'Mã vận đơn',
    sortable: true,
    sortDir: '',
    className: 'TextCenter',
  },
  {
    name: 'getcare_delivery_status_id',
    label: 'Trạng thái',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_logistic_provider_name',
    label: 'Loại vận chuyển',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'getcare_customer_name',
    label: 'Khách hàng',
  },
  {
    name: 'to_address',
    label: 'Địa chỉ',
  },
  {
    name: 'cod',
    label: 'Tiền thu hộ',
    sortable: true,
    sortDir: '',
    className: 'TextRight',
  },
  {
    name: 'fee',
    label: 'Phí VC',
    sortable: true,
    sortDir: '',
    className: 'TextRight',
  },
];
export const shipmentListParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};
export const shipmentFilterFields = [
  {
    name: 'code',
    label: 'Mã đơn giao hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_order_codes',
    label: 'Mã đơn hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'order_code',
    label: 'Mã vận đơn',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'getcare_delivery_status_id',
    label: 'Trạng thái',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'logistic_provider_id',
    label: 'Loại hình vận chuyển',
    value: [],
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'getcare_customer_name',
    label: 'Khách hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'cod',
    label: 'Tiền thu hộ',
    value: ['', ''],
    type: 'custom',
    placeholder: 'Nhập...',
  },
];

// actions
export const PRINT_SHIPMENT = "print_purchase_delivery";
export const SUCCESS_DELIVERY = "success_delivery";
export const RETURN_DELIVERY = "return_delivery";
export const CANCEL = "cancel";

export function getActionItem(actionCode, orderDetailsActions) {
  if (!orderDetailsActions) return;
  return orderDetailsActions.find(action => action.code === actionCode);
}
