import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { toast } from 'react-toastify';
import { saveSalesOrderAllInfo } from 'redux/actions/oms/salesOrderActions';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrder, makeSelectSalesOrderDelivery, makeSelectSalesOrderLogistic } from 'redux/selectors';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DeliverySentForm from 'components/so/SalesOrderDeliverySentDialog/DeliverySentForm/DeliverySentForm';

import classes from './PackingFormDialog.module.scss';

class PackingFormDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
    this.innerFormRef = null;
  }
  componentDidMount() {
    this.props.saveSalesOrderAllInfo(this.props.salesOrderData);
  }
  onClose = () => {
    this.props.onClose();
  };
  handleSaveDelivery = () => {
    this.innerFormRef.submitForm();
  }
  handleSubmitDelivery = async (params) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await salesOrderApi.packShipments({
      params: {
        id: this.props.salesOrderData.id,
        logistic_provider: {...params},
      },
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      toast.success(`Bạn đã gửi thông tin đóng hàng cho đơn ${this.props.salesOrderData?.code} thành công.`);
      this.props.onSubmitSuccess();
      this.onClose();
    });
  }

  render() {
    const { isOpen, salesOrder } = this.props;
    const { isLoading } = this.state;
    const amountGoods = Number((salesOrder?.amount || 0) + (salesOrder?.amount_vat || 0) - (salesOrder?.amount_discount || 0) - (salesOrder?.amount_paid || 0));
    const amountFee = Number((salesOrder?.amount_delivery || 0) - (salesOrder?.amount_shipping_coupon || 0));

    return (
      <Dialog
        open={isOpen}
        onEnter={this.onEnter}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle className={classes.DialogTitle}>Thông tin đóng hàng</DialogTitle>
          <DialogContent className={classes.DialogContent}>
            <DeliverySentForm
              formRef={ref => {
                this.innerFormRef = ref;
              }}
              salesOrder={{...this.props.salesOrder}}
              amountGoods={amountGoods}
              deliveryFeeTotal={amountFee}
              customerCode={this.props.salesOrder?.customer_code}
              customerDelivery={{...this.props.salesOrderDelivery}}
              salesOrderLogistic={{...this.props.salesOrderLogistic}}
              onSubmitDelivery={this.handleSubmitDelivery}
            />
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button type="button" variant="outlined" onClick={this.props.onClose}>
              Huỷ bỏ
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={this.handleSaveDelivery}
              color="primary"
              autoFocus
            >
              Xác nhận
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
  salesOrderDelivery: makeSelectSalesOrderDelivery(),
  salesOrderLogistic: makeSelectSalesOrderLogistic(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    saveSalesOrderAllInfo: (payload) => dispatch(saveSalesOrderAllInfo(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PackingFormDialog);
