import React from 'react';
import { withStyles  } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = (theme) => {
  return {
    root: {
      height: 8,
      borderRadius: 50,
      maxWidth: '100%',
    },
    colorPrimary: {
      backgroundColor: '#A0B9D2',
    },
    bar: {
      borderRadius: 4,
      backgroundColor: theme.palette.success.main,
    },
  }
};

export default withStyles(styles)(
  React.forwardRef( ({ classes, innerRef, color, ...other }, ref) =>
    <LinearProgress ref={ref} classes={classes} {...other} />
  )
);