import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { validDate, currencyFormatVN } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';

import gridClasses from '../SalesOrderListGrid.module.scss';
import classes from './SalesOrderListItem.module.scss';

class SalesOrderListItem extends PureComponent {

  goToDetails = (e) => {
    this.props.history.push(`/so/${this.props.id}`);
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      id,
      ip_address,
      account_name,
      account_code,
      created_at,
      account_phone,
      amount,
      order_info,
      bank_code,
      bank_no,
      command,
    } = this.props;

    return <Link
      to={`/so/${id}`}
      className={`${classes.Item} ${gridClasses.Row}`}
    >
      <div className={gridClasses.Col}> { validDate(created_at) ? format(validDate(created_at), dateFormat) : '' }</div>
      <div className={gridClasses.Col}>{account_code ? account_code: '' }</div>
      <div className={gridClasses.Col}>{account_name ? account_name: '' }</div>
      <div className={`${gridClasses.Col}`}>{account_phone}</div>
      <div className={`${gridClasses.Col}`}>{ip_address}</div>
      <div className={gridClasses.Col}>{ bank_code }</div>
      <div className={gridClasses.Col}>{ bank_no }</div>
      <div className={gridClasses.Col}>{ command }</div>
      <div className={`${gridClasses.Col}`}>{ ['', null, undefined].includes(amount) ? '' : currencyFormatVN(amount) }</div>
      <div className={gridClasses.Col}>{ order_info }</div>
    </Link>;
  }
}

export default React.forwardRef((props, ref) => {
  return <SalesOrderListItem {...props} ref={ref} />;
});
