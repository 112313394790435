import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPurchaseRequestDelivery, makeSelectPurchaseRequest } from 'redux/selectors';

import Panel from 'components/Panel/Panel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import classes from './PurchaseRequestDeliveryInfo.module.scss';

class PurchaseRequestDeliveryInfo extends PureComponent {
  render() {
    const { purchaseRequestDelivery, purchaseRequest } = this.props;

    return (<Panel
      title="Thông tin nhận hàng"
      size="sm"
      panelClassName={classes.Panel}
      content={<div className={classes.Wrap}>
        { purchaseRequestDelivery && (
          <>
            { (purchaseRequestDelivery.name || purchaseRequestDelivery.phone) &&
              <div className={classes.Info}>
                <label>{`${purchaseRequestDelivery.name}${purchaseRequestDelivery.phone ? ' | ' + purchaseRequestDelivery.phone : ''}`}</label>
              </div>
            }
            <div className={classes.Info}>
              <p>{purchaseRequestDelivery.label}</p>
              <p>{purchaseRequestDelivery.address}</p>
              <p>{purchaseRequestDelivery.getcare_ward?.name}</p>
              <p>{purchaseRequestDelivery.getcare_district?.name}</p>
              <p>{purchaseRequestDelivery.getcare_province?.name}</p>
              <p>{`ĐT: ${purchaseRequestDelivery.phone}`}</p>
            </div>
            <div className={`${classes.Info} ${classes.VATOption}`}>
              { purchaseRequest?.is_taxcode ? <CheckBoxIcon fontSize="small" color="secondary"/> : <CheckBoxOutlineBlankIcon fontSize="small" color="secondary"/> }
              <p className={classes.VATInfo}>
                <span>Xuất hoá đơn GTGT</span>
                <span className={classes.TaxInfo}>
                  {purchaseRequest?.is_taxcode && purchaseRequest?.tax_name ? <>Tên công ty: <strong>{ purchaseRequest?.tax_name }</strong></> : ``}
                </span>
                <span className={classes.TaxInfo}>
                  {purchaseRequest?.is_taxcode && purchaseRequest?.taxcode ? <>Mã số thuế: <strong>{ purchaseRequest?.taxcode }</strong></> : ``}
                </span>
                <span className={classes.TaxInfo}>
                  {purchaseRequest?.is_taxcode && purchaseRequest?.tax_address ? <>Địa chỉ: <strong>{ purchaseRequest?.tax_address }</strong></> : ``}
                </span>
              </p>
            </div>
          </>)
        }
      </div>}
      isBorder
    />)
  }
}

PurchaseRequestDeliveryInfo.propTypes = {
  purchaseRequestDelivery: PropTypes.object,
  purchaseRequest: PropTypes.object,
};
PurchaseRequestDeliveryInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  purchaseRequestDelivery: makeSelectPurchaseRequestDelivery(),
  purchaseRequest: makeSelectPurchaseRequest(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PurchaseRequestDeliveryInfo);
