import {
  SAVE_PROVINCE_LIST,
  SAVE_DISTRICT_LIST,
  SAVE_WARD_LIST,
} from 'redux/constants/locationConstants';

const initialState = {
  provinceList: [],
  districtList: [],
  wardList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PROVINCE_LIST: {
      return {
        ...state,
        provinceList: action.provinceList,
      };
    }
    case SAVE_DISTRICT_LIST: {
      return {
        ...state,
        districtList: action.districtList,
      };
    }
    case SAVE_WARD_LIST: {
      return {
        ...state,
        wardList: action.wardList,
      };
    }
    default:
      return state;
  }
}

export default reducer;
