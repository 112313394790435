export const GET_SALES_ORDER_STATUSES = 'getcare/oms/so/GET_SALES_ORDER_STATUSES';

export const GET_SALES_ORDER_LIST = 'getcare/oms/so/GET_SALES_ORDER_LIST';
export const GET_SALES_ORDER = 'getcare/oms/so/GET_SALES_ORDER';
export const GET_SALES_TRANSITION_LIST = 'getcare/oms/so/GET_SALES_TRANSITION_LIST';


export const UPDATE_LOGISTIC_PROVIDER = 'getcare/oms/so/UPDATE_LOGISTIC_PROVIDER';
export const UPDATE_DELIVERY_INFO= 'getcare/oms/so/UPDATE_DELIVERY_INFO';
export const CONFIRM_PURCHASING = 'getcare/oms/so/CONFIRM_PURCHASING';
export const CONFIRM_SUCCESS = 'getcare/oms/so/CONFIRM_SUCCESS';
export const CONFIRM_SUCCESS_DELIVERY = 'getcare/oms/so/CONFIRM_SUCCESS_DELIVERY';
export const PRINT_DELIVERY_PAPER = 'getcare/oms/so/PRINT_DELIVERY_PAPER';
export const RETURN_DELIVERY = 'getcare/oms/so/RETURN_DELIVERY';
export const CONFIRM_SHIP_FROM_VENDOR_SUCCESS = 'getcare/oms/so/CONFIRM_SHIP_FROM_VENDOR_SUCCESS';

export const GET_SALES_ORDER_HISTORY = 'getcare/oms/so/GET_SALES_ORDER_HISTORY';

// mutation
export const SAVE_SALES_ORDER_STATUSES = 'getcare/oms/so/SAVE_SALES_ORDER_STATUSES';

export const SAVE_LOADING = 'getcare/oms/so/SAVE_LOADING';

export const SAVE_SALES_ORDER_LIST = 'getcare/oms/so/SAVE_SALES_ORDER_LIST';
export const SAVE_SALES_TRANSACTION_LIST = 'getcare/oms/so/SAVE_SALES_TRANSACTION_LIST';


export const SAVE_SALES_ORDER_LIST_TOTAL = 'getcare/oms/so/SAVE_SALES_ORDER_LIST_TOTAL';
export const SAVE_SALES_TRANSACTION_LIST_TOTAL = 'getcare/oms/so/SAVE_SALES_TRANSACTION_LIST_TOTAL';

export const SAVE_SALES_ORDER = 'getcare/oms/so/SAVE_SALES_ORDER';
export const SAVE_SALES_ORDER_PRODUCTS = 'getcare/oms/so/SAVE_SALES_ORDER_PRODUCTS';
export const SAVE_SALES_ORDER_PRODUCT_ITEM = 'getcare/oms/so/SAVE_SALES_ORDER_PRODUCT_ITEM';
export const SAVE_SALES_ORDER_BONUSES = 'getcare/oms/so/SAVE_SALES_ORDER_BONUSES';
export const SAVE_SALES_ORDER_DISCOUNTS = 'getcare/oms/so/SAVE_SALES_ORDER_DISCOUNTS';

export const SAVE_SALES_ORDER_NOTES = 'getcare/oms/so/SAVE_SALES_ORDER_NOTES';
export const SAVE_SALES_ORDER_NOTE_ITEM = 'getcare/oms/so/SAVE_SALES_ORDER_NOTE_ITEM';
export const REMOVE_SALES_ORDER_NOTE_ITEM = 'getcare/oms/so/REMOVE_SALES_ORDER_NOTE_ITEM';

export const SAVE_SALES_ORDER_LOGISTIC = 'getcare/oms/so/SAVE_SALES_ORDER_LOGISTIC';
export const SAVE_SALES_ORDER_SALES_USER = 'getcare/oms/so/SAVE_SALES_ORDER_SALES_USER';
export const SAVE_SALES_ORDER_DELIVERY = 'getcare/oms/so/SAVE_SALES_ORDER_DELIVERY';

export const SAVE_SALES_ORDER_ALL_INFO = 'getcare/oms/so/SAVE_SALES_ORDER_ALL_INFO';

export const SAVE_SALES_ORDER_HISTORY = 'getcare/oms/so/SAVE_SALES_ORDER_HISTORY';
