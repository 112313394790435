import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { validNumber, currencyFormatVN, getFullAddressStr } from 'utils/helper';

import Button from '@material-ui/core/Button';

import gridClasses from '../CustomerListGrid.module.scss';
import classes from './CustomerListItem.module.scss';

class CustomerListItem extends PureComponent {
  stopPropagation = (e) => {
    e.stopPropagation();
  };
  handleCreateShipment = () => {
    this.props.onCreateShipment({ shipmentCustomerId: this.props.id });
  }

  render() {
    const {
      index,
      name,
      phone,
      delivery_address,
      delivery_ward_name,
      delivery_district_name,
      delivery_province_name,
      number_order,
      order_price,
    } = this.props;

    return <div className={`${classes.Item} ${gridClasses.Row}`}>
      <div className={`${gridClasses.Col} TextCenter`}>{ index + 1 }</div>
      <div className={gridClasses.Col}>
        <p>{ name }</p>
        { phone && <p className={classes.CustomerCode}>{ phone }</p> }
      </div>
      <div className={`${gridClasses.Col}`}>
        { getFullAddressStr(delivery_address, { name: delivery_ward_name }, { name: delivery_district_name }, { name: delivery_province_name }) }
      </div>
      <div className={`${gridClasses.Col} TextCenter`}>{ number_order }</div>
      <div className={`${gridClasses.Col} TextRight`}>
        { validNumber(order_price) ? currencyFormatVN(order_price) : `-` }
      </div>
      <div className={`${classes.Actions} ${gridClasses.Col} ${gridClasses.ActionsCol}`}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={this.handleCreateShipment}
        >Gửi giao hàng</Button>
      </div>
    </div>;
  }
}

CustomerListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onCreateShipment: PropTypes.func,
};

CustomerListItem.defaultProps = {
};

export default CustomerListItem;
