import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce, isEqual } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';

import { createStructuredSelector } from 'reselect';
import {
  
} from 'redux/selectors';
import { matchingKeyword } from 'utils/helper';

import donateProductApi from 'utils/api/ecom/serviceRequest/connectSupport/donate/productApi';

import {
  Typography,
  TextField,
  InputAdornment,
  Toolbar,
  TableContainer,
  IconButton,
  Box,
  Tooltip,
  Popper,
} from '@material-ui/core';
import { 
  ToggleButtonGroup,
  ToggleButton,
} from '@material-ui/lab';

import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom'
import StyledTableContainer from 'components/Styled/TableContainer/TableContainer';
import StyledDataTable from 'components/Styled/DataTable';

import charityProjectStyles from '../CharityProject.module.scss';
import styles from './DonateProductList.module.scss'

// const QuantityNumberInput = ({ ...params }) => {
//   const [value, setValue] = React.useState(params.value)

//   const onChange = e => {
//     setValue(e.target.value)
//   }

//   // We'll only update the external data when the input is blurred
//   const onBlur = () => {
//     updateMyData(index, id, value)
//   }

//   // If the initialValue is changed external, sync it up with our state
//   React.useEffect(() => {
//     setValue(initialValue)
//   }, [initialValue])

//   return <TextField
//     key={`row_${0}_cell_${0}`}
//     autoComplete="off"
//     variant="outlined"
//     placeholder="Nhập số lượng điều phối"
//     value={value}
//     name="_quantity_number"
//     className={styles.textFieldTable}
//     InputProps={{
//       inputComponent: NumberFormatCustom,
//     }}
//     onChange={ (e) => {
//       console.log(others)
//       const data = this.state.dataTable.data;
//       const rowIndex = value.index;
//       // if ( data[index] ) {
//       //   // data[index] = {
//       //   //   ...data[index],
//       //   //   _quantity_number: Number(e.target.value)
//       //   // }
//       //   data[index]._quantity_number = Number(e.target.value);
//       // }
//       this.updateDataTableState({ data: data.map((row,index)=> {
//         if (index === rowIndex) {
//           return {
//             ...data[rowIndex],
//             _quantity_number: Number(e.target.value),
//           }
//         }
//         return row
//       })})
//     }}
//     size="small"
//   />
// }

class DonateProduct extends PureComponent {
  constructor(props) {
    super(props);
    const pageOptions = [50,100,150];
    this.state = {
      dataTable: {
        initialState: {
          pageIndex: 0,
          pageSize: pageOptions[0],
          sortBy: [{ id: 'quantity_number_assign', desc: false }]
        },
        data: [],
        loading: false,
        pageOptions: pageOptions,
      },
      toggleQuantityNumber: 0,
      filterAnchorEl: null,
      maxTotalCount: 500,
      totalCount: 0,
    };
    this.source = null;
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps,prevState) {
    if ( !isEqual(prevProps.selectedProjectServiceRequestItemId, this.props.selectedProjectServiceRequestItemId) ) {
      this.updateDataTableState({ loading: true });
      this.debounceFetchData();
    }
    if ( !isEqual(prevState.dataTable.data, this.state.dataTable.data) ) {
      this.props.onChangeSupportProductList && this.props.onChangeSupportProductList(this.state.dataTable.data)
    }
  }
  
  
  updateDataTableState = (params = {}) => {
    this.setState((state) => ({
      dataTable: {
        ...state.dataTable,
        ...params
      }
    }))
  }
  updateDataParamsState = (params = {}) => {
    this.setState((state) => ({
      dataParams: {
        ...state.dataParams,
        ...params
      }
    }))
  }
  fetchData = async () => {
    this.updateDataTableState({ loading: true, data: [] });
    this.setState({ totalCount: 0 })
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    const { data: response } = await this.loadData({ cancelToken: this.source.token });
    const data = response.data ? response.data.map( item => ({
      ...item,
      _quantity_number: item.quantity_number_assign || 0
    })) : [];
    this.updateDataTableState({ 
      data: data || [], 
      loading: false 
    });
    this.setState({ totalCount: response.total_records || 0})
  }
  debounceFetchData = debounce(this.fetchData,250)
  loadData = async ({ cancelToken }) => {
    return await donateProductApi.getDonateProductList({
      params: {
        page: 1,
        page_size: this.state.maxTotalCount,
        item_id: this.props.selectedProjectServiceRequestItemId,
        project_id: this.props.selectedProjectId,
      },
      cancelToken,
    });
  }
  renderQuantityNumberInput = ({ value }) => {
    return (
      <TextField
        contentEditable
        suppressContentEditableWarning
        key={value.index}
        autoComplete="off"
        variant="outlined"
        placeholder="Nhập số lượng điều phối"
        value={value.row._quantity_number}
        name="_quantity_number"
        className={styles.textFieldTable}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        onChange={ (e) => {
          const data = [...this.state.dataTable.data];
          const rowIndex = value.index;
          if ( data[rowIndex] ) {
            data[rowIndex] = {
              ...data[rowIndex],
              _quantity_number: Number(e.target.value)
            }
            this.updateDataTableState({ data });
          }
        }}
        size="small"
      />
    );
  };
  
  render() {
    const { className } = this.props;
    const columns = [
      {
        Header: 'Người tặng',
        id: 'user_name',
        accessor: 'user_name',
        sortInverted: true,
      },
      {
        Header: 'Sản phẩm',
        id: 'name',
        accessor: 'name',
      },
      {
        Header: 'Số lượng đóng góp',
        id: 'quantity_number',
        accessor: 'quantity_number',
      },
      {
        Header: 'Giá bán (Nếu có)',
        id: 'price_sales',
        accessor: 'price_sales',
      },
      {
        Header: 'Số lượng đã điều phối',
        id: 'quantity_number_assign',
        sortDescFirst: true,
        accessor: 'quantity_number_assign',
      },
      {
        Header: 'Điều phối số lượng',
        id: '_quantity_number',
        disableSortBy: true,
        accessor: (row,index) => ({ index, row: row }),
        columnProps: {
          align: 'right'
        },
        Cell: this.renderQuantityNumberInput
      },
    ];
    return (
      <div className={className}>
        <Typography variant="h6">Danh sách sản phẩm đóng góp</Typography>
        <Typography gutterBottom variant="subtitle1"><small><b>Lưu ý:</b> Chỉ xác nhận tối đa {this.state.maxTotalCount} sản phẩm cho mỗi lần</small></Typography>
        <StyledTableContainer className={charityProjectStyles.dataTable}>
          <StyledDataTable
            {...this.state.dataTable}
            defaultCanSort
            data={this.state.dataTable.data.filter((item) => {
              if ( this.state.toggleQuantityNumber > 0 ) return item.quantity_number_assign > 0;
              if ( this.state.toggleQuantityNumber < 0 ) return item.quantity_number_assign  === 0;
              return true
            })}
            getRowProps={{ hover: true }}
            columns={columns}
            tableProps={{ stickyHeader: true }}
            componentBeforeTable={(props) => {
              return (
                <Toolbar>
                  <TextField
                    label="Tìm kiếm"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Tìm kiếm..."
                    value={props.globalFilter}
                    name="keyword"
                    onChange={ (e) => props.setGlobalFilter(e.target.value)}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box flexGrow={1}/>
                  <Tooltip placement="top" title="Lọc số lượng điều phối">
                    <ToggleButtonGroup
                      value={this.state.toggleQuantityNumber}
                      exclusive
                      size="small"
                      onChange={(e,value) => this.setState({ toggleQuantityNumber: value })}
                      aria-label="text alignment"
                    >
                      <ToggleButton value={0} aria-label="left aligned">
                        Tất cả
                      </ToggleButton>
                      <ToggleButton value={1} aria-label="centered">
                        Số lượng đã điều phối
                      </ToggleButton>
                      <ToggleButton value={-1} aria-label="right aligned">
                        Số lượng chưa điều phối
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Tooltip>
                </Toolbar>
              )
            }}
            tableWrapper={{
              component: TableContainer,
              props: {
                style: {
                  maxHeicght: '588px'
                }
              }
            }}
          />
        </StyledTableContainer>
        <Popper
          anchorEl={this.state.filterAnchorEl} 
          open={!!this.state.filterAnchorEl}
          placement="bottom-end"
          disablePortal={true}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
        >
          hello
        </Popper>
      </div>
    );
  }
}

DonateProduct.propTypes = {
  selectedProjectId: PropTypes.number,
  selectedProjectServiceRequestItemId: PropTypes.number,
  onSelectServiceRequestItemId: PropTypes.func,
  onChangeSupportProductList: PropTypes.func,
  className: PropTypes.string,
};

DonateProduct.defaultProps = {
  selectedProjectId: null,
  selectedProjectServiceRequestItemId: null,
  className: ''
};

export default DonateProduct;
