import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getSalesOrder,
  saveSalesOrderAllInfo,
  confirmPurchasing,
  confirmSuccess,
  printDeliveryPaper,
  confirmSuccessDelivery,
  getSalesOrderHistory,
  returnDelivery,
  updateDeliveryInfo,
  confirmShipFromVendorSuccess,
} from 'redux/actions/oms/salesOrderActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectSalesOrder,
  makeSelectDeliveryLogisticList,
  makeSelectSalesOrderLoading,
  makeSelectSalesOrderLogistic,
  makeSelectSalesOrderProducts,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import {
  SEND_DELIVERY,
  PRINT_PURCHASE_DELIVERY,
  SUCCESS_DELIVERY,
  DONE,
  CANCEL,
  CONFIRM,
  APPROVE_PURCHASING,
  RETURN_DELIVERY,
  SHIP_FROM_VENDOR,
  getActionItem,
} from 'utils/constanst/omsSalesOrderConstants';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@material-ui/icons';
import SalesOrderInfo from 'components/so/SalesOrderInfo/SalesOrderInfo';
import SalesOrderProducts from 'components/so/SalesOrderProducts/SalesOrderProducts';
import SalesOrderBonuses from 'components/so/SalesOrderBonuses/SalesOrderBonuses';
import SalesOrderLogisticProvider from 'components/so/SalesOrderLogisticProvider/SalesOrderLogisticProvider';
import SalesOrderNotes from 'components/so/SalesOrderNotes/SalesOrderNotes';
import SalesOrderSalesInfo from 'components/so/SalesOrderSalesInfo/SalesOrderSalesInfo';
import SalesOrderDeliveryInfo from 'components/so/SalesOrderDeliveryInfo/SalesOrderDeliveryInfo';
import SalesOrderAmountInfo from 'components/so/SalesOrderAmountInfo/SalesOrderAmountInfo';
import SalesOrderPaymentInfo from 'components/so/SalesOrderPaymentInfo/SalesOrderPaymentInfo';
import SalesOrderCancelFormDialog from 'components/so/SalesOrderCancelFormDialog/SalesOrderCancelFormDialog';
import SalesOrderDeliverySentDialog from 'components/so/SalesOrderDeliverySentDialog/SalesOrderDeliverySentDialog';
import SalesOrderHistory from 'components/so/SalesOrderHistory/SalesOrderHistory';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import SalesOrderApproveDialog from 'components/so/SalesOrderApproveDialog/SalesOrderApproveDialog';

import classes from './SalesOrder.module.scss';

class SalesOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nextSalesOrderId: null,
      openSalesOrderCancelDialog: false,
      isDeliverySentDialogOpen: false,
      openReturnDeliveryDialog: false,
      openConfirmVendorDeliveryDialog: false,
      isApproveDialogOpen: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
  }
  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id && id !== prevProps.match.params.id) {
      this._loadData(id);
    }
    const { salesOrder } = this.props;
    if (
      salesOrder
      && prevProps.salesOrder
      && salesOrder.id === prevProps.salesOrder.id
      && salesOrder.getcare_order_status?.id !== prevProps.salesOrder.getcare_order_status?.id
    ) {
      this._loadSalesOrderHistory();
    }
  }

  _loadData = (id) => {
    this.props.getSalesOrder(id);
  }
  _backToList = () => {
    this.props.history.push('/so');
  }
  _goToNextSalesOrder = () => {
    this.props.history.push(`/so/${this.state.nextSalesOrderId}`);
  }
  _goToAfterItem = () => {
    this.props.history.push(`/so/${this.props.salesOrder?.next_code}`);
  }
  _goToBeforeItem = () => {
    this.props.history.push(`/so/${this.props.salesOrder?.prev_code}`);
  }
  _loadSalesOrderHistory = () => {
    this.props.getSalesOrderHistory({
      params: { getcare_order_id: this.props.salesOrder?.id },
    });
  }
  _prepareRemovedProductsData = () => {
    const willRemoveProducts = this.props.salesOrderProducts.filter(
      item => !!item.willRemove
    );
    return willRemoveProducts.length > 0
      ? willRemoveProducts.map(item => ({
          id: item.id,
          getcare_product_id: item.getcare_product_id,
          getcare_vendor_id: item.getcare_vendor_id,
          getcare_uom_base_id: item.getcare_uom_base_id,
          quantity_number: 0,
          available_number: item.available_number,
        }))
      : undefined;
  }

  handleOpenCancelDialog = () => {
    this.setState({
      openSalesOrderCancelDialog: true,
    });
  }
  handleCloseCancelDialog = () => {
    this.setState({
      openSalesOrderCancelDialog: false,
    });
  }
  handleSaveSalesOrderAllInfo = (newSalesOrderData) => {
    this.props.saveSalesOrderAllInfo(newSalesOrderData);
    this._loadSalesOrderHistory();
  }
  handleConfirmPurchasing = () => {
    this.props.confirmPurchasing({
      params: {
        id: this.props.salesOrder?.id,
        order_items: this._prepareRemovedProductsData(),
      }
    });
  }
  handleConfirmSuccessDelivery = () => {
    this.props.confirmSuccessDelivery({
      params: {
        id: this.props.salesOrder?.id,
      }
    });
  }

  handleConfirmSuccess = () => {
    this.props.confirmSuccess({
      params: {
        id: this.props.salesOrder?.id,
      }
    });
  }
  handlePrintDeliveryPaper = () => {
    this.props.printDeliveryPaper({
      params: {
        id: this.props.salesOrder?.id,
      }
    });
  }

  handleOpenDeliverySentDialog = () => {
    this.setState({
      isDeliverySentDialogOpen: true,
    });
  }
  handleCloseDeliverySentDialog = () => {
    this.setState({
      isDeliverySentDialogOpen: false,
    });
  }

  handleOpenReturnDeliveryDialog = () => {
    this.setState({
      openReturnDeliveryDialog: true,
    });
  }
  handleCloseReturnDeliveryDialog = () => {
    this.setState({
      openReturnDeliveryDialog: false,
    });
  }
  handleSubmitReturnDelivery = () => {
    this.props.returnDelivery({
      params: {
        id: this.props.salesOrder?.id,
      }
    });
    this.handleCloseReturnDeliveryDialog();
  }
  handleSubmitVendorDelivery = async () => {
    await this.props.confirmShipFromVendorSuccess({ params: {
      id: this.props.salesOrder.id
    }});
    this.handleConfirmVendorDeliveryDialog(false);
  }

  handleOpenApproveDialog = () => {
    this.setState({
      isApproveDialogOpen: true,
    });
  }
  handleCloseApproveDialog = () => {
    this.setState({
      isApproveDialogOpen: false,
    });
  }
  handleConfirmVendorDeliveryDialog = (active = false) => {
    this.setState({
      openConfirmVendorDeliveryDialog: active,
    });
  }

  render() {
    const { salesOrder, deliveryLogisticList, loading } = this.props;
    const {idOder} = this.props.match.params;
    const statusId = salesOrder?.getcare_order_status?.id;
    const actions = salesOrder?.actions;

    return (<>
      <div className={`${classes.PageWrap} ${loading ? 'OverlayLoading' : ''}`}>
        <div className={classes.PageHeader}>
          <IconButton disabled={!salesOrder?.prev_code} size="small" onClick={this._goToBeforeItem}>
            <ArrowBackIosOutlined fontSize="small"/>
          </IconButton>
          <IconButton disabled={!salesOrder?.next_code} size="small" onClick={this._goToAfterItem}>
            <ArrowForwardIosOutlined fontSize="small"/>
          </IconButton>
          <h1 className={classes.PageTitle}>
            Chi tiết đơn hàng
            { salesOrder?.is_taxcode && (
              <Tooltip title={`Xuất hoá đơn GTGT`} arrow placement="top">
                <LocalOfferIcon className={classes.InvoiceIcon} color="secondary"/>
              </Tooltip>
            ) }
          </h1>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={this._backToList}
          >Trở về</Button>
          { getActionItem(CANCEL, actions) &&
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.handleOpenCancelDialog}
            >{getActionItem(CANCEL, actions).name}</Button>
          }
          { getActionItem(SHIP_FROM_VENDOR, actions) &&
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.handleConfirmVendorDeliveryDialog(true)}
            >{getActionItem(SHIP_FROM_VENDOR, actions).name}</Button>
          }
          { getActionItem(RETURN_DELIVERY, actions) &&
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleOpenReturnDeliveryDialog}
            >{getActionItem(RETURN_DELIVERY, actions).name}</Button>
          }
          { getActionItem(APPROVE_PURCHASING, actions) &&
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleOpenApproveDialog}
            >{getActionItem(APPROVE_PURCHASING, actions).name}</Button>
          }
          { getActionItem(CONFIRM, actions) &&
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleConfirmPurchasing}
            >{getActionItem(CONFIRM, actions).name}</Button>
          }
          { getActionItem(SEND_DELIVERY, actions) &&
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleOpenDeliverySentDialog}
            >{getActionItem(SEND_DELIVERY, actions).name}</Button>
          }
          {
            getActionItem(PRINT_PURCHASE_DELIVERY, actions) &&
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handlePrintDeliveryPaper}
            >{getActionItem(PRINT_PURCHASE_DELIVERY, actions).name}</Button>
          }
          {
            getActionItem(SUCCESS_DELIVERY, actions) &&
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleConfirmSuccessDelivery}
            >{getActionItem(SUCCESS_DELIVERY, actions).name}</Button>
          }
          { getActionItem(DONE, actions) &&
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleConfirmSuccess}
            >{getActionItem(DONE, actions).name}</Button>
          }
        </div>
        <div className={classes.PageMain}>
          <div className={classes.InfoWrap}>
            <SalesOrderInfo/>
          </div>
          <div className={classes.Content}>
            <SalesOrderProducts
              salesOrderStatusId={statusId}
              onCancelSalesOrder={this.handleOpenCancelDialog}
            />
            <SalesOrderBonuses/>
            <SalesOrderLogisticProvider
              deliveryLogisticList={[...deliveryLogisticList]}
              onOpenDeliverySentDialog={this.handleOpenDeliverySentDialog}
            />
            <SalesOrderPaymentInfo salesOrderCode={salesOrder?.code}  deliveryLogisticList={[...deliveryLogisticList]}
            />
            <SalesOrderNotes salesOrderId={salesOrder?.id}/>
            <SalesOrderHistory salesOrderId={salesOrder?.id}/>
          </div>
          <div className={classes.Side}>
            <SalesOrderSalesInfo onSaveSalesInfoSuccess={this.handleSaveSalesOrderAllInfo}/>
            <SalesOrderDeliveryInfo onSaveDeliveryInfoSuccess={this.handleSaveSalesOrderAllInfo}/>
            <SalesOrderAmountInfo/>
          </div>
        </div>
      </div>

      {this.state.openSalesOrderCancelDialog &&
        <SalesOrderCancelFormDialog
          isOpen={this.state.openSalesOrderCancelDialog}
          salesOrderId={salesOrder?.id}
          onClose={this.handleCloseCancelDialog}
          onCancelSuccess={this.handleSaveSalesOrderAllInfo}
        />
      }

      { this.state.isDeliverySentDialogOpen &&
        <SalesOrderDeliverySentDialog
          salesOrderId={salesOrder?.id}
          salesOrderActions={salesOrder?.actions}
          isOpen={this.state.isDeliverySentDialogOpen}
          onClose={this.handleCloseDeliverySentDialog}
          onSaveDeliverySentSuccess={this.handleSaveSalesOrderAllInfo}
        />
      }

      { this.state.openReturnDeliveryDialog &&
        <ConfirmationDialog
          isOpen={this.state.openReturnDeliveryDialog}
          title={`Xác nhận Đã trả hàng`}
          message={<>
            <p>Đơn hàng <strong>{ salesOrder?.code }</strong> khách hàng không đồng ý mua hoặc không nhận hàng.</p>
            <p>Xác nhận <strong>Đã trả hàng</strong> về hệ thống.</p>
          </>}
          onClose={this.handleCloseReturnDeliveryDialog}
          onSubmit={this.handleSubmitReturnDelivery}
        />
      }
      { this.state.openConfirmVendorDeliveryDialog &&
        <ConfirmationDialog
          isLoading={loading}
          isOpen={this.state.openConfirmVendorDeliveryDialog}
          title={`Xác nhận giao hàng từ vendor`}
          message={<>
            <p>Bạn có chắc muốn giao hàng từ vendor?</p>
          </>}
          onClose={() => this.handleConfirmVendorDeliveryDialog(false)}
          onSubmit={this.handleSubmitVendorDelivery}
        />
      }
  
      { this.state.isApproveDialogOpen &&
        <SalesOrderApproveDialog
          isOpen={this.state.isApproveDialogOpen}
          onClose={this.handleCloseApproveDialog}
          onApproveSuccess={this.handleSaveSalesOrderAllInfo}
        />
      }
    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  loading: makeSelectSalesOrderLoading(),
  salesOrderLogistic: makeSelectSalesOrderLogistic(),
  salesOrderProducts: makeSelectSalesOrderProducts(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrder: (payload) => dispatch(getSalesOrder(payload)),
    confirmPurchasing: (payload) => dispatch(confirmPurchasing(payload)),
    confirmSuccessDelivery: (payload) => dispatch(confirmSuccessDelivery(payload)),
    saveSalesOrderAllInfo: (payload) => dispatch(saveSalesOrderAllInfo(payload)),
    getSalesOrderHistory: (payload) => dispatch(getSalesOrderHistory(payload)),
    confirmSuccess: (payload) => dispatch(confirmSuccess(payload)),
    printDeliveryPaper: (payload) => dispatch(printDeliveryPaper(payload)),
    returnDelivery: (payload) => dispatch(returnDelivery(payload)),
    updateDeliveryInfo: (payload) => dispatch(updateDeliveryInfo(payload)),
    confirmShipFromVendorSuccess: (payload) => dispatch(confirmShipFromVendorSuccess(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesOrder);
