import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PurchaseRequestListItem from './PurchaseRequestListItem/PurchaseRequestListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './PurchaseRequestListGrid.module.scss';
import classes from './PurchaseRequestList.module.scss';

class PurchaseRequestList extends PureComponent {
  render() {
    const { purchaseRequestList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div className={`$${classes.Header}`}>
           <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={`${gridClasses.Col} ${item.className}`}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
            </div>
      <div className={`${classes.Body}`}>
        { !isLoading && purchaseRequestList.length
          ? purchaseRequestList.map(item => (
            <PurchaseRequestListItem
              key={`purchaseRequest-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có đơn đặt hàng nào</p>)
        }
      </div>
    </div>;
  }
}

PurchaseRequestList.propTypes = {
  displayFields: PropTypes.array,
  purchaseRequestList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

PurchaseRequestList.defaultProps = {
  displayFields: [],
  purchaseRequestList: [],
  isLoading: false,
};

export default PurchaseRequestList;
