import React, { PureComponent } from 'react';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import classes from './SalesOrderNoteFormDialog.module.scss';

class SalesOrderNoteFormDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }

  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await salesOrderApi.createOrUpdateSalesOrderNote({
      params: {
        getcare_order_id: this.props.salesOrderId,
        id: this.props.noteItem?.id,
        note: values.noteContent,
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      this.props.onSubmitSuccess(response.data);
      this.props.onClose();
    });
  };
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, noteItem } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>{ noteItem?.id ? 'Sửa ghi chú' : 'Thêm ghi chú'}</DialogTitle>
          <Formik
            initialValues={{
              noteContent: noteItem?.id ? noteItem.note : '',
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validateOnChange={false}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
              noteContent: Yup.string().required("Vui lòng nhập ghi chú").test('len', 'Tối đa 255 ký tự', val => val?.length <= 255),
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleSubmit,
              } = props;
              return (
                <form noValidate autoComplete="off">
                  <DialogContent className={classes.DialogContent}>
                    <div className={`${classes.FieldControl} ${classes.OneColumn}`}>
                      <TextField
                        autoComplete="off"
                        rowsMax={3}
                        multiline
                        value={values.noteContent}
                        placeholder="Nhập ghi chú..."
                        name="noteContent"
                        error={!!errors.noteContent}
                        helperText={errors.noteContent}
                        onChange={handleChange}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      autoFocus
                    >
                      Lưu
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    );
  }
}

export default SalesOrderNoteFormDialog;
