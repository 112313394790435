import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { validNumber, currencyFormatVN, validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';

import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Checkbox from '@material-ui/core/Checkbox';

import classes from './ShipmentCustomerOrders.module.scss';

class ShipmentCustomerOrders extends PureComponent {
  _isAllOrdersSelected = () => {
    const { shipmentCustomerOrders, selectedOrderIds } = this.props;
    return shipmentCustomerOrders.length > 0 && selectedOrderIds.length >= shipmentCustomerOrders.length;
  }
  _isOrderSelected = (id) => {
    return this.props.selectedOrderIds.includes(id);
  }

  render() {
    const { shipmentCustomerOrders } = this.props;

    return (
      <div className={`${classes.Wrap}`}>
        <h4 className={classes.SectionTitle}>Chọn đơn hàng cần giao</h4>
        <div className={`${classes.List}`}>
          <div className={`${classes.Row} ${classes.Header}`}>
            <div className={classes.Col}>
              <Checkbox
                size="small"
                className={classes.Checkbox}
                checked={this._isAllOrdersSelected()}
                onChange={this.props.onAllOrdersSelectedToggle}
              />
            </div>
            <ListHeaderCol className={classes.Col} label="Mã đơn hàng"/>
            <ListHeaderCol className={classes.Col} label="Loại đơn hàng"/>
            <ListHeaderCol className={classes.Col} label="Loại hình VC"/>
            <ListHeaderCol className={classes.Col} label="Trạng thái"/>
            <ListHeaderCol className={classes.Col} label="3PL"/>
            <ListHeaderCol className={`${classes.Col} TextRight`}label="Tổng tiền"/>
            <ListHeaderCol className={`${classes.Col} TextRight`} label="Phí VC"/>
            <ListHeaderCol className={classes.Col} label="Ngày tạo đơn"/>
          </div>
          <div className={`${classes.Body}`}>
            {shipmentCustomerOrders.length ? (
              shipmentCustomerOrders.map((item) => (
                <div key={`order-${item.id}`} className={`${classes.Item} ${classes.Row}`}>
                  <div className={classes.Col}>
                    <Checkbox
                      size="small"
                      className={classes.Checkbox}
                      checked={this._isOrderSelected(item.id)}
                      onChange={(e) => { this.props.onOrderSelectedToggle({ id: item.id, isSelected: e.target.checked }); }}
                    />
                  </div>
                  <div className={classes.Col}>
                    { item.code }
                  </div>
                  <div className={`${classes.Col}`}>
                    { item.getcare_order_type?.name }
                  </div>
                  <div className={`${classes.Col}`}>
                    { item.logistic_provider?.name }
                  </div>
                  <div className={`${classes.Col}`}>
                    { item.getcare_order_status?.name }
                  </div>
                  <div className={classes.Col}>
                    { item.packing_status?.name }
                  </div>
                  <div className={`${classes.Col} TextRight`}>
                    { validNumber(item.amount_total) ? currencyFormatVN(item.amount_total) : `-` }
                  </div>
                  <div className={`${classes.Col} TextRight`}>
                    { validNumber(item.amount_delivery) ? currencyFormatVN(item.amount_delivery) : `-` }
                  </div>
                  <div className={classes.Col}>
                    { validDate(item.created_at) ? format(validDate(item.created_at), dateTimeFormat) : '' }
                  </div>
                </div>
              ))
            ) : (
              <p className="NoData">Không có đơn hàng nào</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ShipmentCustomerOrders.propTypes = {
  shipmentCustomerOrders: PropTypes.array,
  selectedOrderIds: PropTypes.array,
  onOrderSelectedToggle: PropTypes.func,
  onAllOrdersSelectedToggle: PropTypes.func,
};

ShipmentCustomerOrders.defaultProps = {
  selectedOrderIds: [],
  shipmentCustomerOrders: [],
};

export default ShipmentCustomerOrders;
