import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/omsSalesOrderConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getSalesOrderStatuses } from 'redux/actions/oms/salesOrderActions';
import { getSalesChannelList } from 'redux/actions/mdm/salesChannelActions';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderStatuses, makeSelectSalesChannelList } from 'redux/selectors';
import { allInPageSize,deliverMap } from 'utils/constanst/common';
import Checkboxes from 'components/FilterField/Checkboxes';

import FilterField from 'components/FilterField/FilterField';
import PriceRange from 'components/FilterField/PriceRange/PriceRange';
import DateRange from 'components/FilterField/DateRange/DateRange';
import MultiSelect from 'components/FilterField/MultiSelect';

import classes from './SalesOrderListFilters.module.scss';

class SalesOrderListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }
  componentDidMount() {
    this.props.getSalesOrderStatuses();
    this.props.getSalesChannelList({
      params: {
        page: 1,
        page_size: allInPageSize,
      }
    });
  }


  handleFieldChange = ({ name, value }) => {
    console.log(name);
    if ( name === "delivery_by_getcare"){
      console.log(value);
      console.log(this.state.fields.map(field => field.name === name? { ...field, value }: field ));
    }
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    console.log(newFields);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            ['amount_total'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<PriceRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['created_at'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<DateRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['getcare_order_status_id'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                customField={<MultiSelect
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  options={this.props.salesOrderStatuses}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['getcare_sales_channel_id'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                customField={<MultiSelect
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  options={this.props.salesChannelList}
                  optionLabelName="code"
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
              ) : ['delivery_by_getcare'].includes(field.name) ? (
                <FilterField
                key={field.name}
                {...field}
                defaultValue={['delivery_by_getcare']}
                customField={<Checkboxes
                  name={field.name}
                  value={field.value}
                  type="boolean"
                  multiple
                  valuesMap={deliverMap}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
              ) : 
  
            (<FilterField
                key={field.name}
                {...field}
                handleFieldChange={this.handleFieldChange}
              />)
          ))}
        </div>
      </div>
    );
  }
}

SalesOrderListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  salesOrderStatuses: makeSelectSalesOrderStatuses(),
  salesChannelList: makeSelectSalesChannelList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrderStatuses: (payload) => dispatch(getSalesOrderStatuses(payload)),
    getSalesChannelList: (payload) => dispatch(getSalesChannelList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesOrderListFilters);
