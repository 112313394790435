import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPurchaseRequest } from 'redux/selectors';

import Panel from 'components/Panel/Panel';

import classes from './PurchaseRequestSalesInfo.module.scss';

class PurchaseRequestSalesInfo extends PureComponent {
  render() {
    const { purchaseRequest } = this.props;

    return (<Panel
      title="Phụ trách bởi"
      size="sm"
      panelClassName={classes.Panel}
      content={<div className={classes.Wrap}>
        { purchaseRequest && purchaseRequest?.sales_name && (
          <>
            <div className={classes.Info}>
              <label>Trình Dược Viên</label>
              {purchaseRequest?.sales_name || `-`}
            </div>
            <div className={classes.Info}>
              <label>Số điện thoại</label>
              {purchaseRequest?.sales_phone || `-`}
            </div>
            { purchaseRequest?.sales_note &&
              (<div className={`${classes.Info} ${classes.Note}`}>
                <div className={classes.NoteContent}>{purchaseRequest.sales_note}</div>
              </div>)
            }
          </>)
        }
      </div>}
      isBorder
    />)
  }
}

PurchaseRequestSalesInfo.propTypes = {
  purchaseRequest: PropTypes.object,
};
PurchaseRequestSalesInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  purchaseRequest: makeSelectPurchaseRequest(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(PurchaseRequestSalesInfo);
