const styles = (theme) => {
  return {
    tablePaginationToolbar: {
      '@media only screen and (max-width: 768px)': {
        padding: theme.spacing(1,2),
        flexWrap: 'wrap',
        justifyContent: 'center',
      }
    },
    paginationUl: {
      flexWrap: 'nowrap',
      padding: theme.spacing(0,2),
      '@media only screen and (max-width: 768px)': {
        flexWrap: 'wrap',
        justifyContent: 'center',
      }
    },
    
    styledProgressRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '3rem 1rem',
      gap: '.5rem',
    },
    styledProgressCircleBottom: {
      // color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      color: '#EEE'
    },
    styledProgressCircleTop: {
      color: '#1a90ff',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    styledProgressCircle: {
      strokeLinecap: 'round',
    },
    styledProgressIcon: {
      position: 'relative',
    },
    styledProgressContent: {
      fontSize: '1rem'
    }
    // tablePaginationToolbar: {
    //   // flexWrap: 'wrap',
    //   // justifyContent: 'flex-end',
    //   '@media only screen and (max-width: 768px)': {
    //     justifyContent: 'center',
    //   }
    // },
    // tablePaginationRoot: {
    //   border: 'none',
    // },
    // tablePaginationSpacer: {
    //   flex: '0 0',
    // },
    // tablePaginationInput: {
    //   margin: '0 .25rem'
    // },  
  }
};

export default styles;