import React, { PureComponent } from 'react';
import classes from './PermissionDenied.module.scss';

class PermissionDenied extends PureComponent {
  render() {
    return (
      <div className={`${classes.PermissionDenied}`}>
        <div>
          <h2 className={classes.Heading}>Bạn không có quyền vào trang này</h2>
        </div>
      </div>
    );
  }
}

export default PermissionDenied;
