import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { filterFields } from 'utils/constanst/omsCustomerConstants';

import FilterField from 'components/FilterField/FilterField';
import PriceRange from 'components/FilterField/PriceRange/PriceRange';

import classes from './CustomerListFilters.module.scss';

class CustomerListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            ['order_price'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<PriceRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : (<FilterField
                key={field.name}
                {...field}
                handleFieldChange={this.handleFieldChange}
              />)
          ))}
        </div>
      </div>
    );
  }
}

CustomerListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default CustomerListFilters;
