import React, { PureComponent } from 'react';
import { isEqual } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';

import supportProjectApi from 'utils/api/ecom/serviceRequest/connectSupport/support/projectApi';

import {
  TableContainer,
  Typography,
  Toolbar,
  TextField,
  InputAdornment,
  Radio,
  withStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import StyledTableContainer from 'components/Styled/TableContainer/TableContainer';
import StyledDataTable from 'components/Styled/DataTable';

import charityProjectStyles from '../charityProjectStyles';

class ProjectList extends PureComponent {
  constructor(props) {
    super(props);
    const pageSizeOptions = [50,100,150];
    this.state = {
      dataTableProps: {
        initialState: {
          pageIndex: 0,
          pageSize: pageSizeOptions[0],
          globalFilter: {
            keyword: '',
          }
        },
        pageOptions: pageSizeOptions,
      },
      projectList: [],
      projectListCount: 0,
      projectListPageCount: 0,
      loadingProjectList: false,
    };
    this.source = null;
  }
  componentDidUpdate(prevProps,prevState) {
    if ( !isEqual(prevState.projectList, this.state.projectList) && this.state.projectList[0] ) {
      this.props.onGetFirstData && this.props.onGetFirstData(this.state.projectList[0])
    }
  }

  fetchData = async ({ pageSize, pageIndex, sortBy, filter }) => {
    // console.log("sortBy",sortBy)
    this.setState({ loadingProjectList: true });
    this.source && this.source.cancel();
    this.source = axios.CancelToken.source();
    const { data: response } = await this.loadData({ params: {
      page: pageIndex+1,
      page_size: pageSize,
      ...filter,
    }, cancelToken: this.source.token });
    const totalCount = response.total_records || 0;
    this.setState((state) => ({ 
      projectList: response.data || [], 
      projectListCount: totalCount,
      projectListPageCount: Math.ceil(totalCount/pageSize),
      loadingProjectList: false 
    }));
  }
  loadData = async ({ params, cancelToken }) => {
    return await supportProjectApi.getList({
      params: {
        ...params,
      },
      cancelToken,
    });
  }

  render() {
    const { className } = this.props;
    const columns = [
      {
        Header: '',
        accessor: 'id',
        Cell: rowData => {
          return <Radio
            checked={rowData.value === this.props.selectedProjectId}
            onChange={() => this.props.onSelectProject && this.props.onSelectProject(rowData.value)}
            value={rowData.value}
            name="project_id"
            color="primary"
          />
        },
        cellProps: {
          padding: 'checkbox'
        }
        // cellClassName: classes.tableCellRadio
      },
      {
        Header: 'Tên dự án',
        accessor: 'name',
        id: 'name',
      },
      {
        Header: 'Tiến độ',
        accessor: (originalRow) => originalRow.getcare_service_request_status?.name || '--',
        columnProps: {
          align: 'center'
        },
        id: 'status',
      },
      {
        Header: 'Cần trước ngày',
        id: 'deadline',
        accessor: (originalRow) => moment(originalRow.deadline).format('DD/MM/YYYY'),
        columnProps: {
          align: 'right'
        },
      },
    ]
    return (
      <div className={className}>
        <Typography className="margin__bottom--2" variant="h6">Danh sách dự án cần hỗ trợ</Typography>
        <StyledTableContainer>
          <StyledDataTable
            {...this.state.dataTableProps}
            name="projectList"
            data={this.state.projectList}
            totalCount={this.state.projectListCount}
            pageCount={this.state.projectListPageCount}
            loading={this.state.loadingProjectList}
            manualPagination={true}
            disableSortBy
            manualGlobalFilter
            manualSortBy
            columns={columns}
            onFetchData={this.fetchData}
            getRowProps={(row) => ({
              selected: this.props.selectedProjectId === row.values.id,
              hover: true,
            })}
            tableProps={{ stickyHeader: true }}
            componentBeforeTable={(props) => {
              return (
                <Toolbar>
                  <TextField
                    label="Tìm kiếm"
                    autoComplete="off"
                    variant="outlined"
                    placeholder="Tìm kiếm tên sản phẩm/nhà sản xuất/danh mục/đơn vị"
                    value={props.globalFilter.keyword}
                    name="keyword"
                    onChange={ (e) => props.setGlobalFilter({
                      ...props.globalFilter,
                      keyword: e.target.value,
                    })}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Toolbar>
              )
            }}
            tableWrapper={{
              component: TableContainer,
              props: {
                style: {
                  maxHeight: '588px'
                }
              }
            }}
          />
        </StyledTableContainer>
      </div>
    );
  }
}

ProjectList.propTypes = {
  selectedProjectId: PropTypes.number,
  onGetFirstData: PropTypes.func,
  onSelectProject: PropTypes.func,
  className: PropTypes.string,
};

ProjectList.defaultProps = {
  selectedProjectId: null,
  className: ''
};

export default withStyles(charityProjectStyles)(ProjectList);
