import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getShipment,
  printShipment,
  confirmSuccessDelivery,
  returnShipmentDelivery,
} from 'redux/actions/oms/shipmentActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectShipment,
  makeSelectShipmentLoading,
  makeSelectShipmentLogistic,
  makeSelectShipmentProducts,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import {
  CANCEL,
  PRINT_SHIPMENT,
  SUCCESS_DELIVERY,
  RETURN_DELIVERY,
  getActionItem,
} from 'utils/constanst/omsShipmentConstants';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ShipmentInfo from './ShipmentInfo/ShipmentInfo';
import ShipmentProducts from './ShipmentProducts/ShipmentProducts';
import ShipmentLogisticProvider from './ShipmentLogisticProvider/ShipmentLogisticProvider';
import ShipmentDeliveryInfo from './ShipmentDeliveryInfo/ShipmentDeliveryInfo';
import ShipmentSalesInfo from './ShipmentSalesInfo/ShipmentSalesInfo';
import ShipmentCancelFormDialog from 'components/shipment/ShipmentCancelFormDialog/ShipmentCancelFormDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import classes from './ShipmentDetails.module.scss';

class Shipment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nextShipmentId: null,
      openShipmentCancelDialog: false,
      openReturnDeliveryDialog: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
  }
  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id && id !== prevProps.match.params.id) {
      this._loadData(id);
    }
  }

  _loadData = (id) => {
    this.props.getShipment(id);
  }
  _backToList = () => {
    this.props.history.push('/shipment');
  }
  _goToNextShipment = () => {
    this.props.history.push(`/shipment/${this.state.nextShipmentId}`);
  }
  _goToAfterItem = () => {
    this.props.history.push(`/shipment/${this.props.shipment?.next_code}`);
  }
  _goToBeforeItem = () => {
    this.props.history.push(`/shipment/${this.props.shipment?.prev_code}`);
  }

  handleOpenCancelDialog = () => {
    this.setState({
      openShipmentCancelDialog: true,
    });
  }
  handleCloseCancelDialog = () => {
    this.setState({
      openShipmentCancelDialog: false,
    });
  }
  handleCancelSuccessShipment = (newShipmentData) => {
    this.props.history.push(`/shipment`);
  }

  handlePrintShipmentPaper = () => {
    this.props.printShipment({
      params: {
        id: this.props.shipment?.id,
      }
    });
  }
  handleConfirmSuccessDelivery = () => {
    this.props.confirmSuccessDelivery({
      params: {
        id: this.props.shipment?.id,
      }
    });
  }

  handleOpenReturnDeliveryDialog = () => {
    this.setState({
      openReturnDeliveryDialog: true,
    });
  }
  handleCloseReturnDeliveryDialog = () => {
    this.setState({
      openReturnDeliveryDialog: false,
    });
  }
  handleSubmitReturnDelivery = () => {
    this.props.returnShipmentDelivery({
      params: {
        id: this.props.shipment?.id,
      }
    });
    this.handleCloseReturnDeliveryDialog();
  }

  render() {
    const { shipment, loading } = this.props;
    const actions = shipment?.actions;

    return (<>
      <div className={`${classes.PageWrap} ${loading ? 'OverlayLoading' : ''}`}>
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>
            Chi tiết đơn giao hàng
          </h1>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={this._backToList}
          >Trở về</Button>
          { getActionItem(CANCEL, actions) &&
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.handleOpenCancelDialog}
            >{getActionItem(CANCEL, actions).name}</Button>
          }
          {
            getActionItem(RETURN_DELIVERY, actions) &&
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleOpenReturnDeliveryDialog}
            >{getActionItem(RETURN_DELIVERY, actions).name}</Button>
          }
          {
            getActionItem(PRINT_SHIPMENT, actions) &&
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handlePrintShipmentPaper}
            >{getActionItem(PRINT_SHIPMENT, actions).name}</Button>
          }
          {
            getActionItem(SUCCESS_DELIVERY, actions) &&
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleConfirmSuccessDelivery}
            >{getActionItem(SUCCESS_DELIVERY, actions).name}</Button>
          }
        </div>
        <div className={classes.PageMain}>
          <div className={classes.InfoWrap}>
            <ShipmentInfo/>
          </div>
          <div className={classes.Content}>
            <ShipmentProducts/>
            <ShipmentLogisticProvider/>
          </div>
          <div className={classes.Side}>
            <ShipmentSalesInfo/>
            <ShipmentDeliveryInfo/>
          </div>
        </div>
      </div>

      {this.state.openShipmentCancelDialog &&
        <ShipmentCancelFormDialog
          isOpen={this.state.openShipmentCancelDialog}
          shipmentId={shipment?.id}
          shipmentCode={shipment?.code}
          shipmentOrderCodes={shipment?.getcare_order_codes}
          onClose={this.handleCloseCancelDialog}
          onCancelSuccess={this.handleCancelSuccessShipment}
        />
      }

      {
        this.state.openReturnDeliveryDialog &&
        <ConfirmationDialog
          isOpen={this.state.openReturnDeliveryDialog}
          title={`Xác nhận Đã trả hàng`}
          message={<>
            <p>Đơn giao hàng <strong>{ shipment?.code }</strong> khách hàng không đồng ý mua hoặc không nhận hàng.</p>
            <p>Xác nhận <strong>Đã trả hàng</strong> về hệ thống.</p>
          </>}
          onClose={this.handleCloseReturnDeliveryDialog}
          onSubmit={this.handleSubmitReturnDelivery}
        />
      }

    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  shipment: makeSelectShipment(),
  loading: makeSelectShipmentLoading(),
  shipmentLogistic: makeSelectShipmentLogistic(),
  shipmentProducts: makeSelectShipmentProducts(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getShipment: (payload) => dispatch(getShipment(payload)),
    printShipment: (payload) => dispatch(printShipment(payload)),
    confirmSuccessDelivery: (payload) => dispatch(confirmSuccessDelivery(payload)),
    returnShipmentDelivery: (payload) => dispatch(returnShipmentDelivery(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Shipment);
