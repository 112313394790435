import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getUserTeamList } from 'redux/actions/authActions';
import { getSalesOrderList } from 'redux/actions/oms/salesOrderActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectSalesOrderLoading,
  makeSelectSalesOrderList,
  makeSelectSalesOrderListTotal,
  makeSelectUserTeamList,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/omsSalesOrderConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import SalesOrderList from 'components/so/SalesOrderList/SalesOrderList';
import SalesOrderListFilters from 'components/so/SalesOrderList/SalesOrderListFilters/SalesOrderListFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import SalesOrderListExportDialog from 'components/so/SalesOrderListExportDialog/SalesOrderListExportDialog';

import classes from './SalesOrders.module.scss';

class SalesOrders extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      isExportOpen: false,
    };
  }
  componentDidMount() {
    this.props.getUserTeamList();

    this.props.getSalesOrderList({
      params: this.state.listParams,
    });
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['amount_total'].includes(item.name)) {
        const numArr = item.value.map(val => val.trim() !== '' ? Number(val) : '');
        memo[`${item.name}_from`] = numArr[numArr.length - 1] && numArr[0] ? Math.min.apply(null, [...numArr]) : numArr[0];
        memo[`${item.name}_to`] = numArr[numArr.length - 1] && numArr[0] ? Math.max.apply(null, [...numArr]) : numArr[numArr.length - 1];
      } else if (['created_at'].includes(item.name)) {
        memo[`${item.name}_from`] = item.value[0];
        memo[`${item.name}_to`] = item.value[1];
      } else {
        memo[item.name] = item.value;
      }
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getSalesOrderList({
      params: newParams,
    });
  };

  handleOpenExportDialog = () => {
    this.setState({
      isExportOpen: true,
    });
  }
  handleCloseExportDialog = () => {
    this.setState({
      isExportOpen: false,
    });
  }

  render() {
    const { salesOrderList, salesOrderListTotal, loading, userTeamList } = this.props;
    const { listParams, isExportOpen } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách đơn hàng</h1>
            { userTeamList && userTeamList.length > 0 &&
              <Button
                color="primary"
                variant="contained"
                onClick={this.handleOpenExportDialog}
              >Xuất đơn hàng</Button>
            }
          </div>
          <div className={classes.PageMain}>
            <SalesOrderListFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <SalesOrderList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              salesOrderList={salesOrderList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={salesOrderListTotal}
              listName="đơn hàng"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        { isExportOpen &&
          <SalesOrderListExportDialog
            isOpen={isExportOpen}
            list={userTeamList ? [...userTeamList] : []}
            onClose={this.handleCloseExportDialog}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesOrderList: makeSelectSalesOrderList(),
  salesOrderListTotal: makeSelectSalesOrderListTotal(),
  loading: makeSelectSalesOrderLoading(),
  userTeamList: makeSelectUserTeamList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrderList: (payload) => dispatch(getSalesOrderList(payload)),
    getUserTeamList: () => dispatch(getUserTeamList()),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesOrders);
