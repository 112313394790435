import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import { getCustomerList } from 'redux/actions/oms/customerActions';
import {
  makeSelectCustomerLoading,
  makeSelectCustomerList,
  makeSelectCustomerListTotal,
} from 'redux/selectors';
import {
  listDisplayFields,
  listParamsMap,
} from 'utils/constanst/omsCustomerConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';

import CustomerList from 'components/customer/CustomerList/CustomerList';
import CustomerListFilters from 'components/customer/CustomerList/CustomerListFilters/CustomerListFilters';
import ListPagination from 'components/ListPagination/ListPagination';

import classes from './ShipmentCustomers.module.scss';

class ShipmentCustomers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }
  componentDidMount() {
    this.props.getCustomerList({
      params: this.state.listParams,
    });
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      if (['order_price'].includes(item.name)) {
        const numArr = item.value.map(val => val.trim() !== '' ? Number(val) : '');
        memo[`${item.name}_from`] = numArr[numArr.length - 1] && numArr[0] ? Math.min.apply(null, [...numArr]) : numArr[0];
        memo[`${item.name}_to`] = numArr[numArr.length - 1] && numArr[0] ? Math.max.apply(null, [...numArr]) : numArr[numArr.length - 1];
      } else {
        memo[item.name] = item.value;
      }
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getCustomerList({
      params: newParams,
    });
  };
  handleCreateShipment = ({ shipmentCustomerId }) => {
    this.props.history.push(`/shipment/add?shipment_customer=${shipmentCustomerId}`);
  }

  render() {
    const { customerList, customerListTotal, loading } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách khách hàng chờ giao hàng</h1>
          </div>
          <div className={classes.PageMain}>
            <CustomerListFilters onFilterChange={debounce(this.handleFilterChange, 500)}/>
            <CustomerList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              customerList={customerList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
              onCreateShipment={this.handleCreateShipment}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={customerListTotal}
              listName="khách hàng"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  customerList: makeSelectCustomerList(),
  customerListTotal: makeSelectCustomerListTotal(),
  loading: makeSelectCustomerLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerList: (payload) => dispatch(getCustomerList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(ShipmentCustomers);
