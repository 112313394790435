import walletAxios from 'utils/axios/axiosPayment';
import axiosAuth from 'utils/axios/axiosAuth';

const paymentWalletApi = {
  paymentUser: () => {
    return walletAxios.get(`/bank`);
  },
  paymentUserList: (params) => {
    return axiosAuth.get(`/getcare_customer?keyword=` + params);
  },
  paymentTransactionUser: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return walletAxios.get(`transaction`, {
      params: queryParams,
    });
  },
  paymentUserUpdate:  (params) => {
    return axiosAuth.get(`/payment/gcpay_deposit?` + params);
  },
};

export default paymentWalletApi;
