import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ShipmentListItem from './ShipmentListItem/ShipmentListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './ShipmentListGrid.module.scss';
import classes from './ShipmentList.module.scss';

class ShipmentList extends PureComponent {
  render() {
    const { shipmentList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div className={`$${classes.Header}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          <div className={`${gridClasses.Col} TextCenter`}>STT</div>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={`${gridClasses.Col} ${item.className}`}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
            </div>

      <div className={`${classes.Body}`}>
    
        { !isLoading && shipmentList.length
          ? shipmentList.map((item, index) => (
            <ShipmentListItem
              key={`shipment-${item.id}-${index}`}
              {...item}
              index={index}
            />))
          : (<p className="NoData">Không có đơn giao hàng nào</p>)
        }
      </div>
    </div>;
  }
}

ShipmentList.propTypes = {
  displayFields: PropTypes.array,
  shipmentList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

ShipmentList.defaultProps = {
  displayFields: [],
  shipmentList: [],
  isLoading: false,
};

export default ShipmentList;
