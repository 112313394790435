import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { uniqBy, orderBy, isEqual } from 'lodash';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { getShipmentCustomer, createCustomerShipment } from 'redux/actions/oms/customerActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCustomerLoading,
  makeSelectShipmentCustomerOrders,
  makeSelectShipmentCustomer,
  makeSelectShipment,
} from 'redux/selectors';

import Button from '@material-ui/core/Button';
import CustomerDetails from 'components/customer/CustomerDetails/CustomerDetails';
import ShipmentCustomerOrders from 'components/customer/ShipmentCustomerOrders/ShipmentCustomerOrders';
import ShipmentCustomerOrdersDelivery from 'components/customer/ShipmentCustomerOrdersDelivery/ShipmentCustomerOrdersDelivery';

import classes from './CreateShipment.module.scss';

class CreateShipment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrderIds: [],
    }
    this.cDForm = null;
    this.lPForm = null;
  }

  componentDidMount() {
    const { shipmentCustomerId } = this.props;
    if (shipmentCustomerId) {
      this._loadData();
    }
  }
  componentDidUpdate(prevProps) {
    const { shipmentCustomerId, shipmentCustomerOrders, loading, shipment } = this.props;
    if (shipmentCustomerId && shipmentCustomerId !== prevProps.shipmentCustomerId) {
      this._loadData();
    }

    if (!isEqual(shipmentCustomerOrders, prevProps.shipmentCustomerOrders)) {
      // set default selectedOrderIds
      this.setState({
        selectedOrderIds: shipmentCustomerOrders.map(order => order.id),
      });
    }

    if (!loading && shipment && shipment.id && (!prevProps.shipment || shipment.id !== prevProps.shipment.id)) {
      this.props.history.push(`/shipment/${shipment.code}`);
    }
  }

  _loadData = () => {
    this.props.getShipmentCustomer({
      shipmentCustomerId: this.props.shipmentCustomerId
    });
  }
  _getSelectedOrders = () => {
    const { shipmentCustomerOrders } = this.props;
    const { selectedOrderIds } = this.state;
    return shipmentCustomerOrders.filter(order => selectedOrderIds.includes(order.id));
  }
  _getSelectedCustomerDeliveries = () => {
    const selectedOrders = this._getSelectedOrders();
    return uniqBy(
      selectedOrders.map(order => ({ ...order.customer_delivery })),
      'hash'
    );
  }
  _getLatestOrder = () => {
    const orderedSelectedOrders = orderBy(this._getSelectedOrders(), 'created_at', 'desc');
    return orderedSelectedOrders.length ? orderedSelectedOrders[0] : null;
  }
  _getSelectedCustomerDeliveryInfo = (hash) => {
    return this._getSelectedCustomerDeliveries().find(cD => cD.hash === hash);
  }
  _prepareCustomerDeliveryData = (cDvalues) => {
    const customerDeliveryInfo = this._getSelectedCustomerDeliveryInfo(cDvalues.customer_delivery_hash);
    return customerDeliveryInfo ? {
      name: customerDeliveryInfo.name,
      phone: (/^(84)/).test(customerDeliveryInfo.phone) ? customerDeliveryInfo.phone.replace(/^(84)/, '+84') : customerDeliveryInfo.phone,
      label: customerDeliveryInfo.label,
      address: customerDeliveryInfo.address,
      getcare_country_id: customerDeliveryInfo.getcare_country?.id,
      getcare_province_id: customerDeliveryInfo.getcare_province?.id,
      getcare_district_id: customerDeliveryInfo.getcare_district?.id,
      getcare_ward_id: customerDeliveryInfo.getcare_ward?.id,
    } : null;
  }
  _prepareData = ({ logisticProviderParams, customerDeliveryPrams }) => {
    return {
      getcare_customer_id: Number(this.props.shipmentCustomerId),
      customer_delivery: customerDeliveryPrams,
      logistic_provider: logisticProviderParams,
      getcare_orders: this.state.selectedOrderIds.map(id => ({ id })),
    }
  }

  handleCancelShipment = () => {
    this.props.history.push('/shipment/customer');
  }
  handleAllOrdersSelectedToggle = (e) => {
    const allIds = this.props.shipmentCustomerOrders.map(item => item.id);
    this.setState({
      selectedOrderIds: e.target.checked ? allIds : [],
    });
  }
  handleOrderSelectedToggle = ({ id, isSelected }) => {
    const remainIds = this.state.selectedOrderIds.filter(item => item !== id);
    this.setState({
      selectedOrderIds: isSelected ? [...remainIds, id] : remainIds,
    });
  }
  handleSaveShipment = () => {
    this.lPForm.submitForm();
    this.cDForm.submitForm();
  }
  handleSubmitLogisticProvider = (logisticProviderParams) => {
    if (!Object.keys(this.lPForm.errors).length && !Object.keys(this.cDForm.errors).length) {
      this.props.createCustomerShipment({
        params: this._prepareData({
          logisticProviderParams,
          customerDeliveryPrams: this._prepareCustomerDeliveryData(this.cDForm.values),
        }),
      });
    }
  }
  handleSubmitCustomerDelivery = (values) => {
    return;
  }

  render() {
    const { loading } = this.props;
    const { selectedOrderIds } = this.state;

    return (<>
      <div className={`${classes.PageWrap} ${loading ? 'OverlayLoading' : ''}`}>
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>
            Tạo đơn giao hàng
          </h1>
          <Button
            variant="outlined"
            onClick={this.handleCancelShipment}
          >Huỷ và Trở về</Button>
          <Button
            disabled={!selectedOrderIds.length}
            variant="contained"
            color="primary"
            onClick={this.handleSaveShipment}
          >Tạo đơn giao hàng</Button>
        </div>
        <div className={classes.PageMain}>
          <CustomerDetails shipmentCustomer={{...this.props.shipmentCustomer}}/>
          <ShipmentCustomerOrders
            shipmentCustomerOrders={[...this.props.shipmentCustomerOrders]}
            selectedOrderIds={selectedOrderIds}
            onOrderSelectedToggle={this.handleOrderSelectedToggle}
            onAllOrdersSelectedToggle={this.handleAllOrdersSelectedToggle}
          />
          <ShipmentCustomerOrdersDelivery
            logisticProviderFormRef={(ref) => { this.lPForm = ref }}
            customerDeliveryFormRef={(ref) => { this.cDForm = ref }}
            shipmentCustomer={{...this.props.shipmentCustomer}}
            selectedOrders={[...this._getSelectedOrders()]}
            customerDeliveryOptions={this._getSelectedCustomerDeliveries()}
            latestOrder={this._getLatestOrder()}
            onSubmitLogisticProvider={this.handleSubmitLogisticProvider}
            onSubmitCustomerDelivery={this.handleSubmitCustomerDelivery}
          />
        </div>
      </div>
    </>);
  }
}

CreateShipment.propTypes = {
  shipmentCustomerId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

CreateShipment.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectCustomerLoading(),
  shipmentCustomer: makeSelectShipmentCustomer(),
  shipmentCustomerOrders: makeSelectShipmentCustomerOrders(),
  shipment: makeSelectShipment(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getShipmentCustomer: (payload) => dispatch(getShipmentCustomer(payload)),
    createCustomerShipment: (payload) => dispatch(createCustomerShipment(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(CreateShipment);
