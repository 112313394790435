import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/omsPaymentWallet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getSalesOrderStatuses } from 'redux/actions/oms/salesOrderActions';
import { getSalesChannelList } from 'redux/actions/mdm/salesChannelActions';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderStatuses, makeSelectSalesChannelList } from 'redux/selectors';
import { allInPageSize } from 'utils/constanst/common';

import FilterField from 'components/FilterField/FilterField';
import PriceRange from 'components/FilterField/PriceRange/PriceRange';
import DateRange from 'components/FilterField/DateRange/DateRange';
import MultiSelect from 'components/FilterField/MultiSelect';
import paymentWalletApi from 'utils/api/oms/PaymentWallet';
import classes from './SalesOrderListFilters.module.scss';

const command = [
  {
    id: 'deposit',
    name: 'Nạp tiền',
  },
  {
    id: 'withdraw',
    name: 'Rút tiền',
  }
]
class SalesOrderListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }
  async componentDidMount() {
    this.props.getSalesOrderStatuses();
    const { data: responseData } = await paymentWalletApi.paymentUser();
    const userList = responseData.data.map(x => (
      {
        id: x.code,
        name: x.name
      } 
    ));
    this.setState({
      userList
    });
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  };

  render() {
    const { fields, userList } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            ['amount_total'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<PriceRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['created_at'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<DateRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['getcare_order_status_id'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                customField={<MultiSelect
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  options={this.props.salesOrderStatuses}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['merchant_code'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                customField={<MultiSelect
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  options={userList}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['command'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                customField={<MultiSelect
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  options={command}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) 
            : (<FilterField
                key={field.name}
                {...field}
                handleFieldChange={this.handleFieldChange}
              />)
          ))}
        </div>
      </div>
    );
  }
}

SalesOrderListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  salesOrderStatuses: makeSelectSalesOrderStatuses(),
  salesChannelList: makeSelectSalesChannelList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrderStatuses: (payload) => dispatch(getSalesOrderStatuses(payload)),
    getSalesChannelList: (payload) => dispatch(getSalesChannelList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesOrderListFilters);
